import { loading } from './loading'

const rpcTimeout = 10

/**
 * sample
 *  [
 *    {
 *
 *      datasetAccountId: String,
 *      datasetId: String,
 *      laterSelectTargetColumn: Boolean,
 *      passedPreprocessing: Boolean,
 *      preprocessingDatasetId: String,
 *      projectId: String,
 *      recipeId: String,
 *      unstructuredDataset: Boolean,
 *      targetColumn: Array
 *      tmpRecipe: null
 *    }
 *  ]
 */

const modelSettingTemplate = {
  projectId: undefined,
  datasetId: '',
  datasetAccountId: '',
  unstructuredDataset: false,
  targetColumn: [],
  laterSelectTargetColumn: false,
  passedPreprocessing: false,
  preprocessingDatasetId: '',
  recipeId: '',
  tmpRecipe: ''
}

const models = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    modelSettingList: [
      {
        projectId: undefined,
        datasetId: '',
        datasetAccountId: '',
        unstructuredDataset: false,
        targetColumn: [],
        laterSelectTargetColumn: false,
        passedPreprocessing: false,
        preprocessingDatasetId: '',
        recipeId: '',
        tmpRecipe: null
      }
    ],
    models: {
      0: {
        id: 0,
        name: '使用する学習済みAIの名称が入ります使用する学習済みAIの名称が入ります',
        description:
          'AIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの',
        link: '#',
        targetValiable:
          '目的変数名が入ります目的変数名が入ります目的変数名が入ります目的変数名が入ります',
        accuracy: {
          effect: {
            name: '退職率の削減量',
            value: 15
          },
          firstAccuracy: {
            name: '再現率',
            value: 76.2
          }
        },
        featureImportance: [
          {
            id: 1,
            name: '年齢',
            value: 1.222,
            color: '#333'
          },
          {
            id: 2,
            name: '出張頻度-高',
            value: 1.0,
            color: '#333'
          },
          {
            id: 3,
            name: '長い説明変数が入ります長い説明変数が入ります',
            value: 0.9543210123456789,
            color: '#333'
          },
          {
            id: 4,
            name: '長い説明変数が入ります長い説明変数が入ります',
            value: 0.7543210123456789,
            color: '#333'
          },
          {
            id: 5,
            name: '長い説明変数が入ります長い説明変数が入ります',
            value: 0.5543210123456789,
            color: '#333'
          }
        ],
        preprocAndEda: {
          id: 0,
          name: '前処理の名前が入ります'
        }
      },
      1: {
        id: 1,
        name: '使用する学習済みAIの名称が入ります使用する学習済みAIの名称が入ります',
        description:
          'AIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの',
        link: '#',
        targetValiable:
          '目的変数名が入ります目的変数名が入ります目的変数名が入ります目的変数名が入ります',
        accuracy: {
          effect: {
            name: '退職率の削減量',
            value: 15
          },
          firstAccuracy: {
            name: '再現率',
            value: 76.2
          }
        },
        featureImportance: [
          {
            id: 1,
            name: '年齢',
            value: 1.222,
            color: '#333'
          },
          {
            id: 2,
            name: '出張頻度-高',
            value: 1.0,
            color: '#333'
          },
          {
            id: 3,
            name: '長い説明変数が入ります長い説明変数が入ります',
            value: 0.9543210123456789,
            color: '#333'
          },
          {
            id: 4,
            name: '長い説明変数が入ります長い説明変数が入ります',
            value: 0.7543210123456789,
            color: '#333'
          },
          {
            id: 5,
            name: '長い説明変数が入ります長い説明変数が入ります',
            value: 0.5543210123456789,
            color: '#333'
          }
        ],
        preprocAndEda: {
          id: 1,
          name: '前処理の名前が入ります'
        }
      },
      2: {
        id: 2,
        name: '使用する学習済みAIの名称が入ります使用する学習済みAIの名称が入ります',
        description:
          'AIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの説明が入りますAIのAIの説明が入りますAIの',
        link: '#',
        targetValiable:
          '目的変数名が入ります目的変数名が入ります目的変数名が入ります目的変数名が入ります',
        accuracy: {
          effect: {
            name: '退職率の削減量',
            value: 15
          },
          firstAccuracy: {
            name: '再現率',
            value: 76.2
          }
        },
        featureImportance: [
          {
            id: 1,
            name: '年齢',
            value: 1.222,
            color: '#333'
          },
          {
            id: 2,
            name: '出張頻度-高',
            value: 1.0,
            color: '#333'
          },
          {
            id: 3,
            name: '長い説明変数が入ります長い説明変数が入ります',
            value: 0.9543210123456789,
            color: '#333'
          },
          {
            id: 4,
            name: '長い説明変数が入ります長い説明変数が入ります',
            value: 0.7543210123456789,
            color: '#333'
          },
          {
            id: 5,
            name: '長い説明変数が入ります長い説明変数が入ります',
            value: 0.5543210123456789,
            color: '#333'
          }
        ],
        preprocAndEda: {
          id: 2,
          name: '前処理の名前が入ります前処理の名前が入ります前処理の名前が入ります前処理の名前が入ります前処理の名前が入ります前処理の名前が入ります'
        }
      }
    }
  },
  getters: {
    models: (state) => state.models,
    modelSettingList: (state) => state.modelSettingList,
    modelLoading: (_state, getters) => true // getters['loading/loading']
  },
  mutations: {
    UPDATE_MODEL_SETTING_LIST(state, value) {
      state.modelSettingList = value
    }
  },
  actions: {
    notifyError: function (context, response) {
      this._vm.log_info(response)
    },
    remoteCall: async function ({ dispatch }, request) {
      try {
        const response = await Promise.race([
          this._vm.$sendMessageAndReceive(request),
          new Promise((resolve) => setTimeout(resolve, rpcTimeout * 1000)).then(
            () => {
              return {
                status: 'error',
                message: 'RPC_TIMEOUT'
              }
            }
          )
        ])

        if (response.status === 'error') {
          dispatch('notifyError', response)
        } else {
          return response.result
        }
      } catch (error) {
        dispatch('notifyError', error)
      }
    },

    getModelSettingInfo: async function (
      { commit, dispatch, state },
      { jobId }
    ) {
      const req = {
        action: 'getModelSettingInfo',
        jobId
      }

      const res = await dispatch('remoteCall', req)
      const target = Object.assign({}, modelSettingTemplate, res)

      const modelSettingList = state.modelSettingList
      const targetList = modelSettingList.filter(
        (x) => x.projectId !== target.projectId
      )

      targetList.push(target)

      commit('UPDATE_MODEL_SETTING_LIST', targetList)
    },
    resetTrainingStatus: function (
      { commit, state, dispatch },
      { status, setType }
    ) {
      if (!status?.projectId) return

      const modelSettingList = state.modelSettingList

      const target = status
      const targetList = modelSettingList.filter(
        (x) => x.projectId !== status.projectId
      )

      switch (setType) {
        case 'datasetSetting':
          target.datasetId = null
          target.datasetAccountId = null
          target.unstructuredDataset = false
          target.targetColumn = null
          target.laterSelectTargetColumn = false
          target.passedPreprocessing = false
          target.preprocessingDatasetId = null
          target.recipeId = null
          target.tmpRecipe = null
          dispatch('trainingDataset/resetDataset', null, { root: true })
          break

        case 'selectTargetColumn':
          if (target.laterSelectTargetColumn) {
            target.targetColumn = null
            target.recipeId = null
            target.tmpRecipe = null
          } else {
            target.targetColumn = null
            target.passedPreprocessing = false
            target.preprocessingDatasetId = null
            target.recipeId = null
            target.tmpRecipe = null
          }

          break

        case 'trainPreprocessing':
          if (target.preprocessingDatasetId) {
            dispatch('trainingDataset/resetDataset', null, { root: true })
          }

          if (target.laterSelectTargetColumn) {
            target.targetColumn = null
            target.passedPreprocessing = false
            target.preprocessingDatasetId = null
            target.recipeId = null
            target.tmpRecipe = null
          } else {
            target.passedPreprocessing = false
            target.preprocessingDatasetId = null
            target.recipeId = null
            target.tmpRecipe = null
          }

          break

        case 'trainRecipeList':
          target.recipeId = null
          target.tmpRecipe = null

          break

        default:
          return
      }

      targetList.push(target)
      commit('UPDATE_MODEL_SETTING_LIST', targetList)
    },
    checkProgressTraining: function ({ state }, { projectId }) {
      const targetList = state.modelSettingList

      if (targetList.length > 0) {
        return targetList.some((x) => x.projectId === projectId)
      } else {
        return false
      }
    },
    deleteProgressTraining: function ({ commit, state }, { projectId }) {
      let targetList = state.modelSettingList

      if (targetList.some((x) => x.projectId === projectId)) {
        targetList = targetList.filter((x) => x.projectId !== projectId)
      }

      commit('UPDATE_MODEL_SETTING_LIST', targetList)
    },
    getProgressTraining: function ({ state }, { projectId }) {
      const targetList = state.modelSettingList

      if (targetList) {
        return targetList.find((x) => x.projectId === projectId)
      } else {
        return null
      }
    },
    setProgressTraining: function (
      { commit, state },
      { item, setType, projectId, datasetType, datasetAccountId }
    ) {
      const modelSettingList = state.modelSettingList
      let target = modelSettingTemplate
      let targetList = modelSettingList

      if (modelSettingList.some((x) => x.projectId === projectId)) {
        target = modelSettingList.find((x) => x.projectId === projectId)
        targetList = modelSettingList.filter((x) => x.projectId !== projectId)
      }

      if (setType === 'project') {
        target.projectId = item
      } else if (target.projectId) {
        // プロジェクトIDが存在しないか引数にプロジェクトIDがない場合はセットさせない
        switch (setType) {
          case 'dataset':
            target.datasetId = item
            target.datasetAccountId = datasetAccountId
            // データセットを再選択した場合の対策
            // 予測する列の再選択が必要なため
            target.targetColumn = null
            target.laterSelectTargetColumn = false
            target.passedPreprocessing = false
            target.preprocessingDatasetId = null
            target.recipeId = null

            break

          case 'targetColumn':
            target.targetColumn = item
            target.recipeId = null

            break

          case 'laterSelectTargetColumn':
            target.laterSelectTargetColumn = item

            break

          case 'preprocessing':
            target.passedPreprocessing = true
            target.preprocessingDatasetId = item
            target.recipeId = null

            break

          case 'recipe':
            target.recipeId = item

            break

          case 'tmpRecipe':
            target.tmpRecipe = item
            break

          default:
            return
        }
        if (datasetType === 'unstructured') {
          target.unstructuredDataset = true
        } else if (datasetType === 'structured') {
          target.unstructuredDataset = false
        }
      } else {
        return
      }

      targetList.push(target)
      commit('UPDATE_MODEL_SETTING_LIST', targetList)
    },
    async deleteModel({ commit }, { modelId, accountId }) {
      const req = {
        action: 'deleteModel',
        modelId: modelId,
        accountId: accountId
      }
      await this._vm.$sendMessageAndReceive(req)
    }
  }
}

export default models
