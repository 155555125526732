import Router from 'vue-router'
import Vue from 'vue'

import GtmTools from '@/plugin/gtmTools.js'
import store from '@/stores/index'
import _SandboxRoute from './views/dev/route.js'
let SandboxRoute

if (process.env.NODE_ENV === 'development') {
  SandboxRoute = _SandboxRoute
} else {
  SandboxRoute = []
}

Vue.use(Router)
Vue.use(GtmTools)

function checkProject(pageName, to, from, next) {
  if (from?.params?.projectId) {
    next({
      name: pageName,
      params: { projectId: from.params.projectId, id: to.params.id }
    })
  } else {
    next()
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'uiTestTop',
      meta: { public: false, redirect_if_loggedin: true },
      redirect: {
        name: 'projectList'
      }
    },
    {
      path: '/projects',
      name: 'projectList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/project-list.vue')
    },
    {
      path: '/projects/:projectId',
      name: 'projectDetail',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/project-detail.vue')
    },
    // 学習済みAI
    {
      path: '/projects/:projectId/trained-ais',
      name: 'trainedAiProjectList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/trained-ai-list.vue')
    },
    {
      path: '/projects/:projectId/trained-ais/detail/:id',
      name: 'trainedAiProjectDetail',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/trained-ai-detail.vue')
    },
    // 推論
    {
      path: '/projects/:projectId/inference',
      name: 'inferenceProject',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/inference.vue')
    },
    {
      path: '/projects/:projectId/inference/:inferenceId',
      name: 'inferenceProjectTask',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/inference.vue')
    },
    {
      path: '/projects/:projectId/inference-result/:inferenceId',
      name: 'inferenceResult',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/inference.vue')
    },
    // サービス
    {
      path: '/projects/:projectId/services',
      name: 'serviceProjectList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/service-list.vue')
    },
    {
      path: '/projects/:projectId/services/:serviceType/detail/:id',
      name: 'serviceProjectDetail',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/service-detail.vue')
    },
    {
      path: '/projects/:projectId/services/:serviceType/detail/:id/edit',
      name: 'serviceProjectDetailEdit',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/service-detail.vue')
    },
    // データセット
    {
      path: '/projects/:projectId/datasets',
      name: 'datasetProjectList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/dataset-list.vue')
    },
    {
      path: '/projects/:projectId/datasets/:id',
      name: 'datasetProjectDetail',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/dataset-detail.vue')
    },
    // 前処理
    {
      path: '/preprocessings',
      name: 'preprocessingList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/preprocessing-list.vue')
    },
    {
      path: '/projects/:projectId/preprocessings',
      name: 'preprocessingProjectList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/preprocessing-list.vue')
    },
    {
      path: '/projects/:projectId/preprocessings/:id',
      name: 'preprocessingProjectDetail',
      component: () =>
        import(
          /* webpackChunkName: "data" */ './views/preprocessing-detail.vue'
        )
    },
    {
      path: '/projects/:projectId/preprocessing-apply/:datasetId/:preprocessingId',
      name: 'preprocessingProjectApply',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/preprocessing-apply.vue')
    },
    {
      path: '/projects/:projectId/preprocessing-apply/:datasetId',
      name: 'preprocessingProjectApplyNonePreproc',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/preprocessing-apply.vue')
    },
    // レシピ
    {
      path: '/projects/:projectId/recipes',
      name: 'recipeProjectList',
      component: () =>
        import(/* webpackChunkName: "recipe" */ './views/recipe-list.vue')
    },
    {
      path: '/projects/:projectId/recipes/detail/:id',
      name: 'recipeProjectDetail',
      component: () =>
        import(/* webpackChunkName: "recipe" */ './views/recipe-detail.vue')
    },
    {
      path: '/projects/:projectId/recipes/edit',
      name: 'recipeProjectEdit',
      component: () =>
        import(/* webpackChunkName: "recipe" */ './views/recipe-edit.vue')
    },
    // カスタムブロック
    {
      path: '/customblock',
      name: 'customblockList',
      component: () =>
        import(
          /* webpackChunkName: "customblock" */ './views/customblock-list.vue'
        )
    },
    {
      path: '/customblock/detail/:id/:version?',
      name: 'customblockDetail',
      component: () =>
        import(
          /* webpackChunkName: "customblock" */ './views/customblock-detail.vue'
        )
    },
    {
      path: '/customblock/edit/:id?',
      name: 'customblockEdit',
      component: () =>
        import(
          /* webpackChunkName: "customblock" */ './views/customblock-edit.vue'
        )
    },
    // 学習済みAI
    {
      path: '/trained-ais',
      name: 'trainedAiList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/trained-ai-list.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('trainedAiProjectList', to, from, next)
      }
    },
    {
      path: '/trained-ais/detail/:id',
      name: 'trainedAiDetail',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/trained-ai-detail.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('trainedAiProjectDetail', to, from, next)
      }
    },
    // 推論
    {
      path: '/inference',
      name: 'inference',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/inference.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('inferenceProject', to, from, next)
      }
    },
    // サービス
    {
      path: '/services',
      name: 'serviceList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/service-list.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('serviceProjectList', to, from, next)
      }
    },
    {
      path: '/services/:id',
      name: 'serviceDetail',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/service-detail.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('serviceProjectDetail', to, from, next)
      }
    },
    {
      path: '/services/:id/edit',
      name: 'serviceDetailEdit',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/service-detail.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('serviceProjectDetailEdit', to, from, next)
      }
    },
    // データセット
    {
      path: '/datasets',
      name: 'datasetList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/dataset-list.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('datasetProjectList', to, from, next)
      }
    },
    {
      path: '/datasets/:id',
      name: 'datasetDetail',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/dataset-detail.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('datasetProjectDetail', to, from, next)
      }
    },
    // 前処理
    {
      path: '/preprocessings',
      name: 'preprocessingList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/preprocessing-list.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('preprocessingProjectList', to, from, next)
      }
    },
    {
      path: '/preprocessings/:id',
      name: 'preprocessingDetail',
      component: () =>
        import(
          /* webpackChunkName: "data" */ './views/preprocessing-detail.vue'
        ),
      beforeEnter: (to, from, next) => {
        checkProject('preprocessingProjectDetail', to, from, next)
      }
    },
    {
      path: '/preprocessing-apply/:datasetId/:preprocessingId',
      name: 'preprocessingApply',
      component: () =>
        import(
          /* webpackChunkName: "data" */ './views/preprocessing-apply.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (from?.params?.projectId) {
          next({
            name: 'preprocessingProjectApply',
            params: {
              projectId: from.params.projectId,
              datasetId: to.params.datasetId,
              preprocessingId: to.params.preprocessingId
            }
          })
        } else {
          next()
        }
      }
    },
    {
      path: '/preprocessing-apply/:datasetId',
      name: 'preprocessingApplyNonePreproc',
      component: () =>
        import(
          /* webpackChunkName: "data" */ './views/preprocessing-apply.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (from?.params?.projectId) {
          next({
            name: 'preprocessingProjectApplyNonePreproc',
            params: {
              projectId: from.params.projectId,
              datasetId: to.params.datasetId
            }
          })
        } else {
          next()
        }
      }
    },
    // レシピ
    {
      path: '/recipes',
      name: 'recipeList',
      component: () =>
        import(/* webpackChunkName: "recipe" */ './views/recipe-list.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('recipeProjectList', to, from, next)
      }
    },
    {
      path: '/recipes/detail/:id',
      name: 'recipeDetail',
      component: () =>
        import(/* webpackChunkName: "recipe" */ './views/recipe-detail.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('recipeProjectDetail', to, from, next)
      }
    },
    {
      path: '/recipes/edit',
      name: 'recipeEdit',
      component: () =>
        import(/* webpackChunkName: "recipe" */ './views/recipe-edit.vue'),
      beforeEnter: (to, from, next) => {
        checkProject('recipeProjectEdit', to, from, next)
      }
    },
    {
      path: '/organization',
      name: 'organization',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/organization.vue')
    },
    {
      path: '/train/select-mode',
      name: 'selectMode',
      component: () =>
        import(
          /* webpackChunkName: "data" */ './views/project-template-select-mode.vue'
        )
    },
    {
      path: '/train/project-template',
      name: 'projectTemplateList',
      component: () =>
        import(
          /* webpackChunkName: "tenplate" */ './views/project-template.vue'
        )
    },
    {
      path: '/train/project-templates/:id',
      name: 'projectTemplateDetail',
      component: () =>
        import(
          /* webpackChunkName: "data" */ './views/project-template-detail.vue'
        )
    },
    {
      path: '/projects/:projectId/train/dataset-setting',
      name: 'datasetSetting',
      component: () =>
        import(
          /* webpackChunkName: "datasetSetting" */ './views/train/dataset-setting.vue'
        )
    },
    {
      path: '/projects/:projectId/train/select-target-column/:datasetId',
      name: 'selectTargetColumn',
      component: () =>
        import(
          /* webpackChunkName: "data" */ './views/select-target-column.vue'
        )
    },
    {
      path: '/projects/:projectId/train/preprocessing/:type/:id',
      name: 'trainPreprocessing',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/train/preprocessing.vue')
    },
    {
      path: '/projects/:projectId/train/recipes',
      name: 'trainRecipeList',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/train/recipe-list.vue')
    },
    {
      path: '/projects/:projectId/train/recipes/detail/:id',
      name: 'trainRecipeDetail',
      component: () =>
        import(
          /* webpackChunkName: "recipe" */ './views/train/recipe-detail.vue'
        )
    },
    {
      path: '/projects/:projectId/train/recipes/edit',
      name: 'trainRecipeEdit',
      component: () =>
        import(/* webpackChunkName: "recipe" */ './views/train/recipe-edit.vue')
    },
    {
      path: '/projects/:projectId/train/training',
      name: 'training',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/training.vue')
    },
    // 学習中のに飛ぶ
    {
      path: '/projects/:projectId/train/training/:taskId',
      name: 'trainingTask',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/training.vue')
    },
    {
      path: '/projects/:projectId/project-template-create',
      name: 'projectTemplateCreate',
      component: () =>
        import(
          /* webpackChunkName: "data" */ './views/project-template-create.vue'
        )
    },
    {
      path: '/project-template-edit/:projectTemplateId',
      name: 'projectTemplateEdit',
      component: () =>
        import(
          /* webpackChunkName: "data" */ './views/project-template-edit.vue'
        )
    },
    {
      path: '/my-account-info',
      name: 'myAccountInfo',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/account-info.vue')
    },
    {
      path: '/change-plan',
      name: 'changePlan',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/change-plan.vue')
    },
    {
      path: '*',
      name: 'notFound',
      component: () =>
        import(/* webpackChunkName: "data" */ './views/not-found.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: { public: true, redirect_if_loggedin: true },
      component: () =>
        import(/* webpackChunkName: "login" */ './views/login.vue')
    },
    {
      path: '/login/:content?/:token?',
      name: 'loginSubpage',
      meta: { public: true, redirect_if_loggedin: true },
      component: () =>
        import(/* webpackChunkName: "login" */ './views/login.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      meta: { public: true, redirect_if_loggedin: true },
      component: () =>
        import(/* webpackChunkName: "login" */ './views/signup.vue')
    },
    {
      // リダイレクト専用 メール文面に記載されてるのでしばらく残す
      path: '/send_pw_reset',
      name: 'sendPasswordResetRedirect',
      meta: { public: true, redirect_if_loggedin: true },
      redirect: {
        name: 'loginSubpage',
        params: {
          content: 'reset-password'
        }
      }
    },
    {
      // リダイレクト専用 メール文面に記載されてるのでしばらく残す
      path: '/password_reset/:token',
      name: 'passwordResetRedirect',
      meta: { public: true, redirect_if_loggedin: true },
      redirect: ({ params }) => ({
        name: 'loginSubpage',
        params: {
          content: 'set-password',
          token: params.token
        }
      })
    },
    {
      path: '/verify/:token',
      name: 'verify',
      meta: { public: true },
      component: () =>
        import(/* webpackChunkName: "login" */ './views/account/verify.vue')
    },
    {
      path: '/callback/:org_id',
      name: 'callback',
      meta: { public: true },
      component: () =>
        import(/* webpackChunkName: "login" */ './views/account/callback.vue')
    },
    ...SandboxRoute
  ]
})

// projectIdありのページに遷移する際に通常のページ→ProjectID付きのページと遷移しているため、vue routerの遷移時のPromiseのエラーに引っかかってしまう。以下の処理で問題ないが将来的にはすべての遷移をProjectIDありなしで出し分けたい。（これを参考にしています。 https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378）
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

let startTIme = null

// 10分操作がない場合は時間を取り直す
let timeOut
function countStart() {
  clearTimeout(timeOut)
  timeOut = setTimeout(countFinish, 600000) // 10分で時間を再設定する
}
function countFinish() {
  startTIme = null
  document.body.addEventListener('click', countReStart)
  function countReStart() {
    startTIme = new Date()
    document.body.removeEventListener('click', countReStart)
  }
}

router.beforeEach(async (to, from, next) => {
  let loggedIn = store.getters['auth/loggedIn']

  if (!loggedIn) {
    // null or false
    try {
      await store.dispatch('auth/fetchLoginInfo')
      loggedIn = store.getters['auth/loggedIn']
    } catch (ex) {
      loggedIn = false
    }
  }

  if (startTIme) {
    const leaveTime = new Date()
    const sendTime = leaveTime - startTIme
    Vue.prototype.$gtmDataLayer.sendEventCategory(
      'pageViewTime',
      from.name,
      sendTime,
      sendTime
    )
    document.body.removeEventListener('click', countStart)
    startTIme = null
  }
  if (to.matched.some((record) => !record.meta.public) && !loggedIn) {
    next({ path: '/login' })
  } else if (
    to.matched.some((record) => record.meta.redirect_if_loggedin) &&
    loggedIn
  ) {
    next({ path: '/projects' })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  startTIme = new Date()
  document.body.addEventListener('click', countStart)
  Vue.prototype.$gtmDataLayer.sendPageView('pageView', to.name)
})

export default router
