import { loading } from './loading'

const rpcTimeout = 10

const service = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    projectList: {},
    created: {
      projectId: null
    },
    loadingProjectList: false
  },
  getters: {
    apiServerOrigin: () => {
      return window.location.origin
    },
    createdProject: (state) => state.created,
    projectList: (state) => state.projectList,
    projectError: (_state, getters) => getters['loading/error'],
    projectLoading: (_state, getters) => getters['loading/loading'],
    loadingProjectList: (state) => state.loadingProjectList
  },
  mutations: {
    SET_CREATED_PROJECT(state, { projectId }) {
      state.created.projectId = projectId
    },
    SET_PROJECT_LIST(state, value) {
      state.projectList = value
    },
    SET_PROJECT_DETAIL(state, { projectId, value }) {
      state.projectList[projectId] = value
    },
    SET_PROJECT_ERROR(state, { projectId, value }) {
      state.projectList[projectId].error = value
    },
    SET_PROJECT_LOADING_STATE(state, { projectId, value }) {
      if (state.projectList[projectId]) {
        state.projectList[projectId].loading = value
      }
    },
    SET_LOADING_PROJECT_LIST(state, value) {
      state.loadingProjectList = value
    }
  },
  actions: {
    notifyError(context, response) {
      this._vm.log_info(response)
    },
    async remoteCall({ dispatch }, request) {
      try {
        const response = await Promise.race([
          this._vm.$sendMessageAndReceive(request),
          new Promise((resolve) => setTimeout(resolve, rpcTimeout * 1000)).then(
            () => {
              return {
                status: 'error',
                message: 'RPC_TIMEOUT'
              }
            }
          )
        ])
        if (response.status === 'error') {
          dispatch('notifyError', response)
        } else {
          return response.result
        }
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async loadProjectList({ commit, dispatch }) {
      dispatch('loading/start')
      try {
        const response = await this._vm.$sendMessageAndReceive({
          action: 'getProjectList'
        })
        if (response.status !== 'error') {
          const obj = {}
          response.result.forEach((item) => {
            obj[item.id] = item
          })
          commit('SET_PROJECT_LIST', obj)
        } else {
          throw response
        }
      } catch (e) {
        dispatch('loading/error', e)
      } finally {
        dispatch('loading/finish')
      }
    },
    async loadProjectDetail({ commit }, projectId) {
      try {
        commit('SET_PROJECT_LOADING_STATE', {
          projectId: projectId,
          value: true
        })
        const response = await this._vm.$sendMessageAndReceive({
          action: 'projectDetail',
          projectId
        })

        if (response.status === 'success') {
          commit('SET_PROJECT_DETAIL', {
            projectId: projectId,
            value: response.result
          })
        } else {
          throw response
        }
      } catch (e) {
        commit('SET_PROJECT_ERROR', {
          projectId: projectId,
          value: e
        })
      } finally {
        commit('SET_PROJECT_LOADING_STATE', {
          projectId: projectId,
          value: false
        })
      }
    },
    async addTag({ dispatch }, { projectId, tag }) {
      await dispatch('remoteCall', {
        action: 'projectAddTag',
        projectId,
        tag
      })
      await dispatch('loadProjectDetail', projectId)
    },
    async removeTag({ dispatch }, { projectId, tag }) {
      await dispatch('remoteCall', {
        action: 'projectRemoveTag',
        projectId,
        tag
      })
      await dispatch('loadProjectDetail', projectId)
    },
    async createProject({ commit, dispatch }, project) {
      const result = await dispatch('remoteCall', {
        action: 'projectCreate',
        ...project
      })

      commit('SET_CREATED_PROJECT', { projectId: result.id })
      await dispatch('loadProjectList')
    },
    async updateProject({ dispatch }, { projectId, name, description }) {
      await dispatch('remoteCall', {
        action: 'projectUpdate',
        projectId,
        name,
        description
      })
      await dispatch('loadProjectList')
    },
    async deleteProject({ dispatch }, projectId) {
      await dispatch('remoteCall', {
        action: 'projectDelete',
        projectId
      })
      await dispatch('loadProjectList')
    },
    async projectAddDataset({ dispatch }, { projectId, project, targets }) {
      await dispatch('remoteCall', {
        action: 'projectAddDataset',
        projectId,
        project,
        targets
      })
      await dispatch('loadProjectList')
    },
    async projectAddRecipe({ dispatch }, { projectId, project, targets }) {
      await dispatch('remoteCall', {
        action: 'projectAddRecipe',
        projectId,
        project,
        targets
      })
      await dispatch('loadProjectList')
    },
    async projectAddEDARecipe({ dispatch }, { projectId, project, targets }) {
      await dispatch('remoteCall', {
        action: 'projectAddEdaRecipe',
        projectId,
        project,
        targets
      })
      await dispatch('loadProjectList')
    },
    async projectAddTrainedAI({ dispatch }, { projectId, project, targets }) {
      await dispatch('remoteCall', {
        action: 'projectAddTrainedAI',
        projectId,
        project,
        targets
      })
      await dispatch('loadProjectList')
    },
    async projectAddService({ dispatch }, { projectId, project, targets }) {
      await dispatch('remoteCall', {
        action: 'projectAddService',
        projectId,
        project,
        targets
      })
      await dispatch('loadProjectList')
    }
  }
}

export default service
