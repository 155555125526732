import { loading } from './loading'
import { base64ToBytes, download } from '../lib/download'
const customblock = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    customblocks: [],
    submitting: false
  },
  getters: {
    customblockList: (state) => state.customblocks,
    submitting: (state) => state.submitting,
    loading: (_state, getters) => getters['loading/loading']
  },
  mutations: {
    SET_CUSTOMBLOCKS(state, value) {
      state.customblocks = value
    },
    DELETE_DATASOURCE_BY_ID(state, { id, value }) {
      const idx = state.customblocks.findIndex((x) => x.id === id)
      Object.assign(state.customblocks[idx], value)
    },
    SET_SUBMITTING(state, value) {
      state.submitting = value
    }
  },
  actions: {
    async fetchCustomblocks({ commit, dispatch }) {
      dispatch('loading/start')
      try {
        const res = await this._vm.$sendMessageAndReceive({
          action: 'getCustomBlockList'
        })
        commit('SET_CUSTOMBLOCKS', res.result)
      } catch (ex) {
        this._vm.log_info(ex)
      } finally {
        dispatch('loading/finish')
      }
    },
    async createCustomBlock({ commit, dispatch }, payload) {
      commit('SET_SUBMITTING', true)
      try {
        await this._vm.$sendMessageAndReceive({
          action: 'createCustomBlock',
          ...payload
        })
        await dispatch('fetchDataSources')
      } catch (ex) {
      } finally {
        commit('SET_SUBMITTING', false)
      }
    },
    async deleteDataSource({ commit, dispatch }, { id }) {
      const res = await this._vm.$sendMessageAndReceive({
        action: 'deleteDataSource',
        id: id
      })
      if (res.status === 'error') {
        throw res
      }
      await dispatch('fetchDataSources')
    },
    async downloadCustomblockLog(
      { commit, dispatch },
      { customblockId, version }
    ) {
      const req = {
        action: 'downloadCustomblockLog',
        customBlockId: customblockId,
        version
      }

      const res = await this._vm.$sendMessageAndReceive(req)
      const file = res.file
      const ext = res.ext
      const decoded = base64ToBytes(file)
      await download(decoded, 'customblock.' + ext)
    }
  }
}

export default customblock
