<template>
  <div
    class="wrap"
    :class="{
      'wrap-accept': isAccept,
      'wrap-error': isError,
      'wrap-green': isGreen
    }"
  >
    <texts
      v-if="text"
      :text="text"
      :color="useColor"
      size="min"
      :class="{
        'wrap-folded': isFolded
      }"
    />
    <slot v-if="!text" />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
export default {
  components: {
    texts
  },
  props: {
    /** Slotを使用せずに単色のテキストを表示する場合のテキスト */
    text: String,
    /** 青色にするかどうか */
    isAccept: {
      type: Boolean,
      default: true
    },
    /** 赤色にするかどうか */
    isError: {
      type: Boolean,
      default: false
    },
    /** 緑色にするかどうか */
    isGreen: {
      type: Boolean,
      default: false
    },
    /** 中のテキストを折り返すかどうか */
    isFolded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    useColor() {
      if (this.isError) {
        return 'caution'
      }
      return 'accept'
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  align-items: center;
  padding: $space-sub $space-small;
  border-radius: adjustVW(8);
  &-accept {
    border: 2px solid $blue;
    background: $blue-bg;
  }
  &-error {
    border: 2px solid $red;
    background: $red-lite;
  }
  &-green {
    border: 2px solid $green;
    background: $green-bg;
  }
  &-folded {
    white-space: pre-line;
  }
}
</style>
