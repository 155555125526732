export const loading = {
  namespaced: true,
  state() {
    return {
      loading: false,
      error: null
    }
  },
  getters: {
    loading: (state) => state.loading,
    error: (state) => state.error
  },
  actions: {
    start({ commit }) {
      commit('SET_LOADING', true)
    },
    reset({ commit }) {
      commit('SET_RESULT', null)
      commit('SET_ERROR', null)
    },
    set({ commit }, value) {
      commit('SET_RESULT', value)
    },
    error({ commit }, value) {
      commit('SET_ERROR', value)
    },
    finish({ commit }) {
      commit('SET_LOADING', false)
    }
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_RESULT(state, value) {
      state.result = value
    },
    SET_ERROR(state, value) {
      state.error = value
    }
  }
}
