import { splitRevFullId } from '@/lib/misc.js'

const tasks = {
  namespaced: true,
  modules: {},
  state: {
    pending: [],
    running: [],
    finished: [],
    inferencePending: [],
    inferenceRunning: [],
    inferenceFinished: []
  },
  getters: {
    pending: (state) => state.pending,
    running: (state) => state.running,
    finished: (state) => state.finished,
    getProjectTasks: (state) => (projectId) => {
      return [].concat(
        state.pending.filter((item) => item.projectId === projectId),
        state.running.filter((item) => item.projectId === projectId),
        state.finished.filter((item) => item.projectId === projectId)
      )
    },
    inferencePending: (state) => state.inferencePending,
    inferenceRunning: (state) => state.inferenceRunning,
    inferenceFinished: (state) => state.inferenceFinished
  },
  mutations: {
    SET_RESULT(state, value) {
      state.pending = value.pending
      state.running = value.running
      state.finished = value.finished
    },
    SET_INFERENCE_RESULT(state, value) {
      state.inferencePending = value.pending
      state.inferenceRunning = value.running
      state.inferenceFinished = value.finished
    }
  },
  actions: {
    async getTasks({ commit }) {
      const res = await this._vm.$sendMessageAndReceive({
        action: 'getTasks'
      })
      if (res.status === 'error') {
        throw res
      }
      commit('SET_RESULT', res)
    },
    async getInferenceTasks({ commit }) {
      const res = await this._vm.$sendMessageAndReceive({
        action: 'getInferenceTasks'
      })
      if (res.status === 'error') {
        throw res
      }
      commit('SET_INFERENCE_RESULT', res)
    },
    async stopTask({ state, commit, dispatch }, job) {
      const jobId = job.jobId
      const [accountId, modelId] = splitRevFullId(job.jobName)

      const res = await this._vm.$sendMessageAndReceive({
        action: 'stopLearning',
        jobId
      })
      commit('trainings/SET_PROGRESS_STATUS', 'stoppingTraining', {
        root: true
      })
      commit('trainings/RESET_JOB_INFO', null, { root: true })
      commit('trainings/SET_STOPPED_STATUS', true, { root: true })
      commit('trainings/SET_ERROR_MESSAGE', '', { root: true })
      if (res.job_status !== 'SUCCEEDED') {
        dispatch('models/deleteModel', { modelId, accountId }, { root: true })
      }
    }
  }
}

export default tasks
