<template>
  <button
    class="c-button"
    :class="[
      type,
      size,
      { 'c-button-icon': iconName },
      { 'c-button-ellipse': isEllipse }
    ]"
    :disabled="isDisabled"
    :type="buttonType"
    @click="$emit('click')"
  >
    <icon
      v-if="iconName"
      class="c-button-icon-item"
      :iconName="iconName"
      :color="iconColor"
    />
    <div
      class="c-button-text"
      :class="[
        'c-button-text-' + fontSize,
        { 'c-button-text-number': !isNaN(fontSize) }
      ]"
      :style="styleSize"
    >
      {{ text }}
    </div>
  </button>
</template>

<script>
import icon from './icon.vue'

export default {
  components: {
    icon
  },
  props: {
    /** ボタンに表示するテキスト */
    text: String,
    /** ボタンの色の種類 'default', 'sub', 'dashed-sub', 'emphasis', 'caution', 'disabled', 'emphasis-sub', 'green-sub' */
    type: {
      type: String,
      default: 'default',
      require: false,
      validator: function (value) {
        return (
          [
            'default',
            'sub',
            'dashed-sub',
            'emphasis',
            'caution',
            'disabled',
            'emphasis-sub',
            'green',
            'green-sub'
          ].indexOf(value) !== -1
        )
      }
    },
    /** ボタンの使用用途の種類 'button','submit','reset' */
    buttonType: {
      type: String,
      default: 'button',
      require: false,
      validator: function (value) {
        return ['button', 'submit', 'reset'].indexOf(value) !== -1
      }
    },
    /** ボタンの大きさを指定したい場合 'min', 'thin' */
    size: String,
    /** アイコンを表示したい場合はアイコン名を入力 （色が変わらないため使用不可） */
    iconName: String,
    /** 使用不可の状態 */
    isDisabled: Boolean,
    /** 楕円形 */
    isEllipse: Boolean,
    /** 文字の大きさ ['default', 'title', 'sub-title', 'big', 'large', 'small', 'min'] */
    fontSize: {
      type: [String, Number],
      default: 'default',
      require: false
    }
  },
  computed: {
    iconColor() {
      if (this.type === 'sub') {
        return 'default'
      } else if (this.type === 'emphasis-sub') {
        return 'emphasis'
      } else if (this.type === 'green-sub') {
        return 'green'
      } else {
        return 'function'
      }
    },
    styleSize() {
      if (isNaN(this.fontSize)) return
      return { '--textSize': this.fontSize / 10 + 'rem' }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin dashGradient($color) {
  background-image: linear-gradient(
      to right,
      $color,
      $color adjustVW(4),
      transparent adjustVW(4),
      transparent adjustVW(8)
    ),
    linear-gradient(
      to right,
      $color,
      $color adjustVW(4),
      transparent adjustVW(4),
      transparent adjustVW(8)
    ),
    linear-gradient(
      to bottom,
      $color,
      $color adjustVW(4),
      transparent adjustVW(4),
      transparent adjustVW(8)
    ),
    linear-gradient(
      to bottom,
      $color,
      $color adjustVW(4),
      transparent adjustVW(4),
      transparent adjustVW(8)
    );
}

.c-button {
  --button-color: 'default';
  min-width: adjustVW(204);
  min-height: adjustVW(48);
  padding: 0 $space-small;
  border: $border-transparent;
  border-radius: adjustVW(4);
  @include buttonHover;
  &.default {
    background: $button-primary;
    color: $text-func;
  }
  &.sub {
    border: $border-black;
    background: $button-sub;
    color: $text-main;
  }
  &.dashed-sub {
    position: relative;
    background: $button-sub;
    color: $text-main;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      @include dashGradient($black);
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      background-position: left top, left bottom, left top, right top;
      background-size: adjustVW(8) adjustVW(1), adjustVW(8) adjustVW(1),
        adjustVW(1) adjustVW(8), adjustVW(1) adjustVW(8);
      border-radius: adjustVW(4);
    }
    &[disabled] {
      background: $button-sub;
      color: $border-gray;
      &::after {
        @include dashGradient($border-gray);
      }
    }
  }
  &.emphasis {
    background: $button-emphasis;
    color: $text-func;
  }
  &.emphasis-sub {
    border: adjustVW(1) solid $key-color;
    background: $key-lite;
    color: $key-color;
  }
  &.caution {
    background: $button-caution;
    color: $text-func;
  }
  &.green {
    background: $green;
    color: $text-func;
  }
  &.green-sub {
    border: adjustVW(1) solid $green;
    background: $green-lite;
    color: $green;
  }
  &.disabled {
    background: $border-gray;
    color: $text-func;
    &:hover {
      cursor: unset;
      opacity: 1;
    }
  }
  &[disabled] {
    border: none;
    background: $border-gray;
    color: $text-func;
    &:hover {
      cursor: unset;
      opacity: 1;
    }
  }
  &.min {
    min-width: adjustVW(136);
    border: $border-black;
    background: $button-sub;
    color: $text-main;
  }
  &.thin {
    width: fit-content;
    min-width: inherit;
    min-height: inherit;
  }
  &-text {
    font-size: $text-medium;
    @include text-crop;
    &-title {
      font-size: $text-page-title;
    }
    &-sub-title {
      font-size: $text-page-sub-title;
    }
    &-big {
      font-size: $text-big;
    }
    &-large {
      font-size: $text-large;
    }
    &-small {
      font-size: $text-base;
    }
    &-min {
      font-size: $text-min;
    }
    &-number {
      --textSize: 1.6rem;
      font-size: var(--textSize);
    }
  }
  &-icon {
    display: flex;
    align-items: center;
    &-item {
      margin: 0 $space-sub 0 0;
    }
  }
  &-ellipse {
    border-radius: 9in;
  }
  + .c-button {
    margin: 0 0 0 $space-medium;
  }
}
</style>
