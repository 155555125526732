import Vue from 'vue'

export default (context, inject) => {
  Vue.config.errorHandler = (err, vm, info) => {
    const config = {
      err,
      vm,
      info
    }
    handler(config)
  }

  window.addEventListener('error', (event) => {
    const config = {
      err: event.error
    }
    handler(config)
  })

  window.addEventListener('unhandledrejection', (event) => {
    const config = {
      err: event.reason
    }
    handler(config)
  })
}

const handler = ({ err, vm, info }) => {
  const throwNotSetError = () => {
    const error = new Error(err)
    Vue.prototype.$gtmDataLayer.sendErrorMessage(error.name, error.stack)
    if (
      err.message === 'deprecated' ||
      err.message === 'loadDatasetList without projectId is Deprecated' ||
      err.message === 'projectId required'
    ) {
      const notifyContent = {
        group: 'alerts',
        type: 'warning',
        title: vm.$t('alerts.deprecated.title'),
        text: vm.$t('alerts.deprecated.message'),
        duration: -1,
        data: {
          dismissible: false,
          connectionError: true
        }
      }
      vm.$notify(notifyContent)
    }
    throw error
  }
  throwNotSetError(err)
}
