<template>
  <notifications group="alerts" position="bottom left" width="unset">
    <template v-slot:body="props">
      <text-box
        class="notifications-item"
        :isError="props.item.type !== 'primary'"
        :class="{ 'notifications-item-button': checkCanClick(props) }"
        @dismiss="props.close"
        @click.native="onClick(props)"
      >
        <texts
          v-if="props.item.title"
          class="notifications-title"
          :text="props.item.title"
          :color="props.item.type !== 'primary' ? 'caution' : 'accept'"
          size="small"
          isBold
        />
        <texts
          v-if="props.item.text"
          class="notifications-text"
          :color="props.item.type !== 'primary' ? 'caution' : 'accept'"
          size="small"
        >
          <span v-html="props.item.text" />
        </texts>
        <icons
          class="notifications-button"
          iconName="close"
          :color="props.item.type !== 'primary' ? 'caution' : 'accept'"
          size="min"
          isButton
          @icon-click="props.close"
          @click.native.stop
        />
      </text-box>
    </template>
  </notifications>
</template>

<script>
import icons from '@/components/atoms/icon.vue'
import textBox from '@/components/atoms/text-box.vue'

export default {
  components: {
    textBox,
    icons
  },
  methods: {
    onClick(props) {
      if (this.checkCanClick(props)) {
        this.$router.go(0)
      }
    },
    checkCanClick(props) {
      return props.item?.data?.connectionError
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: adjustVW(40);
    margin: 0 0 $space-medium $space-medium;
    &-button {
      cursor: pointer;
      transition: opacity $transition-base;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &-button {
    position: absolute;
    top: $space-sub;
    right: $space-sub;
    transition: opacity $transition-base;
    &:hover {
      opacity: 0.5;
    }
  }
  &-text {
    white-space: pre-line;
  }
}
.notifications-title + .notifications-text {
  margin-top: $space-text;
}
</style>
