import axios from 'axios'

const WP_URL = 'https://www.matrixflow.net/wp-json/wp/v2/'

const helps = {
  namespaced: true,
  modules: {
    axios
  },
  state: {
    // カテゴリごとのページ情報を格納
    categoryObject: {},
    // カテゴリごとに表示する情報を格納
    reusltObject: {},
    // 全カテゴリで、表示する情報を格納（検索用）
    allArray: []
  },
  getters: {
    categoryObject: (state) => state.categoryObject,
    reusltObject: (state) => state.reusltObject,
    allArray: (state) => state.allArray
  },
  mutations: {
    SET_CATEGORY_OBJECT(state, value) {
      state.categoryObject = value
    },
    SET_RESULT_OBJECT(state, value) {
      state.reusltObject = value
    },
    SET_ALL_ARRAY(state, value) {
      state.allArray = value
    }
  },
  actions: {
    async getManualList({ commit }) {
      const categoryMetaList = await axios.get(WP_URL + 'categories', {
        params: {
          per_page: 100
        }
      })
      const manualId = categoryMetaList.data.find(
        (item) => item.slug === 'manual'
      )?.id
      const categoryObject = {}
      categoryMetaList.data.forEach((item) => {
        // manualを親に持つカテゴリだけ持ってくる
        if (manualId && item.parent === manualId) {
          Object.defineProperty(categoryObject, item.slug, {
            value: {
              name: item.slug,
              id: item.id,
              count: item.count
            },
            enumerable: true
          })
        }
      })
      commit('SET_CATEGORY_OBJECT', categoryObject)

      const reusltObject = {}
      const allArray = []
      for (const [key, value] of Object.entries(categoryObject)) {
        const categoryId = value.id
        if (categoryId) {
          // TODO: 各カテゴリごとのページ数が100件を超える場合の処理
          const res = await axios.get(WP_URL + 'posts', {
            params: {
              categories: categoryId,
              per_page: 100,
              page: 1
            }
          })
          const resultArray = []
          res.data.forEach((item) => {
            // タグと空白文字を削除したcontent 後ほど変換処理があるので100文字で制限
            let dispDesc = item.content.rendered
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/\s/gi, '')
              .substring(0, 100)

            // 10進数のUnicodeを変換する
            const match = [...dispDesc.matchAll(/&#(\d{2,5});/gi)]
            if (match.length > 0) {
              match.forEach((item) => {
                dispDesc = dispDesc.replace(
                  item[0],
                  String.fromCharCode(item[1])
                )
              })
            }

            resultArray.push({
              // ID
              id: item.id,
              // 表示する文字列
              content: item.title.rendered,
              // リンク
              link: item.link,
              description: dispDesc
            })
          })
          reusltObject[key] = resultArray
          Array.prototype.push.apply(allArray, resultArray)
        }
      }
      commit('SET_RESULT_OBJECT', reusltObject)
      commit('SET_ALL_ARRAY', allArray)
    }
  }
}

export default helps
