import { loading } from './loading'
import { uploadViaSocket } from '@/lib/upload.js'

const datasource = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    dataSources: [],
    submitting: false
  },
  getters: {
    datasourceList: (state) => state.dataSources,
    submitting: (state) => state.submitting,
    datasourceLoading: (_state, getters) => true // getters['loading/loading']
  },
  mutations: {
    SET_DATASOURCES(state, value) {
      state.dataSources = value
    },
    SET_DATASOURCE_BY_ID(state, { id, value }) {
      const idx = state.dataSources.findIndex((x) => x.id === id)
      Object.assign(state.dataSources[idx], value)
    },
    ADD_DATASOURCE(state, value) {
      state.dataSources.push(value)
    },
    DELETE_DATASOURCE_BY_ID(state, { id }) {
      const idx = state.dataSources.findIndex((x) => x.id === id)
      state.dataSources.splice(idx, 1)
    },
    SET_SUBMITTING(state, value) {
      state.submitting = value
    },
    SET_LOADING(state, value) {
      state.dataSources.loading = value
    }
  },
  actions: {
    async fetchDataSources({ commit }) {
      commit('SET_LOADING', true)
      try {
        const res = await this._vm.$sendMessageAndReceive({
          action: 'listDataSource'
        })
        commit('SET_DATASOURCES', res.result)
      } catch (ex) {
        this._vm.log_info(ex)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addDataSource({ commit, dispatch }, payload) {
      commit('SET_SUBMITTING', true)
      try {
        const req = {
          action: 'addDataSource',
          ...payload
        }

        const res = await this._vm.$sendMessageAndReceive(req)
        // commit('ADD_DATASOURCE', res.result)
        dispatch('fetchDataSources')

        return res
      } catch (ex) {
      } finally {
        commit('SET_SUBMITTING', false)
      }
    },
    async deleteDataSource({ commit }, { id }) {
      commit('DELETE_DATASOURCE_BY_ID', { id })
      const res = await this._vm.$sendMessageAndReceive({
        action: 'deleteDataSource',
        id: id
      })
      if (res.status === 'error') {
        throw res
      }
    },
    async fetchSqlList({ commit }, id) {
      try {
        const res = await this._vm.$sendMessageAndReceive({
          action: 'listImportSql',
          id
        })
        return res.result
      } catch (ex) {}
    },
    async fetchSqlTablePreviewData({ commit }, { id, tableName }) {
      try {
        const res = await this._vm.$sendMessageAndReceive({
          action: 'getDataSourceItem',
          id,
          tableName
        })
        return res.result
      } catch (ex) {}
    },
    async importDatasource({ commit, dispatch }, payload) {
      const messageId = await this._vm.$sendMessage(payload)
      try {
        let res
        await this._vm.$watchProgress(messageId, {
          importFromDataSource: (msg) => {
            res = msg
          }
        })
        return res
      } catch (ex) {}
    },
    async testDataSourceConnection({ commit, dispatch }, payload) {
      try {
        const req = {
          action: 'testDataSource',
          ...payload
        }
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.status
      } catch (ex) {
        return 'error'
      }
    },
    async testBigQueryDataSourceConnection(
      { commit, dispatch },
      { value, data }
    ) {
      try {
        const req = {
          action: 'testBigQueryDataSource',
          ...value
        }
        const startRes = await this._vm.$sendMessageAndReceive(req)
        const messageId = startRes.m_id

        const uploadTask = uploadViaSocket(this._vm.$socket, data, {
          chunkSize: 1e5,
          header: {
            action: 'uploadingData',
            m_id: messageId
          }
        })
        let status
        await Promise.all([
          uploadTask,
          this._vm.$watchProgress(messageId, {
            uploadingLearningData: (res) => {
              if (res.status === 'error') {
                throw new Error()
              }
            },
            uploaded: (res) => {
              status = res.status
              if (res.status === 'error') {
                throw new Error()
              }
            }
          })
        ])
        return status
      } catch (ex) {
        return 'error'
      }
    },
    async editDataSource({ commit, dispatch }, payload) {
      commit('SET_SUBMITTING', true)
      try {
        await this._vm.$sendMessageAndReceive(payload)
        dispatch('fetchDataSources')
      } catch (ex) {
        // TODO エラーの設置
      } finally {
        commit('SET_SUBMITTING', false)
      }
    },
    async editBigQueryDataSource({ commit, dispatch }, { req, data }) {
      try {
        const startRes = await this._vm.$sendMessageAndReceive(req)
        const messageId = startRes.m_id

        const uploadTask = uploadViaSocket(this._vm.$socket, data, {
          chunkSize: 1e5,
          header: {
            action: 'uploadingData',
            m_id: messageId
          }
        })
        await Promise.all([
          uploadTask,
          this._vm.$watchProgress(messageId, {
            uploadingLearningData: (res) => {
              if (res.status === 'error') {
                throw new Error()
              }
            },
            uploaded: (res) => {
              if (res.status === 'error') {
                throw new Error()
              }
            }
          })
        ])
        dispatch('fetchDataSources')
      } catch (ex) {
        // TODO エラーの設置
      }
    },
    async editSnowflakeDataSource({ commit, dispatch }, payload) {
      commit('SET_SUBMITTING', true)
      try {
        await this._vm.$sendMessageAndReceive(payload)
        dispatch('fetchDataSources')
      } catch (ex) {
        // TODO エラーの設置
      } finally {
        commit('SET_SUBMITTING', false)
      }
    }
  }
}

export default datasource
