import { loading } from './loading'
import { uploadViaSocket } from '@/lib/upload.js'

const rpcTimeout = 10

const service = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    projectTemplates: {},
    created: {
      projectId: null,
      projectTemplate: {},
      effectId: null
    },
    uploadState: {
      effect_m_id: null,
      thumbnail_m_id: null
    }
  },
  getters: {
    createdProject: (state) => state.created,
    createdProjectTemplate: (state) => state.created.projectTemplate,
    projectList: (state) => state.projectTemplates,
    projectError: (_state, getters) => getters['loading/error'],
    projectLoading: (_state, getters) => getters['loading/loading'],
    uploadState: (state) => state.uploadState
  },
  mutations: {
    SET_CREATED_PROJECT(state, { projectId }) {
      state.created.projectId = projectId
    },
    SET_CREATED_PROJECT_TEMPlATE(state, { projectTemplate }) {
      state.created.projectTemplate = projectTemplate
    },
    SET_CREATED_TEMPlATE_EFFECT(state, { templateEffectId }) {
      state.created.effectId = templateEffectId
    },
    SET_ITEM_LIST(state, value) {
      state.projectTemplates = value
    },
    SET_PROJECT_TEMPLATE_DETAIL(state, { projectTemplateId, value }) {
      state.projectTemplates[projectTemplateId] = value
    },
    SET_UPLOAD_EFFECT_IMAGE(state, { mId }) {
      state.uploadState.effect_m_id = mId
    },
    SET_UPLOAD_THUMBNAIL_IMAGE(state, { mId }) {
      state.uploadState.thumbnail_m_id = mId
    }
  },
  actions: {
    activateProjectTemplate: async function (
      { commit, dispatch },
      { projectTemplateId }
    ) {
      const req = {
        action: 'activateProjectTemplate',
        projectTemplateId
      }

      await dispatch('remoteCall', req)
    },
    addProjectTemplateEffect: async function (
      { commit, dispatch },
      { projectTemplateId, name, description, formula = {} }
    ) {
      await dispatch('remoteCall', {
        action: 'addProjectTemplateEffect',
        projectTemplateId,
        name,
        description,
        formula
      })
    },
    addProjectTemplateEffectImage: async function (
      { commit, dispatch },
      { fileName, fileSize, imageIndex, name, projectTemplateId }
    ) {
      await dispatch('remoteCall', {
        action: 'addProjectTemplateEffectImage',
        fileName,
        fileSize,
        imageIndex,
        name,
        projectTemplateId
      })
    },
    addProjectTemplateUsageRestriction: async function (
      { dispatch },
      { projectTemplateId, restriction, revision = null }
    ) {
      const req = {
        action: 'addProjectTemplateUsageRestriction',
        projectTemplateId,
        restriction: restriction,
        revision
      }

      await dispatch('remoteCall', req)
    },
    addProjectTemplateSampleDataset: async function (
      { commit, dispatch },
      { projectTemplateId, datasetId }
    ) {
      const res = await dispatch('remoteCall', {
        action: 'addProjectTemplateSampleDataset',
        projectTemplateId,
        datasetId
      })
    },
    addProjectTemplateTag: async function (
      { commit, dispatch },
      { projectTemplateId, tags }
    ) {
      const req = {
        action: 'addProjectTemplateTag',
        projectTemplateId,
        tags
      }

      await dispatch('remoteCall', req)
    },
    cloneProjectTemplate: async function (
      { commit, dispatch },
      { templateId, revision, name, description, tags }
    ) {
      dispatch('loading/start')

      try {
        const req = {
          action: 'cloneProjectTemplate',
          templateId,
          revision,
          name,
          description,
          tags
        }

        const res = await this._vm.$sendMessageAndReceive(req)

        if (res.status === 'error') {
          throw new Error(res)
        } else {
          const result = res.result

          commit('SET_CREATED_PROJECT', { projectId: result.id })
        }
      } catch (err) {
        dispatch('loading/error', err)
        throw new Error(err)
      } finally {
        dispatch('loading/finish')
      }
    },
    createProjectTemplate: async function (
      { commit, dispatch },
      {
        projectId,
        name,
        description,
        wantToAchieve,
        predictedAndClassified,
        industryOccupation,
        tags
      }
    ) {
      const res = await dispatch('remoteCall', {
        action: 'createProjectTemplate',
        projectId,
        name,
        description,
        wantToAchieve,
        predictedAndClassified,
        industryOccupation,
        tags
      })

      const projectTemplate = Object.assign(res, {
        id: String(res.id)
      })

      commit('SET_CREATED_PROJECT_TEMPlATE', { projectTemplate })
    },
    deleteProjectTemplate: async function (
      { commit, dispatch },
      { templateId }
    ) {
      try {
        const req = {
          action: 'deleteProjectTemplate',
          projectTemplateId: templateId
        }

        await dispatch('remoteCall', req)
      } catch (err) {
        dispatch('loading/error', err)
      }
    },
    loadProjectList: async function ({ commit, dispatch }) {
      dispatch('loading/start')
      try {
        const response = await this._vm.$sendMessageAndReceive({
          action: 'listProjectTemplate'
        })

        if (response.status !== 'error') {
          const obj = {}
          response.result.forEach((item) => {
            obj[item.id] = item
          })
          commit('SET_ITEM_LIST', obj)
        } else {
          throw response
        }
      } catch (e) {
        dispatch('loading/error', e)
      } finally {
        dispatch('loading/finish')
      }
    },
    loadProjectTemplateDetail: async function (
      { commit, dispatch },
      { projectTemplateId }
    ) {
      dispatch('loading/start')

      try {
        const res = await dispatch('remoteCall', {
          action: 'detailProjectTemplate',
          templateId: projectTemplateId
        })

        commit('SET_PROJECT_TEMPLATE_DETAIL', {
          projectTemplateId,
          value: res
        })
      } catch (err) {
        dispatch('loading/error', err)
      } finally {
        dispatch('loading/finish')
      }
    },
    notifyError: function (context, response) {
      this._vm.log_info(response)
    },
    remoteCall: async function ({ dispatch }, request) {
      try {
        const response = await this._vm.$sendMessageAndReceive(request)

        if (response.status === 'error') {
          dispatch('notifyError', response)
          throw response
        } else {
          return response.result
        }
      } catch (error) {
        dispatch('notifyError', error)
        throw error
      }
    },
    removeProjectTemplateTag: async function (
      { commit, dispatch },
      { projectTemplateId, tags }
    ) {
      const req = {
        action: 'removeProjectTemplateTag',
        projectTemplateId,
        tags
      }

      await dispatch('remoteCall', req)
    },

    startImageUploading: async function (
      { commit, dispatch },
      { fileName, fileSize, imageIndex, projectTemplateId }
    ) {
      const res = await dispatch('remoteCall', {
        action: 'startImageUploading',
        fileName,
        fileSize,
        imageIndex,
        projectTemplateId
      })

      commit('SET_UPLOAD_EFFECT_IMAGE', { mId: res.m_id })

      return res
    },
    updateProjectTemplateThumbnail: async function (
      { commit, dispatch },
      { projectTemplateId, fileName, fileSize }
    ) {
      const req = {
        action: 'updateProjectTemplateThumbnail',
        fileName,
        fileSize,
        projectTemplateId
      }
      const res = await dispatch('remoteCall', req)

      commit('SET_UPLOAD_THUMBNAIL_IMAGE', { mId: res.m_id })
    },
    updateProjectTemplateEffect: async function (
      { commit, dispatch },
      { projectTemplateId, name, description, formula = {} }
    ) {
      const req = {
        action: 'updateProjectTemplateEffect',
        projectTemplateId,
        name,
        description,
        formula
      }

      await dispatch('remoteCall', req)
    },
    updateProjectTemplateEffectImage: async function (
      { commit, dispatch },
      { fileName, fileSize, imageIndex, projectTemplateId, revision = {} }
    ) {
      const req = {
        action: 'updateProjectTemplateEffectImage',
        fileName,
        fileSize,
        imageIndex,
        projectTemplateId
      }

      const res = await dispatch('remoteCall', req)
      commit('SET_UPLOAD_EFFECT_IMAGE', { mId: res.m_id })
    },
    updateProjectTemplateSampleDataset: async function (
      { commit, dispatch },
      { projectTemplateId, sampleDatasetId, revision = null }
    ) {
      const req = {
        action: 'updateProjectTemplateSampleDataset',
        projectTemplateId,
        revision,
        sampleDatasetId
      }

      await dispatch('remoteCall', req)
    },
    updateProjectTemplateSummary: async function (
      { commit, dispatch },
      {
        projectTemplateId,
        name,
        description,
        wantToAchieve,
        predictedAndClassified,
        industryOccupation,
        restriction
      }
    ) {
      const req = {
        action: 'updateProjectTemplateSummary',
        projectTemplateId,
        summary: {
          name,
          description,
          wantToAchieve,
          predictedAndClassified,
          industryOccupation,
          restriction
        }
      }

      await dispatch('remoteCall', req)
    },
    uploadImage: async function (
      { commit, dispatch },
      { action, messageId, file }
    ) {
      const uploadTask = uploadViaSocket(this._vm.$socket, file, {
        chunkSize: 1e5,
        header: {
          action: action,
          m_id: messageId
        }
      })
      try {
        await Promise.all([
          uploadTask,
          this._vm.$watchProgress(messageId, {
            uploaded: (res) => {}
          })
        ])
      } catch (ex) {
        throw new Error(ex)
      }
    }
  }
}

export default service
