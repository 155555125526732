function getLocalSettings() {
  const localSettingsStr = localStorage.getItem('settings')
  const localSettings = JSON.parse(localSettingsStr)
    ? JSON.parse(localSettingsStr)
    : {}
  return localSettings
}

const localSettings = getLocalSettings()
function setLocalSettings(key, value) {
  const localSettings = getLocalSettings()
  localSettings[key] = value
  localStorage.setItem('settings', JSON.stringify(localSettings))
}

export function getDefaultLanguage() {
  let language = null
  if (localSettings.language) {
    language = localSettings.language
  } else {
    language =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      window.navigator.userLanguage ||
      window.navigator.browserLanguage
    language = language ? language.split('-')[0] : 'en'
    setLocalSettings('language', language)
  }
  return language
}

function getTimezoneDiff() {
  const hours = (new Date().getTimezoneOffset() / 60) * -1
  let diff
  if (hours >= 0) {
    diff = '+' + hours
  } else {
    diff = '-' + hours
  }
  return diff
}

function getSettings(name, defaultValue) {
  if (localSettings[name]) {
    return localSettings[name]
  } else {
    setLocalSettings(name, defaultValue)
    return defaultValue
  }
}

const setting = {
  namespaced: true,
  state: {
    themeColor: '#850491',
    language: getDefaultLanguage(),
    timezoneDiff: getSettings('timezoneDiff', getTimezoneDiff()),
    imagesPerPage: parseInt(getSettings('imagesPerPage', 6)),
    rowsPerPage: parseInt(getSettings('rowsPerPage', 20)),
    fallbackMode: false,
    webSocketConnection: false
  },
  getters: {
    themeColor: (state) => state.themeColor,
    timezoneDiff: (state) => state.timezoneDiff,
    imagesPerPage: (state) => state.imagesPerPage,
    rowsPerPage: (state) => state.rowsPerPage,
    language: (state) => state.language,
    fallbackMode: (state) => state.fallbackMode,
    webSocketConnection: (state) => state.webSocketConnection
  },
  mutations: {
    SET_LANGUAGE(state, value) {
      state.language = value
      setLocalSettings('language', value)
    },
    SET_TIMEZONE_DIFF(state, value) {
      state.timezoneDiff = value
      setLocalSettings('timezoneDiff', value)
    },
    SET_IMAGES_PAR_PAGE(state, value) {
      state.imagesPerPage = value
      setLocalSettings('imagesPerPage', value)
    },
    SET_ROWS_PER_PAGE(state, value) {
      state.rowsPerPage = value
      setLocalSettings('rowsPerPage', value)
    },
    SET_WEB_SOCKET_CONNECTION(state, value) {
      state.webSocketConnection = value
    },
    ENABLE_FALLBACK(state) {
      state.fallbackMode = true
    }
  },
  actions: {
    setTimezoneDiff({ commit }, value) {
      commit('SET_TIMEZONE_DIFF', value)
    },
    setImagesPerPage({ commit }, value) {
      commit('SET_IMAGES_PAR_PAGE', parseInt(value))
    },
    setRowsPerPage({ commit }, value) {
      commit('SET_ROWS_PER_PAGE', parseInt(value))
    },
    setLanguage({ state, commit }, { value, i18n }) {
      commit('SET_LANGUAGE', value)
      i18n.locale = value
    },
    changeLanguage({ state, commit }, { value, i18n }) {
      commit('SET_LANGUAGE', value)
      i18n.locale = value
    },
    toggleLanguage({ state, commit }, { i18n }) {
      const langs = ['ja', 'en']
      const value =
        langs[(langs.findIndex((x) => x === i18n.locale) + 1) % langs.length]
      commit('SET_LANGUAGE', value)
      i18n.locale = value
    },
    enableFallback({ commit }) {
      commit('ENABLE_FALLBACK')
    },
    setWebSocketConnectionStatus({ commit }, bool) {
      commit('SET_WEB_SOCKET_CONNECTION', bool)
    }
  }
}

export default setting
