import axios from 'axios'

import { loading } from './loading'

const getDefaultState = () => ({
  loggedIn: null,
  name: '',
  accountId: null,
  email: '',
  emailVerified: null,
  plan: 'wait',
  role: '',
  lastSigninAt: '',
  totalDataSize: 0,
  planDetail: {
    maxDataSize: 0,
    name: '',
    numModel: 0,
    maxServices: 0
    // maxProjects: 0 プロジェクトの作成ができるようになったら追加 https://trello.com/c/4HmmOSiP/179-%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AE%E4%BD%9C%E6%88%90%E3%81%8C%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%88%E3%81%86%E3%81%AB%E3%81%AA%E3%81%A3%E3%81%9F%E3%82%89%E3%80%81%E6%9C%80%E5%A4%A7%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AE%E4%BD%9C%E6%88%90%E5%8F%AF%E8%83%BD%E6%95%B0%E3%81%AE%E8%A8%AD%E5%AE%9A%E3%82%92%E8%BF%BD%E5%8A%A0
  },
  organizationInfo: {},
  otpEnabled: false,
  otpAuthState: false,
  tutorialInfo: {
    communityLink: false,
    optimization: false,
    optimizationResult: false,
    projectList: false,
    selectTargetColumn: false
  },
  usedLearningTimeSec: 0,
  usedGPULearningTimeSec: 0
})

const auth = {
  namespaced: true,
  modules: {
    loading
  },
  state: getDefaultState,
  getters: {
    accountId: (state) => state.accountId,
    accountInfo: (state) => state,
    accountInfoLoading: (_state, getters) => getters['loading/loading'],
    loggedIn: (state) => state.loggedIn,
    otpAuthState: (state) => state.otpAuthState,
    email: (state) => state.email
  },
  mutations: {
    updateAccountInfo(state, { account }) {
      Object.assign(state, account)
    },
    clearAccoutInfo(state) {
      Object.assign(state, getDefaultState())
    },
    loadedOrgPage(state) {
      state.fetchOrgloading = true
    },
    SET_OTP_AUTH_STATE(state, value) {
      state.otpAuthState = value
    },
    SET_OTP_ENABLED(state, value) {
      state.otpEnabled = value
    },
    setLoginState(state, value) {
      state.loggedIn = value
    }
  },
  actions: {
    async fetchAccountInfo({ commit, dispatch }) {
      dispatch('loading/start')
      try {
        const accountReq = { action: 'getAccountInfo' }
        const res = await this._vm.$sendMessageAndReceive(accountReq)
        commit('updateAccountInfo', res)
      } catch (e) {
        dispatch('loading/error', e)
      } finally {
        dispatch('loading/finish')
      }
    },
    cancelOTP: async function ({ commit }) {
      commit('SET_OTP_AUTH_STATE', false)
    },
    setOtpEnabled({ commit }, value) {
      commit('SET_OTP_ENABLED', value)
    },
    fetchLoginInfo: async function ({ state, commit }) {
      const res = await axios.get('/api/is_logged_in')
      const value = res.data.status === 'success'
      if (!state.loggedIn && value) {
        commit('setLoginState', value)

        await this._vm.$reconnectSocket({ ifDead: true })
      }
    },
    sendOTP: async function ({ commit, dispatch, state }, { otpToken }) {
      let res
      try {
        res = await axios.post('/api/signin_otp', {
          token: otpToken
        })
      } catch (e) {
        const data = e.response && e.response.data
        if (e.request.status === 401) {
          if (data && data.reason) {
            throw new Error(data.reason)
          }
          throw new Error('INCORRECT_OTP')
        } else if (e.request.status === 0) {
          const connectionRefusedError = new Error('CONNECTION_REFUSED')
          throw connectionRefusedError
        } else {
          const serverError = new Error('SERVER_ERROR')
          throw serverError
        }
      }

      if (res.data.status === 'success') {
        await this._vm.$reconnectSocket()
        await dispatch('fetchLoginInfo')
        dispatch('fetchAccountInfo')
      }
    },
    signIn: async function ({ commit, dispatch }, { accountId, password }) {
      let res = null
      try {
        res = await axios.post('/api/signin', {
          accountId: accountId,
          password: password
        })
      } catch (e) {
        const statusCode = e.request.status

        const data = e.response?.data
        const reason = data?.reason

        if (statusCode === 401) {
          if (data && reason) {
            throw new Error(data.reason)
          }
          throw new Error('INCORRECT_ID_OR_PASSWORD')
        }

        if (statusCode === 403) throw new Error('NOT_ALLOWED_IP')
        if (statusCode === 0) throw new Error('CONNECTION_REFUSED')
        if (reason) throw new Error(reason)

        throw new Error('SERVER_ERROR')
      }

      if (res.data.status === 'success') {
        commit('SET_OTP_AUTH_STATE', false)

        await this._vm.$reconnectSocket()
        await dispatch('fetchLoginInfo')

        dispatch('fetchAccountInfo')
      } else if (res.data.status === 'totp_needed') {
        commit('SET_OTP_AUTH_STATE', true)
      } else {
        throw new Error(res.data.reason)
      }
    },
    async signout({ commit }) {
      commit('SET_OTP_AUTH_STATE', false)
      await axios.get('/api/signout')
      commit('setLoginState', false)
      commit('clearAccoutInfo')
      location.reload()
    },
    async connectionClosedSignout({ commit }) {
      // コネクションが切れたときに実行される。APIが生きている状態であれば、ログアウト処理。死んでいればそのままリロードをする。（APIが死んでるのでログインに戻される）
      try {
        commit('SET_OTP_AUTH_STATE', false)
        await axios.get('/api/signout')
        commit('setLoginState', false)
        commit('clearAccoutInfo')
        location.reload()
      } catch {
        location.reload()
      }
    },
    updateTutorialInfo: async function (
      { dispatch },
      {
        accountId,
        communityLink,
        optimization,
        optimizationResult,
        projectList,
        selectTargetColumn
      }
    ) {
      const req = {
        action: 'updateTutorialInfo',
        accountId,
        communityLink,
        optimization,
        optimizationResult,
        projectList,
        selectTargetColumn
      }
      await this._vm.$sendMessageAndReceive(req)

      dispatch('fetchAccountInfo')
    }
  }
}

export default auth
