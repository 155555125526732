import { i18n } from '@/main.js'
import { loading } from './loading'
import axios from 'axios'

const rpcTimeout = 10

const preprocessings = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    dataId: null,
    dataType: null,
    autoPreprocessingApplied: false,

    EDARecipes: {},
    EDARecipeDetail: {},
    loadingPreProc: false,
    loadingEDARecipes: false,
    loadingEDARecipeDetail: false,
    loadingEDARecipeDelete: false,

    versionHistory: [],
    preProcHeadVersion: null,
    preProcLatestVersion: 0,
    table: [],
    columns: [],
    graph: {},
    describe: [],
    linePlotValues: {},
    boxPlotData: {},
    appliedChart: {},
    outlierNums: {}
  },
  getters: {
    dataId: (state) => state.dataId,
    dataType: (state) => state.dataType,
    autoPreprocessingApplied: (state) => state.autoPreprocessingApplied,

    EDARecipes: (state) => state.EDARecipes,
    EDARecipeDetail: (state) => state.EDARecipeDetail,
    loadingPreProc: (state) => state?.loadingPreProc,
    loadingEDARecipes: (state) => state?.loadingEDARecipes,
    loadingEDARecipeDetail: (state) => state?.loadingEDARecipeDetail,
    loadingEDARecipeDelete: (state) => state?.loadingEDARecipeDelete,

    preProcHeadVersion: (state) => state.preProcHeadVersion,
    preProcLatestVersion: (state) => state.preProcLatestVersion,
    graph(state) {
      return state.graph
    },
    describe: (state) => state.describe,
    table(state) {
      return state.table
    },
    columns: (state) => state.columns,
    linePlotValues: (state) => state.linePlotValues,
    boxPlotData: (state) => state.boxPlotData,
    appliedChart: (state) => state.appliedChart
  },
  mutations: {
    RESET_AUTO_PREPROCESSING_APPLIED(state) {
      state.autoPreprocessingApplied = false
    },
    SET_AUTO_PREPROCESSING_APPLIED(state, value) {
      state.autoPreprocessingApplied = value
    },
    SET_PREPROC_HEAD_VERSION(state, value) {
      if (value > state.preProcLatestVersion) {
        state.preProcLatestVersion = value
      }
      state.preProcHeadVersion = value
    },
    SET_PREPROC_LATEST_VERSION(state, value) {
      state.preProcLatestVersion = value
    },
    SET_LINE_PLOT_VALUES(state, value) {
      state.linePlotValues = value
    },
    SET_BOX_PLOT_DATA(state, value) {
      state.boxPlotData = value
    },
    SET_DATA(
      state,
      {
        dataId,
        dataType,
        graph,
        describe,
        table,
        columnNulls,
        appliedChart,
        dtypes,
        outlierNums
      }
    ) {
      state.dataId = dataId

      state.graph = graph ?? {}
      state.describe = describe ?? []

      state.table = table
      state.outlierNums = outlierNums ?? {}

      if (table?.length && table.length > 0) {
        state.columns = []
        const heads = Object.keys(table[0])
        for (const k in heads) {
          state.columns.push({
            id: k,
            name: heads[k],
            nNull: columnNulls[heads[k]] ?? null,
            dtype: dtypes[heads[k]],
            outlierNums: state.outlierNums[heads[k]] ?? 0
          })
        }
      } else {
        state.columns = []
      }

      state.dataType = dataType
      state.appliedChart = appliedChart
    },
    DELETE_CACHE(state, value) {
      const vh = state.versionHistory
      for (let i = value + 1; i < vh.length; i++) {
        state.versionHistory[i] = null
      }
    },
    SET_LOADING(state, value) {
      state.loadingPreProc = value
    },
    UPDATE_EDA_RECIPE(state, { EDARecipeId, value }) {
      state.EDARecipeDetail = value.detail
    },
    SET_EDA_RECIPES(state, { edaRecipes }) {
      state.EDARecipes = edaRecipes
    },
    SET_EDA_RECIPES_LOADING(state, value) {
      state.loadingEDARecipes = value
    },
    SET_EDA_RECIPE_DETAIL_LOADING(state, value) {
      state.loadingEDARecipeDetail = value
    },
    SET_EDA_RECIPES_DELETE_LOADING(state, value) {
      state.loadingEDARecipeDelete = value
    },
    SET_SAVE_STATE(state, value) {
      state.savingPreprocessedData = value
    },
    RESET_DATA(state) {
      state.graph = {}
      state.table = []
      state.describe = []
      state.columns = []
      state.dataType = null
      state.appliedChart = {}
      state.dataId = null
      state.outlierNums = {}
    },
    RESET_BOX_PLOT_DATA(state) {
      state.boxPlotData = {}
    }
  },
  actions: {
    notifyError: function (_context, response) {
      this._vm.log_info(response)
    },
    remoteCall: async function ({ dispatch }, request) {
      try {
        const response = await Promise.race([
          this._vm.$sendMessageAndReceive(request),
          new Promise((resolve) => setTimeout(resolve, rpcTimeout * 1000)).then(
            () => {
              return {
                status: 'error',
                message: 'RPC_TIMEOUT'
              }
            }
          )
        ])

        if (response.status === 'error') {
          dispatch('notifyError', response)

          throw response
        } else {
          return response.result
        }
      } catch (error) {
        dispatch('notifyError', error)
        throw error
      }
    },

    async getLinePlotData({ state, commit, dispatch }, { yColId, xColId }) {
      const yColumnName = state.columns[yColId].name
      const xColumnName = xColId ? state.columns[xColId].name : ''
      const req = {
        action: 'getLinePlotData',
        dataId: state.dataId,
        version: state.preProcHeadVersion,
        yColumnName: yColumnName,
        xColumnName: xColumnName
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      commit('SET_LINE_PLOT_VALUES', res.values)
    },
    async getSelectedLearningDataBoxPlot(
      { state, commit, dispatch },
      { dataId = null, columnName }
    ) {
      try {
        let version

        if (dataId === null) {
          dataId = state.dataId
        }

        if (state.preProcHeadVersion !== null && state.dataId === dataId) {
          version = state.preProcHeadVersion
        }

        const req = {
          action: 'getSelectedLearningDataBoxPlot',
          dataId,
          version,
          columnName
        }
        const res = await this._vm.$sendMessageAndReceive(req)

        commit('SET_BOX_PLOT_DATA', res.data)
      } catch (ex) {
        dispatch('notifyError', ex)
        throw ex
      }
    },

    async resetPreProcessing({ state, commit }) {
      if (!state.dataId) return
      const req = {
        action: 'resetPreprocessing',
        dataId: state.dataId
      }
      await this._vm.$sendMessage(req)

      commit('SET_PREPROC_HEAD_VERSION', 0)
      commit('SET_PREPROC_LATEST_VERSION', 0)
      commit('DELETE_CACHE', 0)
      commit('SET_AUTO_PREPROCESSING_APPLIED', false)
    },
    resetPreProcessingData({ state, commit }) {
      commit('RESET_DATA')
    },
    resetPreprocessingState: function ({ dispatch }) {
      dispatch('resetAutoPreprocessingApplied')
    },
    resetAutoPreprocessingApplied: function ({ commit }) {
      commit('RESET_AUTO_PREPROCESSING_APPLIED')
    },
    async deleteColumn({ state, commit, dispatch }, { colId }) {
      const columnName = state.columns[colId].name
      const req = {
        action: 'dropColumn',
        dataId: state.dataId,
        columnName: columnName,
        version: state.preProcHeadVersion
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      for (let i = 0; i < state.columns.length; i++) {
        const column = state.columns[i]
        if (column.id === colId) {
          this._vm.$delete(state.columns, i)
          break
        }
      }
      const resVersion = res.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async replaceRowValues(
      { state, commit, dispatch },
      { colId, rowValues, rowReplacingValues, fillingValue }
    ) {
      const columnName = state.columns[colId].name
      const req = {
        action: 'replaceValues',
        dataId: state.dataId,
        targetColumn: columnName,
        oldValues: rowValues,
        newValues: rowReplacingValues,
        fillingValue: fillingValue,
        version: state.preProcHeadVersion
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      const resVersion = res.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async replaceRowValuesOneHot({ state, commit, dispatch }, { colId }) {
      const columnName = state.columns[colId].name
      const req = {
        action: 'replaceValuesOneHot',
        dataId: state.dataId,
        targetColumn: columnName,
        version: state.preProcHeadVersion
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      const resVersion = res.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async replaceRowValuesDummy({ state, commit, dispatch }, { colId }) {
      const columnName = state.columns[colId].name
      const req = {
        action: 'replaceValuesDummy',
        dataId: state.dataId,
        targetColumn: columnName,
        version: state.preProcHeadVersion
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      const resVersion = res.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async fillNullValue({ state, commit, dispatch }, { colId, fillingValue }) {
      const columnName = state.columns[colId].name
      const req = {
        action: 'fillNull',
        dataId: state.dataId,
        columnName: columnName,
        fillingValue: fillingValue,
        version: state.preProcHeadVersion
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      const resVersion = res.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async dropNullValue({ state, commit, dispatch }, { colId }) {
      const columnName = state.columns[colId].name
      const req = {
        action: 'dropNull',
        dataId: state.dataId,
        columnName: columnName,
        version: state.preProcHeadVersion
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      const resVersion = res.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async deleteOutlier({ state, commit }, { colId }) {
      const columnName = state.columns[colId].name

      const req = {
        action: 'dropOutlier',
        dataId: state.dataId,
        columnName: columnName,
        version: state.preProcHeadVersion
      }
      const res = await this._vm.$sendMessageAndReceive(req)

      const resVersion = res.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async applyStandardize({ state, commit, dispatch }, { colId }) {
      const columnName = state.columns[colId].name
      const result = await axios.post('/api/mf/preproc/standardize', {
        dataId: state.dataId,
        targetColumn: columnName,
        version: state.preProcHeadVersion
      })
      if (result.status !== 200) {
        throw new Error('PREPROC_APPLY_FAILED')
      }
      const resVersion = result.data.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async applyYeoJohnson({ state, commit, dispatch }, { colId }) {
      const columnName = state.columns[colId].name
      const result = await axios.post('/api/mf/preproc/yeo-johnson', {
        dataId: state.dataId,
        targetColumn: columnName,
        version: state.preProcHeadVersion
      })
      if (result.status !== 200) {
        throw new Error('PREPROC_APPLY_FAILED')
      }
      const resVersion = result.data.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async applyBinning({ state, commit, dispatch }, { colId, binningBorders }) {
      const columnName = state.columns[colId].name
      const result = await axios.post('/api/mf/preproc/binning', {
        dataId: state.dataId,
        targetColumn: columnName,
        version: state.preProcHeadVersion,
        binningBorders
      })
      if (result.status !== 200) {
        throw new Error('PREPROC_APPLY_FAILED')
      }
      const resVersion = result.data.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    async moveVersion({ state, commit, dispatch }, relative) {
      this._vm.log_info('moveVersion', relative, this)
      let targetVersion = state.preProcHeadVersion + relative
      if (targetVersion > state.preProcLatestVersion) {
        targetVersion = state.preProcLatestVersion
      } else if (targetVersion < 0) {
        targetVersion = 0
      }
      commit('SET_PREPROC_HEAD_VERSION', targetVersion)
    },
    async fetchSelectedLearningDataForAnalysis(
      { state, commit, rootGetters },
      {
        type = null,
        id = null,
        reload = true,
        withGraph = false,
        withOutlier = false,
        withDescribe = false,
        noLoading = false
      }
    ) {
      try {
        if (!noLoading) {
          commit('SET_LOADING', true)
        }
        let version
        if (id === null) {
          id = state.dataId
        }
        if (type === null) {
          type = state.dataType
        }
        if (state.preProcHeadVersion !== null && state.dataId === id) {
          version = state.preProcHeadVersion
          if (state.versionHistory[version] != null && !reload) {
            return // from client side history
          }
        }
        const req = {
          action: 'getSelectedLearningDataForAnalysis',
          offset: 0,
          limit: rootGetters['settings/rowsPerPage'],
          type,
          dataId: id,
          version,
          withGraph,
          withOutlier,
          withDescribe
        }
        const res = await this._vm.$sendMessageAndReceive(req)
        const table = res.list
        commit('SET_DATA', {
          dataId: id,
          dataType: type,
          graph: res.graph,
          describe: res.describe ?? [],
          table: table,
          columnNulls: res.nulls,
          appliedChart: res.version_info,
          dtypes: res.dtypes,
          outlierNums: res.outlier_nums
        })

        const resVersion = res.version
        commit('SET_PREPROC_HEAD_VERSION', resVersion)
        if (version == null) {
          commit('SET_PREPROC_LATEST_VERSION', resVersion)
        }
        if (reload) {
          commit('DELETE_CACHE')
        }
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchEDARecipeList({ commit }) {
      try {
        commit('SET_EDA_RECIPES_LOADING', true)
        const req = {
          action: 'listEDARecipes'
        }
        const res = await this._vm.$sendMessageAndReceive(req)
        if (res.status === 'error') {
          throw res
        }
        const edaRecipes = {}
        for (const v of res.result) {
          v.link = {
            name: 'preprocessingDetail',
            params: v.id
          }
          v.fullId = v.id + '-' + v.accountId
          edaRecipes[v.id] = v
        }
        commit('SET_EDA_RECIPES', {
          edaRecipes
        })
      } finally {
        commit('SET_EDA_RECIPES_LOADING', false)
      }
    },
    async deleteEDARecipe(
      { commit, dispatch },
      { EDARecipeAccountId, EDARecipeId }
    ) {
      try {
        commit('SET_EDA_RECIPES_DELETE_LOADING', true)
        const req = {
          action: 'deleteEDARecipe',
          EDARecipeAccountId,
          EDARecipeId
        }
        const res = await this._vm.$sendMessageAndReceive(req)
        if (res.status === 'error') {
          throw res
        }
        dispatch('fetchEDARecipeList')
      } finally {
        commit('SET_EDA_RECIPES_DELETE_LOADING', false)
      }
    },
    async deleteEDARecipeMulti({ commit, dispatch }, preprocessingList) {
      try {
        commit('SET_EDA_RECIPES_DELETE_LOADING', true)
        const req = {
          action: 'deleteEDARecipeMulti',
          preprocessingList
        }
        const res = await this._vm.$sendMessageAndReceive(req)
        if (res.status === 'error') {
          throw res
        }
        dispatch('fetchEDARecipeList')
      } finally {
        commit('SET_EDA_RECIPES_DELETE_LOADING', false)
      }
    },
    async loadEDARecipeDetail({ commit }, { EDARecipeAccountId, EDARecipeId }) {
      try {
        commit('SET_EDA_RECIPES_LOADING', true)
        const req = {
          action: 'detailEDARecipe',
          EDARecipeAccountId,
          EDARecipeId
        }
        const res = await this._vm.$sendMessageAndReceive(req)
        if (res.status === 'error') {
          throw res
        }
        commit('UPDATE_EDA_RECIPE', {
          EDARecipeId,
          value: {
            detail: res.result,
            detailLoaded: true
          }
        })
      } finally {
        commit('SET_EDA_RECIPES_LOADING', false)
      }
    },
    async updatePreprocessing(
      { dispatch },
      { id, accountId, name, description }
    ) {
      const req = {
        action: 'updatePreprocessing',
        id: id,
        accountId: accountId,
        name: name,
        description: description
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      if (res.status === 'error') {
        throw res
      }
      await dispatch('fetchEDARecipeList')
      await dispatch('loadEDARecipeDetail', {
        EDARecipeId: id,
        EDARecipeAccountId: accountId
      })
    },
    applyAutoPreprocess: async function (
      { commit },
      { dataId, version, predictColumns }
    ) {
      const req = {
        action: 'applyAutoPreprocess',
        dataId,
        version,
        predictColumns
      }

      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        if (res.status === 'error') throw res

        const resVersion = res.result.version

        commit('SET_PREPROC_HEAD_VERSION', resVersion)
        commit('SET_AUTO_PREPROCESSING_APPLIED', true)
      } catch (error) {
        this._vm.log_info(error)
        throw error
      }
    },
    async applyEDARecipe(
      { commit, dispatch, state },
      { EDARecipeAccountId, EDARecipeId }
    ) {
      const req = {
        action: 'applyEDARecipe',
        dataId: state.dataId,
        version: state.preProcHeadVersion,
        EDARecipeAccountId,
        EDARecipeId
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      if (res.status === 'error') {
        throw res
      }
      const resVersion = res.version
      commit('SET_PREPROC_HEAD_VERSION', resVersion)
    },
    saveEDARecipe: async function (
      { state, dispatch, commit },
      { dataId, version, name, description, projectId }
    ) {
      const req = {
        action: 'saveEDARecipe',
        dataId: dataId,
        version: version,
        name: name,
        description: description,
        projectId
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      if (res.status === 'error') {
        throw res
      }
      dispatch('fetchEDARecipeList')
    },
    savePreProcessedData: async function (
      { state, dispatch, commit },
      { dataId, version, name, description, projectId }
    ) {
      const req = {
        action: 'savePreProcessedData',
        dataId,
        version,
        name,
        description,
        projectId
      }

      try {
        commit('SET_SAVE_STATE', true)
        const res = await this._vm.$sendMessageAndReceive(req)
        if (res.status === 'error') {
          throw res
        }
        commit('SET_PREPROC_HEAD_VERSION', null)
        return res
      } catch (ex) {
        // TODO: エラー用のポップアップが必要
        this._vm.log_info(ex)

        if (ex && ex.code !== 999999) {
          return ex
        } else {
          this._vm.$notify({
            group: 'alerts',
            type: 'danger',
            title: i18n.t('preprocessing.error.UNKNOWN_ERROR'),
            duration: 5000,
            data: {
              dismissible: true
            }
          })
          return false
        }
      } finally {
        commit('SET_SAVE_STATE', false)
      }
    },
    pageBackMainState({ commit, state }) {
      commit('RESET_BOX_PLOT_DATA')
    }
  }
}

export default preprocessings
