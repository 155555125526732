import infer from './infer'
import learn from './learn'
import upload from './upload'
import optimization from './optimization'

export default {
  namespaced: true,
  modules: {
    infer,
    learn,
    upload,
    optimization
  },
  actions: {
    addService({ dispatch }, { serviceType, ...payload }) {
      dispatch(`${serviceType}/addService`, { ...payload })
    },
    loadServiceList({ dispatch }, { serviceType, ...payload }) {
      dispatch(`${serviceType}/loadServiceList`, { ...payload })
    },
    deleteServices({ dispatch }, { serviceType, projectId, payload }) {
      // payload : Array
      dispatch(`${serviceType}/deleteServices`, {
        projectId,
        services: payload
      })
    },
    updateService({ dispatch }, { serviceType, projectId, ...payload }) {
      dispatch(`${serviceType}/updateService`, { projectId, ...payload })
    }
  }
}
