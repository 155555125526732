<template>
  <div>
    <template v-if="platformIsNotSupported">
      <not-support
        :platformIsMobile="platformIsMobile"
        :platformName="platformName"
      />
    </template>
    <template v-else>
      <router-view />
      <notification-item />
      <connection-error-popup :showPopup="showPopup" />
    </template>
  </div>
</template>

<script>
import platform from 'platform'
import { mapActions, mapGetters } from 'vuex'
import notificationItem from '@/components/organisms/notification-item.vue'
import notSupport from '@/components/organisms/not-support.vue'
import connectionErrorPopup from '@/components/organisms/connection-error-popup.vue'

export default {
  name: 'App',
  data() {
    return {
      showPopup: false
    }
  },
  mounted() {
    document.addEventListener('keydown', this.key)
  },
  unmounted() {
    document.removeEventListener('keydown', this.key)
  },
  methods: {
    ...mapActions('settings', ['toggleLanguage']),
    async key(e) {
      /*
      if (
        e.code === 'KeyL' &&
        e.ctrlKey &&
        process.env.NODE_ENV === 'development'
      ) {
        this.toggleLanguage({ i18n: this.$i18n })
        e.preventDefault()
      }
      if (
        e.code === 'KeyI' &&
        e.ctrlKey &&
        process.env.NODE_ENV === 'development'
      ) {
        console.log(
          await this.$sendMessageAndReceive({
            action: 'debug_getPerfLog'
          })
        )
        e.preventDefault()
      }
      */
    }
  },
  computed: {
    ...mapGetters('settings', ['webSocketConnection']),
    platformName() {
      return platform.name
    },
    platformIsNotSupported() {
      return (
        platform.name === 'Safari' ||
        platform.name === 'IE' ||
        this.platformIsMobile
      )
    },
    platformIsMobile() {
      return platform.product != null || platform.name.match(/mobile/i)
    }
  },
  components: {
    notificationItem,
    notSupport,
    connectionErrorPopup
  },
  watch: {
    webSocketConnection(newVal) {
      if (!newVal) {
        this.showPopup = !newVal
      }
    }
  }
}
</script>
