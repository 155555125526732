var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.auto",value:(_vm.toolTipItem),expression:"toolTipItem",modifiers:{"auto":true}}],staticClass:"c-icon-wrap",class:{ 'c-icon-wrap-disabled': _vm.isDisabled }},[(_vm.isButton)?_c('button',{attrs:{"type":"button","disabled":_vm.isDisabled},on:{"click":function($event){return _vm.$emit('icon-click')}}},[_c(_vm.componentName,{tag:"svg",class:'c-icon-color-' + _vm.color,attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","aria-labelledby":_vm.iconName,"role":"presentation","width":_vm.iconName === 'backward' ||
          _vm.iconName === 'forward' ||
          _vm.iconName === 'newTab'
          ? _vm.styleVariablesArrow
          : _vm.styleVariables,"height":_vm.styleVariables}})],1):_c(_vm.componentName,{tag:"svg",class:'c-icon-color-' + _vm.color,attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","aria-labelledby":_vm.iconName,"role":"presentation","width":_vm.iconName === 'backward' ||
        _vm.iconName === 'forward' ||
        _vm.iconName === 'newTab'
        ? _vm.styleVariablesArrow
        : _vm.styleVariables,"height":_vm.styleVariables}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }