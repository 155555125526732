var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"c-button",class:[
    _vm.type,
    _vm.size,
    { 'c-button-icon': _vm.iconName },
    { 'c-button-ellipse': _vm.isEllipse }
  ],attrs:{"disabled":_vm.isDisabled,"type":_vm.buttonType},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.iconName)?_c('icon',{staticClass:"c-button-icon-item",attrs:{"iconName":_vm.iconName,"color":_vm.iconColor}}):_vm._e(),_c('div',{staticClass:"c-button-text",class:[
      'c-button-text-' + _vm.fontSize,
      { 'c-button-text-number': !isNaN(_vm.fontSize) }
    ],style:(_vm.styleSize)},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }