<template>
  <div class="platform-not-supported-wrap">
    <div class="platform-not-supported-card">
      <div class="platform-not-supported-logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1140 233">
          <path
            d="M114.6 104.3c-22.9-5.4-49.3-.6-67.7 8.1S-8 149.1 1.6 185C8.4 210.8 42.3 216 79.9 195c11.2-6.2 46.2-88.1 34.7-90.8zM142.8 104.3c22.9-5.4 49.3-.6 67.8 8.1s54.8 36.7 45.3 72.5c-7 25.9-40.8 31.2-78.4 10.2-11.2-6.2-46.2-88.1-34.7-90.8z"
            fill="#d584dd"
            fill-rule="evenodd"
          />
          <path
            d="M167.4 109.6c16.6 9 24.3 23.2 31.5 38.6 6.3 13.7 20 18.1 26.4 15.3 7.1-3.1 9.6-14.3 2-25.3-10.6-15.8-35.5-28.6-59.9-28.6zM91.6 109.6c-16.6 9-24.4 23.2-31.5 38.6-6.4 13.7-20 18.1-26.5 15.3-7-3.1-9.5-14.3-2-25.3 10.7-15.8 35.6-28.6 60-28.6z"
            fill="#fdfdfd"
            fill-rule="evenodd"
          />
          <path
            d="M129.5 98.2c-17.5 0-61.2 28.3-61.2 71.2 0 27.2 20.5 63 61.2 63 42 0 61.2-35.8 61.2-63 0-42.9-43.7-71.2-61.2-71.2z"
            fill="#850491"
            fill-rule="evenodd"
          />
          <path
            d="M129.5 105.2c-2.8 4.8-21.2 21.8-21.2 37.5 0 15.3 7 30.2 21.2 30.2s21.2-14.9 21.2-30.2c0-15.7-18.4-32.7-21.2-37.5z"
            fill="#fff"
            fill-rule="evenodd"
          />
          <path
            d="M93 31.5c5.4 8.3 11 34.4 15.3 52-15.6-11.6-36.6-26.6-42-34.8s-4-18.9 3.3-23.7 17.8-1.8 23.3 6.5zm72.4 0c-5.4 8.3-11 34.4-15.3 52 15.5-11.6 36.6-26.6 42-34.8s4-19-3.4-23.7-17.8-1.8-23.3 6.5z"
            fill="#d584dd"
            fill-rule="evenodd"
          />
          <path
            d="M153.3 24.3c0 13-14.8 45.6-23.8 67.8-9.9-23.6-23.8-54.8-23.8-67.8a23.8 23.8 0 0147.6 0z"
            fill="#850491"
            fill-rule="evenodd"
          />
          <path
            d="M505.3 119c0-8.5-4.2-12.7-15.1-12.7a66.7 66.7 0 00-26.3 6.2c-.3.2-.5.2-.6-.1l-6.2-18c-.2-.3 0-.4.3-.6a81 81 0 0137.5-8c21.6 0 35 11.8 35 31.6v28c0 6 .8 8 5.8 8h2.3a.4.4 0 01.5.4v17.8a.4.4 0 01-.5.5c-19.5.8-23.7-2.7-26.9-9-6.5 7.5-14.4 10.5-28.8 10.5-16.8 0-31-9.9-31-26.1 0-16 13.1-26.3 31.5-26.8l22.5-.7zm-15 17.6c-9.6 0-16 3.7-16 9.4s5.7 8.5 13.6 8.5c12.7 0 17.4-5.4 17.4-14.2v-3.7zM579.7 64.8v21.1h15.6v16.9h-15.6v43q0 4.7 1.9 6.8t7 2a35 35 0 006.7-.8v17.7a47.3 47.3 0 01-13.9 2.1q-24.2 0-24.7-24.4v-46.4h-12.5V85.9h12.5V64.8zM699 173.5h-23V85.8h23zm2.2-111.7A13.8 13.8 0 11687.5 48a13.8 13.8 0 0113.7 13.8zM755.3 111.6L771 85.8h26.4L771 128l28.3 45.6h-26.5L755.4 145l-17.3 28.7h-26.6L740 128l-26.2-42.2H740zM880.7 124.6h-45.9v49H811V56.1h75.4v19.3h-51.6v30h45.9zM924.3 173.6h-23.1V56h23zM1108.4 140.6l10.6-54.8h21l-21.1 87.8h-17.3l-15.9-55.9-15.9 55.9h-17.2l-21.1-87.8h21l10.5 54.7 14.9-54.7h15.7zM1026.5 129.7a43.7 43.7 0 11-43.7-43.9 43.8 43.8 0 0143.7 44zm-43.7-20.6a20.6 20.6 0 1020.6 20.5 20.6 20.6 0 00-20.6-20.5zM315 56h22l18.4 26.2c7 9.6 21.3 30.4 21.3 30.4l21.2-30.3L416.4 56h22v117.6h-25.5V99.2s-4.5 7.4-10.7 16.3L376.7 152l-25.8-36.7a284 284 0 01-10.5-16.1v74.4H315zM661 109a55.1 55.1 0 00-8.5-.6q-13 0-17.1 8.8v56.4h-23V85.9h23v11.9c4.6-8 10.4-12 18.5-12a24.1 24.1 0 017.2 1.1z"
            fill="#850491"
          />
        </svg>
      </div>
      <template v-if="platformIsMobile">
        <div
          class="platform-not-supported-title platform-not-supported-title--sp"
        >
          {{ $t('app.deviceNotSupported') }}
        </div>
      </template>
      <template v-else>
        <div class="platform-not-supported-title">
          {{ $t('app.platformNotSupported', { platform_name: platformName }) }}
        </div>
        <div class="platform-not-supported-desc">
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener"
          >{{ $t('app.getGoogleChrome') }}</a>
          <a
            href="https://www.mozilla.org/firefox/"
            target="_blank"
            rel="noopener"
          >{{ $t('app.getFirefox') }}</a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    platformIsMobile: {
      type: Boolean,
      default: false
    },
    platformName: String
  }
}
</script>

<style scoped lang="scss">
.platform-not-supported {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: $background-sub;
  }
  &-logo {
    display: block;
    width: 240px;
    height: 50px;
    margin-bottom: 22px;
  }
  &-card {
    max-width: 87.5%;
    padding: 32px;
    background-color: $white;
    border-radius: 12px;
    box-shadow: $box-shadow-main;
  }
  &-title {
    margin-bottom: 24px;
    line-height: $line-height-base;
    font-size: 2.4rem;
    white-space: pre-wrap;
  }
  &-desc {
    font-size: 2rem;
    > a {
      margin-right: 32px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 1028px) {
  .platform-not-supported {
    &-logo {
      width: 34%;
      height: auto;
      margin-bottom: 18px;
    }
    &-card {
      max-width: 87.5%;
      padding: 24px;
      border-radius: 8px;
    }
    &-title {
      font-size: 3.2rem;
      &--sp {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 764px) {
  .platform-not-supported {
    &-logo {
      width: 50%;
      margin-bottom: 14px;
    }
    &-card {
      padding: 18px 16px 12px;
      border-radius: 8px;
    }
    &-title {
      font-size: 2.8rem;
    }
  }
}
</style>
