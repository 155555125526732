export default {
  install(Vue, socket, options) {
    Vue.prototype.date2Str = function (date) {
      if (!date) return date
      let localDate = null
      if (typeof date === 'string') {
        const d = date.replace(' ', 'T')
        localDate = new Date(
          new Date(d).setHours(
            new Date(d).getHours() +
              Number(this.$store.getters['setting/timezoneDiff'])
          )
        )
      } else {
        localDate = date
      }
      return (
        [localDate.getFullYear(), localDate.getMonth() + 1, localDate.getDate()].join('-') +
        ' ' +
        localDate.toLocaleTimeString()
      )
    }
    Vue.prototype.limitString = function (str, limit) {
      if (!str) {
        return ''
      }
      return str.slice(0, limit) + (str.length > limit ? '...' : '')
    }
    Vue.prototype.themeColor = '#850491'
    Vue.prototype.formatFileSize = function (value, fixedDigits = 0) {
      if (value < 1024) {
        return `${value}  B`
      } else if (value < 1024 ** 2) {
        return `${(value / 1024).toFixed(fixedDigits)} KB`
      } else if (value < 1024 ** 3) {
        return `${(value / 1024 / 1024).toFixed(fixedDigits)} MB`
      } else {
        return `${(value / 1024 / 1024 / 1024).toFixed(fixedDigits)} GB`
      }
    }
  }
}
