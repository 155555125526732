import Vue from 'vue'
import Vuex from 'vuex'

import project from './project'
import projectTemplate from './projectTemplate'
import datasets from './datasets'
import datasource from './datasource'
import settings from './settings'
import auth from './auth'
import preprocessings from './preprocessings'
import models from './models'
import services from './services/index.js'
import helps from './helps'
import trainedAi from './trainedAI'
import trainings from './trainings'
import recipes from './recipes'
import inference from './inference'
import tasks from './tasks'
import customblock from './customblock'
import trainingDataset from './trainingDataset'
Vue.use(Vuex)

const rpcTimeout = 10
export default new Vuex.Store({
  namespaced: true,
  modules: {
    project,
    projectTemplate,
    datasets,
    auth,
    settings,
    datasource,
    preprocessings,
    models,
    services,
    helps,
    trainedAi,
    recipes,
    inference,
    trainings,
    tasks,
    customblock,
    trainingDataset
  },
  getters: {
    getTimezoneDiff() {
      const hours = (new Date().getTimezoneOffset() / 60) * -1
      let diff
      if (hours >= 0) {
        diff = '+' + hours
      } else {
        diff = '-' + hours
      }
      return diff
    }
  },
  state: {},
  mutations: {},
  actions: {
    async remoteCall({ dispatch }, request) {
      try {
        const response = await Promise.race([
          this._vm.$sendMessageAndReceive(request),
          new Promise((resolve) => setTimeout(resolve, rpcTimeout * 1000)).then(
            () => {
              return {
                status: 'error',
                message: 'RPC_TIMEOUT'
              }
            }
          )
        ])
        if (response.status === 'error') {
          dispatch('notifyError', response)
        }
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async initialize({ getters, dispatch, commit }) {
      await dispatch('auth/fetchLoginInfo')
      dispatch('helps/getManualList')
      // reload時のloading対策
      commit('datasets/SET_LOADING_DATASET_LIST', true)
      commit('preprocessings/SET_EDA_RECIPES_LOADING', true)
      commit('project/SET_LOADING_PROJECT_LIST', true)
      commit('trainedAi/SET_LOADING_TRAINED_AIS', true)

      if (getters['auth/loggedIn']) {
        try {
          await dispatch('auth/fetchAccountInfo')
          await dispatch('project/loadProjectList')
        } finally {
          commit('datasets/SET_LOADING_DATASET_LIST', false)
          commit('preprocessings/SET_EDA_RECIPES_LOADING', false)
          commit('project/SET_LOADING_PROJECT_LIST', false)
          commit('trainedAi/SET_LOADING_TRAINED_AIS', false)
          const accountInfo = getters['auth/accountInfo']
          // eslint-disable-next-line no-undef
          dataLayer.push({
            event: 'get_id',
            eventLabel: accountInfo.email,
            eventCheckId: accountInfo.accountId
          })
          // eslint-disable-next-line no-undef
          dataLayer.push({
            event: 'appEvent',
            eventCategory: 'userPlan',
            eventAction: 'getPlan',
            eventLabel: accountInfo.plan,
            eventCheckId: accountInfo.accountId
          })
        }
      }
    }
  }
})
