// <ハイフンなしのID>-<accountId>の場合のsplit
export function splitFullId(fullId) {
  const splitted = fullId.split('-')
  const idPart = splitted.shift()
  return [idPart, splitted.join('-')]
}
// <accountId>-<ハイフンなしのID>の場合のsplit
export function splitRevFullId(fullId) {
  const splitted = fullId.split('-')
  const idPart = splitted.pop()
  return [splitted.join('-'), idPart]
}

export function formatTime(secTime) {
  if (secTime < 60) {
    return secTime + this.$t('common.time.sec')
  } else if (secTime >= 60 && secTime < 60 * 60) {
    return (
      Math.floor(secTime / 60) +
      this.$t('common.time.min') +
      (secTime % 60) +
      this.$t('common.time.sec')
    )
  } else if (secTime >= 60 * 60) {
    const h = Math.floor(secTime / (60 * 60))
    const restSec = secTime % (60 * 60)
    const m = Math.floor(restSec / 60)
    const s = restSec & 60
    return (
      h +
      this.$t('common.time.hour') +
      m +
      this.$t('common.time.min') +
      s +
      this.$t('common.time.sec')
    )
  } else {
    return '-'
  }
}

// 精度評価などの％表示
export function toPercentage(s) {
  return (s * 100).toFixed(1) + '%'
}

// 精度評価などで小数を丸める
export function toRounded(s) {
  return s.toFixed(3)
}

// エラーがi18nに存在するかチェック
// .bind(this)でvueインスタンスをバインドして使って下さい
export function checkErrori18n(reason) {
  if (this.$te('common.disabled.' + reason)) {
    return this.$t('common.disabled.' + reason)
  } else {
    throw new Error('エラーメッセージが未設定です')
  }
}

// エデュケーションプランが時間制限を過ぎているかチェック
export function checkExpiredEducation(accountInfo) {
  return (
    accountInfo.plan === 'education_1' &&
    accountInfo.usedLearningTimeSec > 2 * 60 * 60
  )
}

/**
 *  Arrayの中にObjectが入っていて、そのObjectの特定のKeyに重複がないかチェックする関数
 *  @params { array } items 重複チェックするArray
 *  @params { string } key チェックする対象のObjectのkey
 */
export function duplicateObjectValueCheck(items, key) {
  const target = items.map((item) => {
    return item[key]
  })
  return new Set(target).size !== items.length
}
