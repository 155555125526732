// 学習用のデータセットに関する情報の格納先
import { loading } from './loading'

const trainingDataset = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    datasetId: '',
    datasetAccountId: '',
    datasetDetail: {},
    datasetSample: {},
    datasetInPreprocess: false,
    datasetColumns: [],
    loadingDataset: false,
    loadingDatasetDetail: false,
    fetchDetailError: null,
    tooLargeData: false
  },
  getters: {
    trainingDataset: (state) => state.datasetDetail?.detail,
    trainingDatasetDetail: (state) => state.datasetDetail,
    trainingDatasetSample: (state) => state.datasetSample,
    trainingDatasetId: (state) => state.datasetId,
    trainingDatasetAccountId: (state) => state.datasetAccountId,
    trainingDatasetInPreprocess: (state) => state.datasetInPreprocess,
    trainingDatasetColumns: (state) => state.datasetColumns,
    trainingDatasetTooLarge: (state) => state.tooLargeData,
    loadingDataset: (state) => state.loadingDataset,
    loadingDatasetDetail: (state) => state.loadingDatasetDetail,
    fetchDetailError: (state) => state.fetchDetailError
  },
  mutations: {
    SET_TRAINING_DATASET_DETAIL(state, value) {
      state.datasetDetail = value
    },
    SET_TRAINING_DATASET_SAMPLE(state, value) {
      state.datasetSample = value
    },
    SET_TRAINING_DATASET_LOADING(state, bool) {
      state.loadingDataset = bool
    },
    SET_TRAINING_DATASET_DETAIL_LOADING(state, bool) {
      state.loadingDatasetDetail = bool
    },
    SET_TRAINING_DATASET_ID(state, id) {
      state.datasetId = id
    },
    SET_TRAINING_DATASET_ACCOUNT_ID(state, accountId) {
      state.datasetAccountId = accountId
    },
    SET_TRAINING_DATASET_IN_PREPROCESS(state, bool) {
      state.datasetInPreprocess = bool
    },
    SET_TRAINING_DATASET_COLUMNS(state, columns) {
      state.datasetColumns = columns
    },
    SET_TRAINING_DATASET_TOO_LARGE(state, boolean) {
      state.tooLargeData = boolean
    },
    ERROR_FETCH_DETAIL(state, error) {
      state.fetchDetailError = error.name
    },
    RESET_TRAINING_DATASET(state) {
      state.datasetId = ''
      state.datasetAccountId = ''
      state.datasetDetail = {}
      state.datasetSample = {}
      state.datasetInPreprocess = false
      state.datasetColumns = []
      state.loadingDataset = false
      state.loadingDatasetDetail = false
      state.fetchDetailError = null
      state.tooLargeData = false
    },
    RESET_ERROR(state) {
      state.fetchDetailError = null
    }
  },
  actions: {
    async fetchDatasetDetail({ state, commit, dispatch }, payload) {
      commit('RESET_ERROR')
      const req = {
        action: 'getData',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        if (res.status === 'error') {
          throw new Error(res)
        }
        commit('SET_TRAINING_DATASET_DETAIL', res)
        dispatch('setDatasetIdAccountId', {
          id: res.detail.id,
          accountId: res.detail.accountId
        })
      } catch (res) {
        commit('ERROR_FETCH_DETAIL', res)
      } finally {
        dispatch('setLoadingDatasetDetail', false)
      }
    },
    async fetchDatasetDetailUnstructured({ state, commit, dispatch }, payload) {
      commit('RESET_ERROR')
      const reqDataSample = {
        action: 'getDataSample',
        ...payload
      }
      try {
        const resDataSample = await this._vm.$sendMessageAndReceive(
          reqDataSample
        )
        if (resDataSample.status === 'error') {
          throw new Error(resDataSample)
        }
        commit('SET_TRAINING_DATASET_SAMPLE', resDataSample)
      } catch (res) {
        console.log('sample')
        commit('ERROR_FETCH_DETAIL', res)
      } finally {
        dispatch('setLoadingDatasetDetail', false)
      }
    },
    async fetchDatasetInPreprocessing({ state, commit }, payload) {
      const req = {
        action: 'getDataPreprocessingStatus',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        const check = res.preprocessing.version > 0
        commit('SET_TRAINING_DATASET_IN_PREPROCESS', check)
      } catch {}
    },
    // データセットのIDとアカウントIDだけを登録する関数
    setDatasetIdAccountId({ commit }, { id, accountId }) {
      commit('SET_TRAINING_DATASET_ID', id)
      commit('SET_TRAINING_DATASET_ACCOUNT_ID', accountId)
    },
    setDatasetPreprocessing({ commit }, bool) {
      commit('SET_TRAINING_DATASET_IN_PREPROCESS', bool)
    },
    setLoadingDatasetDetail({ commit }, bool) {
      commit('SET_TRAINING_DATASET_DETAIL_LOADING', bool)
    },
    setDatasetColumns({ commit }, columns) {
      commit('SET_TRAINING_DATASET_COLUMNS', columns)
    },
    setDatasetTooLargeData({ commit }, boolean) {
      commit('SET_TRAINING_DATASET_TOO_LARGE', boolean)
    },
    resetDataset({ commit }) {
      commit('RESET_TRAINING_DATASET')
    }
  }
}

export default trainingDataset
