import { i18n } from '@/main'
import Vue from 'vue'
import { splitRevFullId } from '@/lib/misc.js'
import { setMetricsList } from '@/lib/trainedAI.js'
import { finishTrainingError } from '@/lib/training.js'
import * as metricsDefsByModelType from '@/components/organisms/trained-ai-detail/metricsDefs'
import { base64ToBytes, download } from '../lib/download'

function fixMetrix(testEvaluation) {
  const metricsTarget = testEvaluation ?? {}
  const assingMetrics = {}
  for (const [key, value] of Object.entries(metricsTarget)) {
    if (typeof value === 'number') {
      if (key === 'r2') {
        Object.assign(assingMetrics, { test_accuracy: value })
      } else {
        Object.assign(assingMetrics, { ['test_' + key]: value })
      }
    }
  }
  return assingMetrics
}

function fixMetrixList(lastAlgorithmType, metrics, learningPredictionColumns) {
  if (lastAlgorithmType === 'no_accuracy') return []
  const metricsDefs = metricsDefsByModelType[lastAlgorithmType](
    i18n.t.bind(i18n)
  )
  const trainedAi = {
    metrics: metrics,
    predictionColumns: learningPredictionColumns
  }
  const metricsList = setMetricsList(trainedAi, metricsDefs)
  return metricsList
}

const learning = {
  namespaced: true,
  state: {
    training: false,
    trainingProgressStatus: null,
    trainingNumIter: 0,
    trainingProgress: 0,
    results: [],
    modelInfo: {},
    charts: {},
    cnnMetrics: {},
    bestParams: {},
    bestAcc: 0,
    firstSettedBestParams: {},
    firstSettedBestAcc: 0,
    elapsedTime: 0,
    wordCloud: null,
    frequencies: null,
    confusionMatrixList: [],
    stoppingTraining: false,
    trials: [],
    firstSettedTrials: [],
    finishColumns: [],
    timeseries: {
      observations: [],
      acf: [],
      pacf: []
    },
    settedModelId: null,
    timeseriesWarning: false,

    featureImportances: {},
    metrics: {},
    metricsList: {},
    metricsSave: {},
    metricsListSave: {},
    learningPredictionColumns: [],
    featureImportancesScatter: {},

    jobInfo: null,
    jobToken: null,
    message: '',
    errorManual: null,
    errorDetail: null,
    numCreatingtrainedAIs: 0,
    isStopped: false,

    inputColumnsOptions: {},
    predictionColumnOptions: {},
    loadingColumns: false,

    runningJobs: [],
    cancelToken: null,

    optimizationConditions: [],
    optimizationResult: {
      result: {},
      columnRanges: [],
      total: 0,
      length: 0,
      optimizationConditionsId: null
    },
    optimizationNumIter: 0,
    optimizationProgress: 0
  },
  getters: {
    training: (state) => state.training,
    results: (state) => state.results,
    modelInfo: (state) => state.modelInfo,
    trainingProgressStatus: (state) => state.trainingProgressStatus,
    stoppingTraining: (state) => state.stoppingTraining,
    trainingNumIter: (state) => state.trainingNumIter,
    trainingProgress: (state) => state.trainingProgress,
    charts: (state) => state.charts,
    cnnMetrics: (state) => state.cnnMetrics,
    elapsedTime: (state) => state.elapsedTime,
    bestParams: (state) => state.bestParams,
    bestAcc: (state) => state.bestAcc,
    firstSettedBestParams: (state) => state.firstSettedBestParams,
    firstSettedBestAcc: (state) => state.firstSettedBestAcc,
    trials: (state) => state.trials,
    firstSettedTrials: (state) => state.firstSettedTrials,
    confusionMatrixList: (state) => state.confusionMatrixList,
    wordCloud: (state) => state.wordCloud,
    frequencies: (state) => state.frequencies,
    finishColumns: (state) => state.finishColumns,
    settedModelId: (state) => state.settedModelId,
    timeseriesWarning: (state) => state.timeseriesWarning,

    featureImportances: (state) => state.featureImportances,
    metrics: (state) => state.metrics,
    metricsList: (state) => state.metricsList,
    metricsSave: (state) => state.metricsSave,
    metricsListSave: (state) => state.metricsListSave,
    learningPredictionColumns: (state) => state.learningPredictionColumns,
    featureImportancesScatter: (state) => state.featureImportancesScatter,

    jobId: (state) => (state.jobInfo ? state.jobInfo.jobId : null),
    message: (state) => state.message,
    errorManual: (state) => state.errorManual,
    errorDetail: (state) => state.errorDetail,
    numCreatingtrainedAIs: (state) => state.numCreatingtrainedAIs,
    isStopped: (state) => state.isStopped,

    inputColumnsOptions: (state) => state.inputColumnsOptions,
    predictionColumnOptions: (state) => state.predictionColumnOptions,
    loadingColumns: (state) => state.loadingColumns,

    runningJobs: (state) => state.runningJobs,
    cancelToken: (state) => state.cancelToken,

    optimizationConditions: (state) => state.optimizationConditions,
    optimizationResult: (state) => state.optimizationResult,
    optimizationNumIter: (state) => state.optimizationNumIter,
    optimizationProgress: (state) => state.optimizationProgress
  },
  mutations: {
    UPDATE_STATE(state, { training = null, numIter, progress }) {
      if (training !== null) {
        state.training = training
      }
      state.trainingNumIter = numIter
      state.trainingProgress = progress
    },
    UPDATE_OPTIMIZATION_STATE(state, { training = null, numIter, progress }) {
      if (training !== null) {
        state.training = training
      }
      state.optimizationNumIter = numIter
      state.optimizationProgress = progress
    },
    ADD_TRIAL(state, value) {
      const trial = {}
      trial.params = value.trial
      trial.iter = value.iter + 1
      trial.value = value.trialAcc
      if (state.finishColumns.length === 0) {
        state.firstSettedTrials.unshift(trial)
      }
      state.trials.unshift(trial)
    },
    REPLACE_TRIAL(state, { value }) {
      state.firstSettedBestParams = value.trials.bestParams
      state.firstSettedBestAcc = value.trials.bestAcc
      state.firstSettedTrials = value.trials.trials
    },
    ADD_CHART_BEST_DATA(state, value) {
      if (state.charts.best == null) {
        Vue.set(state.charts, 'best', {})
      }
      if (state.charts.trial == null) {
        Vue.set(state.charts, 'trial', {})
      }
      if (state.charts.best.step == null) {
        Vue.set(state.charts.best, 'step', [])
      }
      if (state.charts.trial.step == null) {
        Vue.set(state.charts.trial, 'step', [])
      }
      if (state.charts.best.accuracy == null) {
        Vue.set(state.charts.best, 'accuracy', [])
      }
      if (state.charts.trial.accuracy == null) {
        Vue.set(state.charts.trial, 'accuracy', [])
      }
      if (state.charts.best.algorithm == null) {
        Vue.set(state.charts.best, 'algorithm', [])
      }
      if (state.charts.trial.algorithm == null) {
        Vue.set(state.charts.trial, 'algorithm', [])
      }
      state.charts.best.step.push(value.iter)
      state.charts.best.accuracy.push(value.bestAcc)
      state.charts.best.algorithm.push(value.bestParams?.classifier)

      state.charts.trial.step.push(value.iter)
      state.charts.trial.accuracy.push(value.trialAcc)
      state.charts.trial.algorithm.push(value.trials?.classifier)

      state.bestParams = value.bestParams
      state.bestAcc = value.bestAcc
      if (state.finishColumns.length === 0) {
        state.firstSettedBestParams = value.bestParams
        state.firstSettedBestAcc = value.bestAcc
      }
    },
    ADD_CHART_DATA(state, { label = 'best', value }) {
      if (state.charts[label] == null) {
        Vue.set(state.charts, label, {})
      }
      for (const k in value) {
        if (state.charts[label][k] == null) {
          Vue.set(state.charts[label], k, [])
        }
        state.charts[label][k].push(value[k])
      }
    },
    SET_CNN_METRICS(state, value) {
      state.cnnMetrics = value
    },
    SET_ELAPSED_TIME(state, value) {
      state.elapsedTime = Math.floor(value)
    },
    SET_RESULTS(state, value) {
      if (state.results.length === 0 && value) {
        state.results = value
      }
    },
    SET_MODEL_INFO(state, value) {
      if (Object.keys(state.modelInfo).length === 0) {
        state.modelInfo = value
      }
    },
    SET_CHARTS(state, value) {
      state.charts = value
    },
    SET_WORD_CLOUD(state, value) {
      state.wordCloud = value
    },
    SET_FREQUENCIES(state, value) {
      state.frequencies = value
    },
    SET_VALID_COLUMNS(state, value) {
      state.inputColumnsOptions = value.input_columns
      state.predictionColumnOptions = value.predict_columns
    },
    SET_JOB_INFO(state, value) {
      state.jobInfo = value
      state.settedModelId = value.jobName.split('-').pop()
    },
    RESET_JOB_INFO(state) {
      state.jobInfo = null
    },
    SET_PROGRESS_STATUS(state, value) {
      state.trainingProgressStatus = value
    },
    SET_CLASSIC_RESULT(state, value) {
      if (state.results.length === 0) {
        state.results.push(value.learning_results)
      }

      if (value?.prediction_column) {
        state.finishColumns.push(value.prediction_column)
      }

      let index = 0
      if (state.finishColumns.length > 0 && value.prediction_column) {
        index = state.finishColumns.indexOf(value.prediction_column)
      }
      value.learning_results.forEach((result) => {
        const algoIndex = result.algo_index
        state.featureImportances[algoIndex] =
          state.featureImportances[algoIndex] ?? {}
        state.metrics[algoIndex] = state.metrics[algoIndex] ?? {}
        state.metricsSave[algoIndex] = state.metricsSave[algoIndex] ?? {}

        const metricsTarget = result?.results?.test_evaluation ?? {}
        const assingMetrics = fixMetrix(metricsTarget)
        const featureImportance = result?.results?.mean_shap ?? {}

        if (index === 0) {
          state.featureImportances[algoIndex] = { ...featureImportance }
          Vue.set(state.metrics[algoIndex], index, assingMetrics)
          const metricsList = fixMetrixList(
            result.results.last_algorithm_type,
            state.metrics[algoIndex],
            state.learningPredictionColumns
          )
          Vue.set(state.metricsList, algoIndex, metricsList)
        }

        if (index < 7) {
          Vue.set(state.metricsSave[algoIndex], index, assingMetrics)
          const metricsListSave = fixMetrixList(
            result.results.last_algorithm_type,
            state.metricsSave[algoIndex],
            state.learningPredictionColumns
          )
          Vue.set(state.metricsListSave, algoIndex, metricsListSave)
        }
      })

      if (
        Object.keys(state.featureImportancesScatter).length === 0 &&
        value?.learning_results[0]?.results?.feature_importance
      ) {
        state.featureImportancesScatter =
          value.learning_results[0].results.feature_importance
      }
    },
    SET_TIMESERIES_OBS(state, value) {
      if (state.results.length === 0) {
        if (value.warn) {
          state.timeseries.observations = []
          state.timeseries.acf = []
          state.timeseries.pacf = []
        } else {
          state.timeseries.observations = value.observations
          state.timeseries.acf = value.acf
          state.timeseries.pacf = value.pacf
        }
      }
    },
    SET_TIMESERIES_RESULT_ALL(state, values) {
      state.results = []
      for (const value of values) {
        let result
        if (value.warn || state.timeseries.observations === []) {
          result = [{ warn: value.warn }]
        } else {
          result = [value]
        }
        state.results.push(result)
      }
    },
    SET_TIMESERIES_RESULT(state, value) {
      let result
      if (value.warn || state.timeseries.observations === []) {
        if (value?.prediction_column && !state.timeseriesWarning) {
          state.timeseriesWarning = true
        } else {
          result = [{ warn: value.warn }]
        }
      } else if (state.results.length === 0) {
        // 2個目以降の学習結果はセットしない
        result = value
        result.observations = state.timeseries.observations
        result.acf = state.timeseries.acf
        result.pacf = state.timeseries.pacf
      }
      // TrendFlowの場合予測する列が渡されるのでセット
      if (value?.prediction_column) {
        state.finishColumns.push(value.prediction_column)
      }
      // resultが存在していればセット
      if (result) {
        state.results.push(result)
      }
    },
    SET_TIMESERIES_DETAIL(state, value) {
      state.results = []
      let result
      if (value.warn) {
        result = { warn: value.warn }
      } else {
        result = value
      }
      state.results.push(result)
    },
    SET_OPTIMIZATION_RESULT(state, value) {
      state.optimizationConditions = value.columnConditions
      state.optimizationResult.columnRanges = value.columnRanges
      state.optimizationResult.total = value.total
      state.optimizationResult.length = value.length
      state.optimizationResult.optimizationConditionsId =
        value.optimizationConditionsId
    },
    SET_OPTIMIZATION_RESULT_DETAILE(state, value) {
      state.optimizationResult.result = value
    },
    ADD_CONFUSION_IMAGE(state, { confImg }) {
      state.confusionMatrixList.push(confImg)
    },
    RESET(state, notResetProgress = null) {
      state.training = false
      state.jobInfo = null
      state.message = ''
      state.errorManual = null
      state.settedModelId = null
      state.timeseriesWarning = false
      state.learningPredictionColumns = []
      if (!notResetProgress) {
        state.trainingProgressStatus = null
        state.isStopped = false
      }
    },
    RESET_PROGRESS(state) {
      state.trainingNumIter = 0
      state.trainingProgress = 0
      state.optimizationNumIter = 0
      state.optimizationProgress = 0
    },
    RESET_RESULT(state) {
      state.results = []
      state.firstSettedBestParams = {}
      state.firstSettedBestAcc = 0
      state.confusionMatrixList = []
      state.wordCloud = null
      state.frequencies = null
      state.modelInfo = {}
      state.firstSettedTrials = []
      state.finishColumns = []
      state.timeseries = {
        observations: [],
        acf: [],
        pacf: []
      }
      state.finishColumns = []
      state.featureImportances = {}
      state.metrics = {}
      state.metricsList = {}
      state.metricsSave = {}
      state.metricsListSave = {}
      state.featureImportancesScatter = {}
      state.optimizationConditions = []
      state.optimizationResult = {
        result: {},
        columnRanges: [],
        total: 0,
        length: 0,
        optimizationConditionsId: null
      }
    },
    RESET_CHRATS(state) {
      state.charts = {}
      state.cnnMetrics = {}
      state.bestParams = {}
      state.bestAcc = 0
      state.trials = []
    },
    SET_LOADING_COLUMNS(state, value) {
      state.loadingColumns = value
    },
    SET_ERROR_MESSAGE(state, setError) {
      if (!setError) {
        state.message = ''
        state.errorManual = null
        state.errorDetail = null
      } else {
        state.message = setError?.message ?? ''
        state.errorManual = setError?.manualType ?? null
        state.errorDetail = setError.detail ?? null
      }
    },
    SET_NUM_AIS(state, number) {
      state.numCreatingtrainedAIs = number
    },
    SET_STOPPED_STATUS(state, flag) {
      state.isStopped = flag
    },

    SET_RUNNING_JOBS(state, runningJobs) {
      state.runningJobs = runningJobs
    },
    SET_CANCEL_TOKEN(state, cancelToken) {
      state.cancelToken = cancelToken
    },
    SET_LEANING_PREDICTION_COLUMNS(state, predictionColumn) {
      state.learningPredictionColumns = predictionColumn
    },
    SET_FEATURE_IMPORTANCE_SCATTER(state, value) {
      state.featureImportancesScatter = value
    },
    SET_TRAINING_COLUMN_RESULT(
      state,
      { values, algoIndex, targetIndex, isImage }
    ) {
      // 更新対象の状態のみを更新する
      // results[0]なのは、resultsの持ち方がこうなっているため
      // 画像の場合はデータを持つ階層が異なるから、分岐で設定する位置を変える
      if (isImage) {
        // state.results[0] =...で更新すると変更が検知されずにcomputedの値が変更されないから、この初期化処理を利用する
        state.results = []
        state.results.push({
          algo_index: algoIndex,
          predict_column: null,
          results: values[0]
        })
      } else {
        if (state.results[0] == null) {
          Vue.set(state.results, 0, [])
        }
        Vue.set(state.results[0], algoIndex, {
          algo_index: algoIndex,
          predict_column: state.learningPredictionColumns?.[targetIndex],
          results: values[0]
        })
      }
    },
    SET_TRAINING_COLUMN_LEARNING_RESULT(
      state,
      { values, algoIndex, targetIndex }
    ) {
      const featureImportance = values[algoIndex]?.results?.mean_shap ?? {}
      state.featureImportances[algoIndex] = { ...featureImportance }

      const featureImportanceScatter =
        values[algoIndex]?.results?.feature_importance ?? {}
      state.featureImportancesScatter = featureImportanceScatter

      const metricsTarget = values[algoIndex]?.results?.test_evaluation ?? {}
      const assingMetrics = fixMetrix(metricsTarget)
      state.metrics[algoIndex] = {}
      Object.keys(state.metrics[algoIndex]).forEach((key) => {
        this.$delete(state.metrics[algoIndex], key)
      })
      Vue.set(state.metrics[algoIndex], targetIndex, assingMetrics)

      if (values[algoIndex].result?.last_algorithm_type) {
        const metricsList = fixMetrixList(
          values[algoIndex].results.last_algorithm_type,
          state.metrics[algoIndex],
          state.learningPredictionColumns
        )
        Vue.set(state.metricsList, algoIndex, metricsList)
      }
    },
    SET_SAVED_TRAINING_RESULT(state, { value, targetIndex, isImage }) {
      // result set
      // 画像の場合はデータを持つ階層が異なるから、分岐で設定する位置を変える
      if (isImage) {
        // state.results[0] =...で更新すると変更が検知されずにcomputedの値が変更されないから、この初期化処理を利用する
        state.results = []
        state.results.push({
          algo_index: 0,
          predict_column: null,
          results: value.result
        })
      } else {
        // 予測する列が複数の場合にしか呼ばれないため、レシピのアルゴリズムのブロックの個数は最大１になる。そのため0を入れる
        state.results = []
        state.results[0] = []

        let predictColumn = null
        if (state.learningPredictionColumns !== null) {
          predictColumn = state.learningPredictionColumns[targetIndex]
        }
        state.results[0].push({
          algo_index: 0,
          predict_column: predictColumn,
          results: value.result
        })

        // feature metrics set
        const featureImportance = value?.summary?.featureImportance ?? {}
        state.featureImportances[0] = { ...featureImportance }

        const assingMetrics = value?.summary?.metrics[targetIndex] ?? {}
        state.metrics[0] = {}
        Vue.set(state.metrics[0], targetIndex, assingMetrics)
      }

      if (value.result?.last_algorithm_type) {
        const metricsList = fixMetrixList(
          value.result.last_algorithm_type,
          state.metrics[0],
          state.learningPredictionColumns
        )
        Vue.set(state.metricsList, 0, metricsList)
      }
    },
    SET_SAVED_TRAINING_SCATTER_RESULT(state, { value }) {
      state.featureImportancesScatter.columns = value.input_columns
      state.featureImportancesScatter.shap_values = value.shap_values
    },
    SET_FINISH_COLUMNS(state) {
      // 全て終わってると仮定して、 `finishColumns` をセットする
      state.finishColumns = state.learningPredictionColumns
    },
    SET_JOB_TOKEN(state, value) {
      state.jobToken = value
    }
  },
  actions: {
    notifyError: function (context, response) {
      this._vm.log_info(response)
    },
    resetValidColumns({ commit }) {
      commit('SET_VALID_COLUMNS', { input_columns: {}, predict_columns: {} })
    },
    async fetchValidColumns({ state, commit }, payload) {
      commit('SET_LOADING_COLUMNS', true)
      try {
        const req = { ...payload, action: 'validColumns' }
        const res = await this._vm.$sendMessageAndReceive(req)
        commit('SET_VALID_COLUMNS', res)
      } finally {
        commit('SET_LOADING_COLUMNS', false)
      }
    },
    initCharts({ commit }) {
      commit('SET_CHARTS', {})
    },
    async updateProgress({ state, commit }, res) {
      // training
      if (
        state.trainingProgressStatus === 'training' ||
        state.trainingProgressStatus === 'preparingEnvironment'
      ) {
        if (res.iter === 0) {
          commit('RESET_CHRATS')
        }
        commit('SET_ELAPSED_TIME', Math.floor(res.elapsedTime))
        commit('UPDATE_STATE', { numIter: res.nIter + 1, progress: res.iter })
        commit('SET_PROGRESS_STATUS', 'training')
        if (typeof res.bestAcc !== 'undefined') {
          commit('ADD_CHART_BEST_DATA', res)
        }
        if (res.trials) {
          commit('ADD_TRIAL', {
            trial: res.trials,
            iter: res.iter,
            trialAcc: res.trialAcc
          })
        }
      }
    },
    async updateOptimizationProgress({ state, commit }, res) {
      if (
        state.trainingProgressStatus === 'training' ||
        state.trainingProgressStatus === 'preparingEnvironment'
      ) {
        if (res.nIter > 0) {
          commit('UPDATE_OPTIMIZATION_STATE', {
            numIter: res.nIter,
            progress: res.iter + 1
          })
        }
      }
    },
    async updateStatistics({ state, commit }, res) {
      // evaluate_train , evaluate_test
      if (state.trainingProgressStatus !== 'stoppingTraining') {
        const float = Number.parseFloat
        if (res.action === 'evaluate_train') {
          commit('ADD_CHART_DATA', {
            label: 'train',
            value: {
              step: float(res.iter),
              loss: float(res.loss),
              accuracy: float(res.accuracy)
            }
          })
        } else {
          commit('ADD_CHART_DATA', {
            label: 'test',
            value: {
              step: float(res.iter),
              loss: float(res.loss),
              accuracy: float(res.accuracy)
            }
          })
        }
      }
    },
    setCnnMetrics({ state, commit }, res) {
      commit('SET_CNN_METRICS', res)
    },
    async showWordCloud({ state, commit }, res) {
      commit('SET_WORD_CLOUD', res.body)
    },
    async showFrequencies({ state, commit }, res) {
      commit('SET_FREQUENCIES', res.frequencies)
    },
    async setClassicResult({ state, commit }, res) {
      const modelInfo = res.model_info
      commit('SET_MODEL_INFO', modelInfo || {})
      commit('SET_CLASSIC_RESULT', res)
      commit('SET_ELAPSED_TIME', res.elapsedTime)
    },
    async setTimeseriesObservations({ state, commit }, res) {
      commit('SET_TIMESERIES_OBS', res)
      commit('SET_ELAPSED_TIME', res.elapsedTime)
    },
    async setTimeseriesResult({ state, commit }, res) {
      commit('SET_TIMESERIES_RESULT', res)
      commit('SET_ELAPSED_TIME', res.elapsedTime)
    },
    async setTimeseriesResultList({ state, commit }, res) {
      commit('SET_TIMESERIES_RESULT_ALL', res.list)
      const modelInfo = res.info || {}
      commit('SET_MODEL_INFO', modelInfo)
    },
    async setOptimizationResult({ state, commit }, res) {
      commit('SET_OPTIMIZATION_RESULT', res)
    },
    async startLearning({ state, commit }, res) {
      commit('RESET_PROGRESS')
      commit('RESET_RESULT')
      commit('RESET_CHRATS')
      commit('SET_JOB_INFO', res)
    },
    async setNewlyPredictionColumns({ state, commit }, res) {
      commit('SET_LEANING_PREDICTION_COLUMNS', res.newlyPredictionColumns)
    },
    async finishTraining({ state, commit, dispatch }, res) {
      let status = null
      let notificationMessage = null
      let manualType = null
      let errorDetail = null
      if (res.status === 'error') {
        if (state.trainingProgressStatus !== 'stoppingTraining') {
          if (res.message === 'exceededEducationPlanTime') {
            status = res.message
          } else {
            const jobs = res.runningJobs
            let checkDifferentOptimization = false
            if (jobs && jobs.length > 0) {
              checkDifferentOptimization = jobs.some((job) => {
                return job?.isDifferentOptimization
              })
            }
            const checkError = finishTrainingError(
              res,
              i18n,
              checkDifferentOptimization,
              'training'
            )
            status = checkError.status
            notificationMessage = checkError.notificationMessage
            manualType = checkError.manualType
            errorDetail = checkError.detail

            if (res.code === 27005) {
              commit('SET_RUNNING_JOBS', jobs)
            }
          }
          if (notificationMessage === null) {
            notificationMessage =
              i18n.t('training.progressStatus.type.training') +
              i18n.t('training.progressStatus.' + status, null, errorDetail)
          }
          const setError = {
            message: notificationMessage,
            manualType: manualType,
            detail: errorDetail
          }
          commit('SET_ERROR_MESSAGE', setError)
          commit('RESET_JOB_INFO')
        } else {
          notificationMessage = i18n.t('training.message.trainingStop', {
            type: i18n.t('training.message.type.training')
          })
        }
      } else {
        status = 'finishTraining'
        notificationMessage = i18n.t('training.message.trainingSuccess', {
          type: i18n.t('training.message.type.training')
        })
      }
      commit('RESET_JOB_INFO')
      commit('UPDATE_STATE', { training: false, numIter: 0, progress: 0 })
      commit('SET_PROGRESS_STATUS', status)
      // 学習終了時の通知機能
      // eslint-disable-next-line no-unused-vars
      const notification = new Notification(
        i18n.t('training.message.finishTraining', {
          type: i18n.t('training.message.type.training')
        }),
        {
          body: notificationMessage,
          icon: '',
          tag: '',
          data: {}
        }
      )
    },
    async resetResults({ state, commit, dispatch }) {
      state.elapsedTime = 0
      commit('RESET')
      commit('RESET_PROGRESS')
      commit('RESET_RESULT')
      commit('RESET_CHRATS')
    },
    async stopBackgroundTrainingAsync({ state, commit, dispatch }) {
      let jobId, modelId, accountId
      if (state.runningJobs.length > 0) {
        [accountId, modelId] = splitRevFullId(state.runningJobs[0].jobName)

        jobId = state.runningJobs[0].jobId
      } else {
        return
      }
      const res = await this._vm.$sendMessageAndReceive({
        action: 'stopLearning',
        jobId
      })
      commit('SET_PROGRESS_STATUS', 'stoppingTraining')
      commit('RESET_JOB_INFO')
      commit('SET_STOPPED_STATUS', true)
      commit('SET_ERROR_MESSAGE', null)
      if (res.job_status !== 'SUCCEEDED') {
        dispatch('models/deleteModel', { modelId, accountId }, { root: true })
      }
    },
    async stopTrainingAsync({ state, commit, dispatch }) {
      if (!state.training) return
      const jobInfo = state.jobInfo
      if (jobInfo == null) return
      const jobId = jobInfo.jobId
      commit('SET_PROGRESS_STATUS', 'stoppingTraining')
      commit('RESET_JOB_INFO')
      commit('SET_STOPPED_STATUS', true)
      const res = await this._vm.$sendMessageAndReceive({
        action: 'stopLearning',
        jobId
      })

      commit('RESET', true)
      commit('RESET_PROGRESS')
      commit('RESET_RESULT')
      commit('RESET_CHRATS')
      await dispatch('pollingAsync', { jobId })
    },
    async reenterTrainingAsync(
      { state, commit, dispatch },
      { jobId, predictionColumn, isOptimization }
    ) {
      commit('UPDATE_STATE', { training: true, numIter: 0, progress: 0 })
      commit('SET_PROGRESS_STATUS', 'preparingEnvironment')
      commit('SET_STOPPED_STATUS', false)
      commit('SET_ERROR_MESSAGE', null)

      if (!isOptimization) {
        commit('SET_LEANING_PREDICTION_COLUMNS', predictionColumn)
      }
    },
    async pollingAsync({ state, commit, dispatch, getters }, payload) {
      const req = {
        action: 'pollLearning',
        ...payload,
        token: state.jobToken
      }
      const jobId = payload.jobId
      const res = await this._vm.$sendMessageAndReceive(req)
      if (getters.jobId != null && getters.jobId !== jobId) {
        console.log('DEBUG: polling cancelled.')
        return
      }
      if (res.status === 'error') {
        await dispatch('finishTraining', res)
        return
      }
      const handlers = {
        startLearning: async (msg) => await dispatch('startLearning', msg),
        newlyPredictionColumns: async (msg) =>
          dispatch('setNewlyPredictionColumns', msg),
        optimizationStart: async (msg) => await dispatch('startLearning', msg),
        learning: async (msg) => await dispatch('updateProgress', msg),
        evaluate_train: async (msg) => await dispatch('updateStatistics', msg),
        evaluate_test: async (msg) => await dispatch('updateStatistics', msg),
        evaluate_last_test: async (msg) => await dispatch('setCnnMetrics', msg),
        getWordCloud: async (msg) => await dispatch('showWordCloud', msg),
        getFrequencies: async (msg) => await dispatch('showFrequencies', msg),
        timeseriesObservations: async (msg) => {
          dispatch('setTimeseriesObservations', msg)
          commit('SET_PROGRESS_STATUS', 'training')
        },
        classicResult: async (msg) => await dispatch('setClassicResult', msg),
        timeseriesResult: async (msg) => {
          await dispatch('setTimeseriesResult', msg)
          commit('SET_PROGRESS_STATUS', 'training')
        },
        getConfusionMatrix: async (msg) => {
          if (msg.modelId === 'newModel')
            commit('ADD_CONFUSION_IMAGE', { confImg: msg.body })
        },
        optimizationIteration: async (msg) =>
          await dispatch('updateOptimizationProgress', msg),
        optimizationResult: async (msg) =>
          await dispatch('setOptimizationResult', msg),
        finishLearning: async (msg) => {
          if (msg.learning_results) {
            commit('SET_RESULTS', msg.learning_results)
          }
          if (msg.model_info) {
            commit('SET_MODEL_INFO', msg.model_info || {})
          }
          await dispatch('finishTraining', msg)
        }
      }

      for (const msg of res.messages) {
        await handlers[msg.action](msg)
      }

      commit('SET_JOB_TOKEN', res.nextToken)
    },
    async trainingAsync({ state, commit, dispatch }, payload) {
      commit('UPDATE_STATE', { training: true, numIter: 0, progress: 0 })
      commit('SET_PROGRESS_STATUS', 'preparingEnvironment')
      commit('SET_STOPPED_STATUS', false)
      commit('SET_ERROR_MESSAGE', null)
      // start
      const req = {
        action: 'startLearning',
        ...payload
      }
      if (
        payload?.optimizationConditions &&
        Object.keys(payload.optimizationConditions).length > 0
      ) {
        req.action = 'optimizationStart'
      } else {
        commit(
          'SET_LEANING_PREDICTION_COLUMNS',
          payload.trainConfig.predictionColumn
        )
      }

      const res = await this._vm.$sendMessageAndReceive(req)
      if (res.status === 'error') {
        if (
          res.message === 'gpuNotAvailable' ||
          res.message === 'InvalidTypeForGPU'
        ) {
          const notificationMessage =
            i18n.t('training.progressStatus.type.training') +
            i18n.t('training.progressStatus.' + res.message)
          const setError = {
            message: notificationMessage,
            manualType: null,
            detail: null
          }
          commit('SET_PROGRESS_STATUS', null)
          commit('SET_STOPPED_STATUS', true)
          commit('SET_ERROR_MESSAGE', setError)
          commit('RESET_JOB_INFO')
          commit('UPDATE_STATE', { training: false, numIter: 0, progress: 0 })
        } else if (res.message === 'job_limit_reached') {
          let checkDifferentOptimization = false
          if (res.code === 27005) {
            const jobs = res.runningJobs
            if (jobs && jobs.length > 0) {
              checkDifferentOptimization = jobs.some((job) => {
                return job?.isDifferentOptimization
              })
            }
            commit('SET_RUNNING_JOBS', jobs)
          }
          const checkError = finishTrainingError(
            res,
            i18n,
            checkDifferentOptimization,
            'training'
          )
          const status = checkError.status
          const manualType = checkError.manualType
          const errorDetail = checkError.detail
          const notificationMessage =
            i18n.t('training.progressStatus.type.training') +
            i18n.t('training.progressStatus.' + status, null, errorDetail)

          const setError = {
            message: notificationMessage,
            manualType: manualType,
            detail: errorDetail
          }
          commit('SET_ERROR_MESSAGE', setError)
          commit('SET_PROGRESS_STATUS', null)
          commit('SET_STOPPED_STATUS', true)
          commit('RESET_JOB_INFO')
          commit('UPDATE_STATE', { training: false, numIter: 0, progress: 0 })
        }
        throw res
      }
      dispatch('startLearning', res)
    },
    async cancelWatchProgress({ state, commit }) {
      state.cancelToken?.cancel()
      commit('SET_CANCEL_TOKEN', null)
    },
    async saveTrainedAi(
      { state, commit, dispatch },
      { modelId, modelAccountId, projectId, setting }
    ) {
      const req = {
        action: 'saveTrainedAi',
        modelId: modelId,
        modelAccountId: modelAccountId,
        projectId: projectId,
        setting: setting
      }
      return await this._vm.$sendMessageAndReceive(req)
    },
    async getNumTrainedAi({ commit }, { recipeId, accountId }) {
      const req = {
        action: 'getNumTrainedAi',
        recipe_id: recipeId,
        recipe_account_id: accountId
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      commit('SET_NUM_AIS', res.number)
    },
    async fetchTimeseriesDetail({ commit, dispatch }, { id, targetIndex }) {
      const req = {
        action: 'getTimeseriesDetail',
        id: id,
        targetIndex: targetIndex
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        commit('SET_TIMESERIES_DETAIL', res.values[0])
        const modelInfo = res?.info || {}
        commit('SET_MODEL_INFO', modelInfo)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchSavedTimeseriesDetail(
      { commit, dispatch },
      { id, targetIndex }
    ) {
      const req = {
        action: 'getTrainedAiTimeseriesDetail',
        id: id,
        targetIndex: targetIndex
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        commit('SET_TIMESERIES_DETAIL', res.values)
        const modelInfo = res?.info || {}
        commit('SET_MODEL_INFO', modelInfo)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchTrainedAiResult(
      { state, commit, dispatch },
      {
        id,
        targetIndex,
        algoIndex,
        accountId,
        threshold = 0.5,
        reversePositive = false,
        limit = 50,
        offset = 0,
        isImage = false
      }
    ) {
      const req = {
        id: id,
        targetIndex: targetIndex,
        algoIndex: algoIndex,
        accountId: accountId,
        threshold: threshold,
        reversePositive: reversePositive,
        limit: limit,
        offset: offset
      }
      try {
        const resultRes = await this._vm.$sendMessageAndReceive({
          action: 'getModelDetail',
          ...req
        })
        commit('SET_TRAINING_COLUMN_RESULT', {
          values: resultRes.values,
          algoIndex: algoIndex,
          targetIndex: targetIndex,
          isImage: isImage
        })
        const learningResultRes = await this._vm.$sendMessageAndReceive({
          action: 'getLearningResults',
          modelId: id,
          columnIndex: targetIndex,
          accountId: accountId,
          threshold: threshold,
          reversePositive: reversePositive
        })
        commit('SET_TRAINING_COLUMN_LEARNING_RESULT', {
          values: learningResultRes.list,
          algoIndex: algoIndex,
          targetIndex: targetIndex,
          isImage: isImage
        })
        const modelInfo = learningResultRes.info || {}
        commit('SET_MODEL_INFO', modelInfo)

        if (state.firstSettedTrials.length === 0) return
        const trialsRes = await this._vm.$sendMessageAndReceive({
          action: 'getModelCharts',
          modelId: id,
          accountId: accountId,
          columnIndex: targetIndex
        })
        commit('REPLACE_TRIAL', {
          value: trialsRes.charts[0]
        })
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchValidExpressions(
      { commit, dispatch },
      { expressions, columns }
    ) {
      const req = {
        action: 'validateExpressions',
        expressions,
        columns
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.result
      } catch (error) {
        dispatch('notifyError', error)
        return []
      }
    },
    async fetchModelOptimizationResult({ state, dispatch, commit }, payload) {
      const req = {
        action: 'getOptimizationResultByPaging',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        if (Object.keys(state.optimizationResult.result).length === 0) {
          commit('SET_OPTIMIZATION_RESULT_DETAILE', res.result)
        }
        return res
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchSavedTrainedAiResult(
      { dispatch, commit },
      {
        id,
        columnIndex,
        threshold = 0.5,
        reversePositive = false,
        limit = 50,
        offset = 0,
        isImage = false
      }
    ) {
      const req = {
        action: 'getTrainedAIDetail',
        id,
        columnIndex,
        threshold: threshold,
        reversePositive: reversePositive,
        limit: limit,
        offset: offset
      }
      const reqScatter = {
        action: 'getTrainedAIScatter',
        id,
        columnIndex
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        commit('SET_SAVED_TRAINING_RESULT', {
          value: res.value,
          targetIndex: columnIndex,
          isImage: isImage
        })
        // chart set
        if (res.value?.trials && Object.keys(res.value.trials).length > 0) {
          commit('REPLACE_TRIAL', { value: res.value })
        }

        if (!isImage) {
          const resScatter = await this._vm.$sendMessageAndReceive(reqScatter)
          commit('SET_SAVED_TRAINING_SCATTER_RESULT', {
            value: resScatter.result
          })
        }
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    setFinishColumns({ commit }) {
      commit('SET_FINISH_COLUMNS')
    },

    // テキストマイニングの情報取得
    async getModelTextMining({ dispatch }, { loadType, payload }) {
      const action =
        'getModel' + loadType.charAt(0).toUpperCase() + loadType.slice(1)
      const req = {
        action,
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.result
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async getTimeTransformerV2BeforeSetting({ dispatch }, projectId) {
      const req = {
        action: 'getColumnConditions',
        projectId: projectId
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.data
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async downloadClusteringResult({ dispatch }, { type, payload }) {
      let action = 'downloadModelClusteringResult'
      if (type === 'all') {
        action = 'downloadModelClusteringResultAll'
      }
      const req = {
        action,
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        const file = res.file
        const fileName =
          type === 'all' ? 'clustering_result_all.' : 'clustering_result.'
        const ext = res.ext
        const decoded = base64ToBytes(file)
        await download(decoded, fileName + ext)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchClusteringResult({ dispatch }, payload) {
      const req = {
        action: 'getModelClusteringResult',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.data
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchClusteringDistribution({ dispatch }, payload) {
      const req = {
        action: 'getModelClusteringDistribution',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.data
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchTrainingRegressionGraph({ dispatch }, payload) {
      const req = {
        action: 'getModelResidualGraph',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        if (res.status === 'error') {
          throw res
        }
        return res.result
      } catch (error) {
        return error.code
      }
    },
    async fetchTrainingRegressionGraphDetail({ dispatch }, payload) {
      const req = {
        action: 'getModelResidualGraphDetail',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.result
      } catch (error) {
        dispatch('notifyError', error)
      }
    }
  }
}

export default learning
