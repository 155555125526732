import { i18n } from '@/main'
import axios from 'axios'
import { intlSorter } from '@/lib/sort'
import { CancelToken } from '@/plugin/ws'
import { finishTrainingError } from '@/lib/training.js'
import { uploadViaSocket } from '@/lib/upload.js'
import { base64ToBytes, download } from '../lib/download'

const inference = {
  namespaced: true,
  state: {
    inferenceProgressStatus: '',
    inferenceErrorMessage: null,
    inferenceErrorDetail: {},
    inferenceWarnings: [],

    inferenceId: null,
    inferenceType: '',
    resultInputColumns: [],
    predictedColumns: [],
    predictedTable: [],
    classes: [],
    hasProbability: true,
    imageClassficationResult: [],
    textClassificationResult: [],
    vectorizationResult: [],
    dimRedResult: null,
    total: null,
    meanShap: null,
    featureImportance: null,
    featureImportanceImg: null,
    forecast: [],

    inferencedTrainedAiId: null,
    inferencedColumns: [],
    inferencedColumnsBase: [],
    inferenceStatus: {},
    finishColumnsList: null,
    processStatusList: null,
    runMultiInference: false,

    // optimization
    jobInfo: null,
    settedModelId: null,
    showOptimizationResult: false,
    optimizationProgressStatus: null,
    newlyPredictionColumns: [],
    newlyFinishedPredictionColumns: [],
    optimizationInProgress: false,
    trainingNumIter: 0,
    trainingProgress: 0,
    optimizationNumIter: 0,
    optimizationProgress: 0,
    optimizationConditions: [],
    optimizationResult: {
      result: {},
      columnRanges: [],
      total: 0,
      length: 0,
      optimizationConditionsId: null
    },
    optimizationModelInfo: {},
    optimizationConditionsInfo: {
      name: '',
      description: ''
    },
    executedOptimizationConditions: [],
    optimizationErrorMessage: null,
    optimizationErrorManual: null,

    cancelToken: null,
    isOptimizationStopped: false
  },
  getters: {
    inferring: (state) => state.inferenceProgressStatus === 'INFERRING',
    inferenceErrorMessage: (state) =>
      state.inferenceProgressStatus === 'ERROR'
        ? state.inferenceErrorMessage
        : null,
    inferenceErrorDetail: (state) => state.inferenceErrorDetail,
    inferenceWarnings: (state) => state.inferenceWarnings,
    runMultiInference: (state) => state.runMultiInference
  },
  mutations: {
    RESET(state) {
      state.inferenceProgressStatus = ''
      state.inferenceErrorMessage = null
      state.inferenceWarnings.splice(0)
      state.inferenceErrorDetail = {}
      state.uploadInferenceFileProgress = 0
      state.inferenceType = ''
      state.jobInfo = null
      state.settedModelId = null
      state.optimizationProgressStatus = null
      state.showOptimizationResult = false
    },
    RESET_RESULT(state) {
      state.resultInputColumns = []
      state.predictedColumns = []
      state.predictedTable = []
      state.imageClassficationResult = []
      state.textClassificationResult = []
      state.classes = []
      state.hasProbability = true
      state.vectorizationResult = []
      state.dimRedResult = null
      state.total = null
      state.meanShap = null
      state.featureImportanceImg = null
      state.forecast = []
      state.inferencedColumns = []
      state.inferencedColumnsBase = []
      state.inferenceStatus = {}
      state.finishColumnsList = null
      state.processStatusList = null
      state.runMultiInference = false
      state.newlyPredictionColumns = []
      state.newlyFinishedPredictionColumns = []
      state.optimizationInProgress = false
      state.trainingNumIter = 0
      state.trainingProgress = 0
      state.optimizationNumIter = 0
      state.optimizationProgress = 0
      state.optimizationConditions = []
      state.optimizationResult = {
        result: {},
        columnRanges: [],
        total: 0,
        length: 0,
        optimizationConditionsId: null
      }
      state.optimizationModelInfo = {}
      state.optimizationConditionsInfo = {
        name: '',
        description: ''
      }
      state.executedOptimizationConditions = []
      state.optimizationErrorMessage = null
      state.optimizationErrorManual = null
      state.isOptimizationStopped = false
    },
    SET_STATUS(
      state,
      {
        uploading = null,
        status = null,
        error = null,
        errorDetail = null,
        warnings = null
      }
    ) {
      if (status !== null) state.inferenceProgressStatus = status
      if (error !== null) state.inferenceErrorMessage = error
      if (errorDetail !== null) state.inferenceErrorDetail = errorDetail
      if (warnings !== null && warnings.length > 0) {
        // 重複なしのwarningsを渡す
        warnings.forEach((warning) => {
          if (!state.inferenceWarnings.includes(warning)) {
            state.inferenceWarnings.push(warning)
          }
        })
      }
    },
    SET_RESULT(state, { res, onlySetMeanShap = false }) {
      if (res?.inferenceType) {
        state.inferenceType = res.inferenceType
      }
      if (res?.id) {
        state.inferenceId = res.id
      }
      if (res?.trainedAiId) {
        state.inferencedTrainedAiId = res.trainedAiId
      }

      if (state.inferenceType === 'classficationStructuredData') {
        state.meanShap = res.mean_shap
        if (!onlySetMeanShap) {
          state.resultInputColumns = res.inputColumns
          state.predictedColumns = res.predictionColumn
          state.predictedTable = res.list
          state.inferenceId = res.id
          state.hasProbability = res.probability

          state.featureImportance = res.feature_importance
          state.featureImportanceImg = res.feature_importance_image
          state.total = res.total

          const classes = res.classes ?? []
          state.classes = classes.length > 0 ? classes.sort(intlSorter) : []
        }
      } else if (state.inferenceType === 'classification') {
        state.imageClassficationResult = res.list
        state.inferenceId = res.id
        state.classes = res.classes.sort(intlSorter)
      } else if (state.inferenceType === 'textClassification') {
        state.textClassificationResult = res.list
        state.inferenceId = res.id
        state.hasProbability = res.probability
        state.classes = res.classes.sort(intlSorter)
      } else if (
        state.inferenceType === 'vectorization' ||
        state.inferenceType === 'textVectorization'
      ) {
        state.vectorizationResult = res.list
      } else if (state.inferenceType === 'dimRed') {
        state.dimRedResult = res.list
      } else if (
        state.inferenceType === 'time' ||
        state.inferenceType === 'time_transformerV2'
      ) {
        // 時系列の場合、フロントで推論IDを生成、保存するので、ここでは推論IDを保存しない
        state.resultInputColumns = res.inputColumns
        if (!(state.inferencedColumnsBase.length > 1)) {
          const target = res?.list ? res.list[0].forecast : res.forecast
          state.forecast = target
        }
      }
    },
    SET_INFERENCE_STATUS(state, res) {
      state.inferenceStatus = res
    },
    SET_FINISH_COLUMN(state, res) {
      state.finishColumnsList = res
    },
    SET_PROCESS_STATUS(state, res) {
      state.processStatusList = res
    },
    SET_FINISH_ALL(state, res) {
      state.runMultiInference = false
      if (state.inferenceStatus != null) {
        state.inferenceStatus.finishedPostProcessing = true
      }
    },
    SET_TIMESERIES_MULTI_RESULT(state, res) {
      state.forecast = res.forecast
    },
    SET_INFERENCE_INFO(state, res) {
      console.log(
        'SET_INFERENCE_INFO',
        res,
        res.baseTrainedAiPredictionColumns.length
      )
      state.inferencedColumns = res.predictionColumn
      state.inferencedColumnsBase = res.baseTrainedAiPredictionColumns
      state.inferencedTrainedAiId = res.inferencedTrainedAiId
      if (state.inferencedColumnsBase.length > 1) {
        state.runMultiInference = true
      }
    },
    SET_INFERENCE_ID(state, res) {
      state.inferenceId = res
    },
    SET_INFERENCE_TYPE(state, type) {
      state.inferenceType = type
    },
    SET_OPTIMIZATION_DISP(state, bool) {
      state.showOptimizationResult = bool
    },
    SET_JOB_INFO(state, value) {
      state.jobInfo = value
      state.settedModelId = value.jobName.split('-').pop()
    },
    RESET_JOB_INFO(state) {
      state.jobInfo = null
    },
    SET_PROGRESS_STATUS(state, value) {
      state.optimizationProgressStatus = value
    },
    SET_OPTIMIZATION_PREDICTION_COLUMNS(state, value) {
      state.newlyPredictionColumns = value.newlyPredictionColumns
    },
    UPDATE_STATE(state, { optimizationInProgress = null, numIter, progress }) {
      if (optimizationInProgress !== null) {
        state.optimizationInProgress = optimizationInProgress
      }
      state.trainingNumIter = numIter
      state.trainingProgress = progress
    },
    UPDATE_OPTIMIZATION_STATE(
      state,
      { optimizationInProgress = null, numIter, progress }
    ) {
      if (optimizationInProgress !== null) {
        state.optimizationInProgress = optimizationInProgress
      }
      state.optimizationNumIter = numIter
      state.optimizationProgress = progress
    },
    SET_OPTIMIZATION_RESULT(state, value) {
      state.optimizationConditions = value.columnConditions
      state.optimizationResult.columnRanges = value.columnRanges
      state.optimizationResult.total = value.total
      state.optimizationResult.length = value.length
      state.optimizationResult.optimizationConditionsId =
        value.optimizationConditionsId
      state.optimizationConditionsInfo.name = value.optimizationConditionsName
    },
    SET_OPTIMIZATION_RESULT_PAGING(state, value) {
      state.optimizationResult.result = { ...value.result }
      state.optimizationResult.total = value.total
      state.optimizationResult.length = value.length
    },
    SET_OPTIMIZATION_SETTINGS(state, { trainedAiId }) {
      state.inferencedTrainedAiId = trainedAiId
    },
    SET_OPTIMIZATION_TRAINING_FINISH_COLUMN(state, column) {
      state.newlyFinishedPredictionColumns.push(column)
    },
    SET_OPTIMIZATION_MODEL_INFO(state, modelInfo) {
      state.optimizationModelInfo = { ...modelInfo }
    },
    SET_OPTIMIZATION_CONDITIONS(state, conditions) {
      state.executedOptimizationConditions = conditions
    },
    UPDATE_OPTIMIZATION_CONDITIONS_INFO(state, value) {
      state.optimizationConditionsInfo.name = value.name
      state.optimizationConditionsInfo.description = value.description
    },
    SET_CANCEL_TOKEN(state, cancelToken) {
      state.cancelToken = cancelToken
    },
    SET_OPTIMIZATION_ERROR_MESSAGE(state, { message, manualType }) {
      state.optimizationErrorMessage = message
      state.optimizationErrorManual = manualType
    },
    SET_OPTIMIZATION_STOPPED_STATUS(state, flag) {
      state.isOptimizationStopped = flag
    }
  },
  actions: {
    reset({ commit }) {
      commit('RESET')
      commit('RESET_RESULT')
    },
    async inference(
      { state, commit, dispatch },
      {
        projectId,
        type,
        accountId,
        trainedAiId,
        file,
        dataId,
        dataAccountId,
        forecastTime,
        forecastTimeUnit,
        EDARecipeId,
        EDARecipeAccountId,
        predictionColumn,
        inferencedTrainedAiId,
        targetIndexList,
        baseTrainedAiPredictionColumns,
        threshold,
        inferenceEnvironment,
        targetClustering
      }
    ) {
      dispatch('reset')
      commit('SET_STATUS', { status: 'INFERRING' })

      let uploadFlag = false
      let inferenceId = null
      try {
        if (file) {
          // ファイルサイズチェック
          const LIMIT_FILE_SIZE = 200 * 1024 * 1024
          if (file.size > LIMIT_FILE_SIZE)
            throw new Error('ERROR_TOO_LARGE_INFER_DATA_S3')
          const reqUrl = {
            action: 'getInferenceUploadUrl',
            fileName: file.name
          }
          try {
            const resUrl = await this._vm.$sendMessageAndReceive(reqUrl)
            const url = resUrl.url
            inferenceId = resUrl.inferenceId

            // storageがS3の場合のみ、直接アップロードする仕組みを利用する
            if (url !== null) {
              await axios.put(url, file, {
                headers: {
                  // SignatureDoesNotMatchを避けるために、設定しないことを明示的に設定する
                  'Content-Type': ''
                }
              })
              uploadFlag = true
            }
          } catch (ex) {
            this._vm.log_info(ex)
            throw ex
          }
        }
        let res
        // 推論完了後、他のAIを推論対象に選択した場合、予測する列が変わってしまうのを防ぐために、固定する
        // 他のAIの推論実行時のRESET_RESULTで削除される
        if (
          predictionColumn &&
          baseTrainedAiPredictionColumns &&
          inferencedTrainedAiId
        ) {
          commit('SET_INFERENCE_INFO', {
            predictionColumn,
            baseTrainedAiPredictionColumns,
            inferencedTrainedAiId
          })
        }
        const params = {
          action: 'startInference',
          projectId: projectId,
          accountId: accountId,
          trainedAiId: trainedAiId,
          type: type,
          inferenceEnvironment
        }
        // ClusterFlowとMFTransformerV2用の設定が必要なためセット
        if (type === 'clustering') {
          params.targetClustering = targetClustering
          commit('SET_INFERENCE_TYPE', type)
        } else if (type === 'time_transformerV2') {
          commit('SET_INFERENCE_TYPE', type)
        }
        if (type === 'time') {
          commit('SET_INFERENCE_TYPE', type)

          params.forecastTime = forecastTime
          params.forecastTimeUnit = forecastTimeUnit
          if (targetIndexList.length > 0) {
            // 学習済みAIに紐づく予測する列が１つならtargetIndexListは渡さない
            params.targetIndexList = targetIndexList
          }
        } else if (file || dataId) {
          if (EDARecipeId != null) {
            params.EDARecipeId = EDARecipeId
            params.EDARecipeAccountId = EDARecipeAccountId
          }
          if (targetIndexList && targetIndexList.length > 0) {
            params.targetIndexList = targetIndexList
          }

          if (file) {
            params.fileSize = file.size
            params.fileName = file.name
          } else {
            params.dataAccountId = dataAccountId
            params.dataId = dataId
          }

          if (threshold) {
            params.threshold = threshold
          }

          params.uploaded = uploadFlag
          params.inferenceId = inferenceId
        } else {
          throw new Error('data or (upload)file is required')
        }
        const messageId = await this._vm.$sendMessage(params)
        const cancelToken = new CancelToken()

        state.cancelToken?.cancel()
        commit('SET_CANCEL_TOKEN', cancelToken)

        await this._vm.$watchProgress(
          messageId,
          {
            inferenceId: (msg) => {
              commit('SET_INFERENCE_ID', msg.id)
            },
            startUploading: (msg) => {
              // fileアップロード処理
              uploadViaSocket(this._vm.$socket, file, {
                header: {
                  action: 'uploadingInferData',
                  m_id: messageId
                }
              })
            },
            inferenceProgress: (msg) => {
              commit('SET_INFERENCE_STATUS', msg.data)
            },
            checkInference: (msg) => {
              commit('SET_FINISH_COLUMN', msg.data)
            },
            checkProcess: (msg) => {
              commit('SET_PROCESS_STATUS', msg.data)
            },
            finishInference: (msg) => {
              res = msg
              commit('SET_FINISH_ALL', true)
            }
          },
          cancelToken
        )
        commit('SET_CANCEL_TOKEN', null)

        const checkTypes = ['regression', 'classification']
        const checkMultiNotTimeseries =
          baseTrainedAiPredictionColumns.length > 1 && checkTypes.includes(type)
        if (!checkMultiNotTimeseries && type !== 'clustering') {
          if (res.status === 'error') {
            throw res
          }
          if (state.processStatusList === null) {
            commit('SET_RESULT', { res: res })
          } else {
            // 表示している情報（ページ情報）を変更させないようにするため、マルチプロセスの場合はmeanShapのみを更新
            commit('SET_RESULT', { res: res, onlySetMeanShap: true })
          }
        }

        commit('SET_STATUS', {
          status: 'FINISH_INFERENCE',
          warnings: res.warnings
        })
      } catch (err) {
        const errMessage = err.message || 'ERROR_UNKNOWN'
        const errDetail = err.error_info || {}
        commit('SET_INFERENCE_STATUS', {})
        commit('SET_STATUS', {
          status: 'ERROR',
          error: errMessage,
          errorDetail: errDetail
        })
      }
    },
    async reenterInference(
      { state, commit, dispatch },
      {
        targetInferenceId,
        predictionColumn,
        inferencedTrainedAiId,
        baseTrainedAiPredictionColumns,
        inferenceType
      }
    ) {
      dispatch('reset')
      commit('SET_STATUS', { status: 'INFERRING' })
      commit('SET_INFERENCE_ID', targetInferenceId)
      commit('SET_INFERENCE_TYPE', inferenceType)
      if (
        predictionColumn &&
        baseTrainedAiPredictionColumns &&
        inferencedTrainedAiId
      ) {
        commit('SET_INFERENCE_INFO', {
          predictionColumn,
          baseTrainedAiPredictionColumns,
          inferencedTrainedAiId
        })
      }

      const req = {
        action: 'inferenceProgress',
        inferenceId: targetInferenceId
      }
      const messageId = this._vm.$sendMessage(req)
      try {
        let res
        await this._vm.$watchProgress(messageId, {
          inferenceProgress: (msg) => {
            commit('SET_INFERENCE_STATUS', msg.data)
          },
          checkInference: (msg) => {
            commit('SET_FINISH_COLUMN', msg.data)
          },
          checkProcess: (msg) => {
            commit('SET_PROCESS_STATUS', msg.data)
          },
          finishInference: (msg) => {
            res = msg
            commit('SET_FINISH_ALL', true)
          }
        })
        commit('SET_RESULT', { res: res })
        commit('SET_STATUS', {
          status: 'FINISH_INFERENCE',
          warnings: res.warnings
        })
      } catch (err) {
        const errMessage = err.message || 'ERROR_UNKNOWN'
        const errDetail = err.error_info || {}
        commit('SET_INFERENCE_STATUS', {})
        commit('SET_STATUS', {
          status: 'ERROR',
          error: errMessage,
          errorDetail: errDetail
        })
      }
    },
    async reenterInferenceInfo(
      { state, commit, dispatch },
      { targetInferenceId }
    ) {
      const req = {
        action: 'inferenceProgressInfo',
        inferenceId: targetInferenceId
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        const retRes = {}
        for (const key in res) {
          if (res[key] !== null) {
            retRes[key] = res[key]
          }
        }
        return retRes
      } catch (ex) {
        this._vm.log_info(ex)
      }
    },
    async getInferenceResult({ state, commit, dispatch }, { inferenceId }) {
      const req = {
        action: 'getInferenceResult',
        inferenceId
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      console.log('getInferenceResult', res)

      commit('SET_INFERENCE_ID', inferenceId)
      commit('SET_INFERENCE_TYPE', res.inferenceType)

      commit('SET_RESULT', { res })
      commit('SET_STATUS', {
        status: 'FINISH_INFERENCE',
        warnings: []
      })
    },
    setInferenceInfoForInferenceResult(
      { state, commit, dispatch },
      {
        predictionColumn,
        baseTrainedAiPredictionColumns,
        inferencedTrainedAiId
      }
    ) {
      commit('SET_FINISH_COLUMN', predictionColumn)
      commit('SET_INFERENCE_INFO', {
        predictionColumn,
        baseTrainedAiPredictionColumns,
        inferencedTrainedAiId
      })
    },
    async fetchTimeseriesResult(
      { state, commit, dispatch },
      { id, targetIndex }
    ) {
      const req = {
        action: 'getTimeseriesInferenceResult',
        inferenceId: id,
        targetIndex: targetIndex
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        commit('SET_TIMESERIES_MULTI_RESULT', res)
        if (res.warnings && res.warnings.length > 0) {
          commit('SET_STATUS', {
            status: 'FINISH_INFERENCE',
            warnings: res.warnings
          })
        }
      } catch (ex) {
        this._vm.log_info(ex)
      }
    },
    async fetchInferenceResultPaging({ state, commit, dispatch }, payload) {
      const req = {
        action: 'getInferenceResultPaging',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        commit('SET_RESULT', { res: res.value })
      } catch (ex) {
        this._vm.log_info(ex)
      }
    },

    async startOptimization({ state, commit }, res) {
      commit('SET_JOB_INFO', res)
    },
    async setOptimizationPredictionColumns({ state, commit }, res) {
      commit('SET_OPTIMIZATION_PREDICTION_COLUMNS', res)
    },
    async updateProgress({ state, commit }, res) {
      if (
        state.optimizationProgressStatus === 'training' ||
        state.optimizationProgressStatus === 'preparingEnvironment'
      ) {
        commit('UPDATE_STATE', { numIter: res.nIter + 1, progress: res.iter })
        commit('SET_PROGRESS_STATUS', 'training')
      }
    },
    async setClassicResult({ state, commit }, res) {
      commit('SET_OPTIMIZATION_TRAINING_FINISH_COLUMN', res?.prediction_column)
    },
    async updateOptimizationProgress({ state, commit }, res) {
      if (
        state.optimizationProgressStatus === 'training' ||
        state.optimizationProgressStatus === 'preparingEnvironment'
      ) {
        if (res.nIter > 0) {
          commit('UPDATE_OPTIMIZATION_STATE', {
            numIter: res.nIter,
            progress: res.iter + 1
          })
          commit('SET_PROGRESS_STATUS', 'training')
        }
      }
    },
    async setOptimizationResult({ state, commit }, res) {
      commit('SET_OPTIMIZATION_RESULT', res)
    },
    async finishOptimization({ state, commit, dispatch }, res) {
      let status = null
      let notificationMessage = null
      let manualType = null
      if (res.status === 'error') {
        if (state.trainingProgressStatus !== 'stoppingTraining') {
          if (res.message === 'exceededEducationPlanTime') {
            status = res.message
          } else {
            const jobs = res.runningJobs
            let checkDifferentOptimization = false
            if (jobs && jobs.length > 0) {
              checkDifferentOptimization = jobs.some((job) => {
                return job?.isDifferentOptimization
              })
            }
            const checkError = finishTrainingError(
              res,
              i18n,
              checkDifferentOptimization,
              'optimization'
            )
            status = checkError.status
            notificationMessage = checkError.notificationMessage
            manualType = checkError.manualType
          }
          if (notificationMessage === null) {
            notificationMessage =
              i18n.t('training.progressStatus.type.optimization') +
              i18n.t('training.progressStatus.' + status)
          }
          const setError = {
            message: notificationMessage,
            manualType: manualType
          }
          commit('SET_OPTIMIZATION_ERROR_MESSAGE', setError)
          commit('RESET_JOB_INFO')
        } else {
          notificationMessage = i18n.t('training.message.trainingStop', {
            type: i18n.t('training.message.type.optimization')
          })
          commit('SET_OPTIMIZATION_DISP', false)
        }
      } else {
        commit('SET_OPTIMIZATION_MODEL_INFO', res.model_info)
        status = 'finishOptimization'
        notificationMessage = i18n.t('training.message.trainingSuccess', {
          type: i18n.t('training.message.type.optimization')
        })
      }
      commit('UPDATE_STATE', {
        optimizationInProgress: false,
        numIter: 0,
        progress: 0
      })
      commit('SET_PROGRESS_STATUS', status)
      // 学習終了時の通知機能
      // eslint-disable-next-line no-unused-vars
      const notification = new Notification(
        i18n.t('training.message.finishTraining', {
          type: i18n.t('training.message.type.optimization')
        }),
        {
          body: notificationMessage,
          icon: '',
          tag: '',
          data: {}
        }
      )
    },

    async optimizationAsync({ state, commit, dispatch }, payload) {
      commit('RESET')
      commit('RESET_RESULT')
      commit('UPDATE_STATE', {
        optimizationInProgress: true,
        numIter: 0,
        progress: 0
      })
      commit('SET_PROGRESS_STATUS', 'preparingEnvironment')
      commit('SET_OPTIMIZATION_DISP', true)
      commit('SET_OPTIMIZATION_SETTINGS', payload)
      if (payload.checkGetConditions) {
        // 名前重複判定を行うために、trainedAiに所属する最適化条件の一覧を取得する
        const reqList = {
          action: 'getOptimizationConditionsList',
          trainedAiId: payload.trainedAiId
        }
        try {
          const resList = await this._vm.$sendMessageAndReceive(reqList)
          commit('SET_OPTIMIZATION_CONDITIONS', resList.optimizationConditions)
        } catch (error) {
          dispatch('notifyError', error)
        }
      } else {
        commit('SET_OPTIMIZATION_CONDITIONS', payload.conditionsList)
      }

      // start
      const req = {
        action: 'optimizationStart',
        projectId: payload.projectId,
        trainedAiId: payload.trainedAiId,
        trainConfig: payload.trainConfig,
        optimizationConditions: payload.optimizationConditions
      }
      const messageId = this._vm.$sendMessage(req)
      const cancelToken = new CancelToken()

      try {
        state.cancelToken?.cancel()
        commit('SET_CANCEL_TOKEN', cancelToken)
        await this._vm.$watchProgress(
          messageId,
          {
            optimizationStart: (msg) => dispatch('startOptimization', msg),
            newlyPredictionColumns: (msg) =>
              dispatch('setOptimizationPredictionColumns', msg),
            learning: (msg) => dispatch('updateProgress', msg),
            classicResult: (msg) => dispatch('setClassicResult', msg),
            optimizationIteration: (msg) =>
              dispatch('updateOptimizationProgress', msg),
            optimizationResult: (msg) => dispatch('setOptimizationResult', msg),
            finishLearning: (msg) => dispatch('finishOptimization', msg)
          },
          cancelToken
        )
        commit('SET_CANCEL_TOKEN', null)
      } catch (ex) {
        dispatch('finishOptimization', ex)
      }
    },
    async cancelWatchProgress({ state, commit }) {
      state.cancelToken?.cancel()
      commit('SET_CANCEL_TOKEN', null)
    },
    async stopOptimizationAsync({ state, commit }) {
      if (!state.optimizationInProgress) return
      const jobInfo = state.jobInfo
      if (jobInfo == null) return
      const jobId = jobInfo.jobId
      commit('SET_PROGRESS_STATUS', 'stoppingTraining')
      commit('RESET_JOB_INFO')
      commit('SET_OPTIMIZATION_STOPPED_STATUS', true)
      const res = await this._vm.$sendMessageAndReceive({
        action: 'stopLearning',
        jobId
      })
      commit('RESET')
      commit('RESET_RESULT')
    },
    async fetchOptimizationResult({ state, commit, dispatch }, payload) {
      const req = {
        action: 'getOptimizationResultByPaging',
        ...payload
      }

      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        commit('SET_OPTIMIZATION_RESULT_PAGING', res)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async updateOptimzationConditions({ dispatch, commit }, payload) {
      const req = {
        action: 'optimizationConditionsUpdate',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)

        commit('UPDATE_OPTIMIZATION_CONDITIONS_INFO', res.result)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async downloadClusteringResult({ dispatch }, { type, payload }) {
      let action = 'downloadInferenceClusteringResult'
      if (type === 'all') {
        action = 'downloadInferenceClusteringResultAll'
      }
      const req = {
        action,
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        const file = res.file
        const fileName =
          type === 'all' ? 'clustering_result_all.' : 'clustering_result.'
        const ext = res.ext
        const decoded = base64ToBytes(file)
        await download(decoded, fileName + ext)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchClusteringResult({ dispatch }, payload) {
      const req = {
        action: 'getInferenceClusteringResult',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.data
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchClusteringDistribution({ dispatch }, payload) {
      const req = {
        action: 'getInferenceClusteringDistribution',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.data
      } catch (error) {
        dispatch('notifyError', error)
      }
    }

    // テキストマイニングの情報取得
    /**
     * TODO 推論実行時のテキストマイニングの結果取得実装後修正
    async getInferencedTextMining({ dispatch }, { loadType, payload }) {
      let res = {}
      const action =
        'getInferenced' + loadType.charAt(0).toUpperCase() + loadType.slice(1)
      const req = {
        action,
        ...payload
      }
      try {
        res = await this._vm.$sendMessageAndReceive(req)
        return res.result
      } catch (error) {
        dispatch('notifyError', error)
      }
    }
     */
  }
}

export default inference
