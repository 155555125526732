// polyfill from https://developer.mozilla.org/ja/docs/Web/API/TextEncoder with some modification
if (typeof TextEncoder === 'undefined') {
  window.TextEncoder = function TextEncoder() {}
  TextEncoder.prototype.encode = function encode(str) {
    'use strict'
    const Len = str.length
    let resPos = -1
    // The Uint8Array's length must be at least 3x the length of the string because an invalid UTF-16
    //  takes up the equivelent space of 3 UTF-8 characters to encode it properly. However, Array's
    //  have an auto expanding length and 1.5x should be just the right balance for most uses.
    const resArr =
      typeof Uint8Array === 'undefined'
        ? new Array(Len * 1.5)
        : new Uint8Array(Len * 3)
    for (let point = 0, nextcode = 0, i = 0; i !== Len; ) {
      point = str.charCodeAt(i)
      i += 1
      if (point >= 0xd800 && point <= 0xdbff) {
        if (i === Len) {
          resArr[(resPos += 1)] = 0xef /* 0b11101111 */
          resArr[(resPos += 1)] = 0xbf /* 0b10111111 */
          resArr[(resPos += 1)] = 0xbd /* 0b10111101 */
          break
        }
        // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
        nextcode = str.charCodeAt(i)
        if (nextcode >= 0xdc00 && nextcode <= 0xdfff) {
          point = (point - 0xd800) * 0x400 + nextcode - 0xdc00 + 0x10000
          i += 1
          if (point > 0xffff) {
            resArr[(resPos += 1)] = (0x1e << 3) | (point >>> 18)
            resArr[(resPos += 1)] =
              (0x2 << 6) | ((point >>> 12) & 0x3f) /* 0b00111111 */
            resArr[(resPos += 1)] =
              (0x2 << 6) | ((point >>> 6) & 0x3f) /* 0b00111111 */
            resArr[(resPos += 1)] = (0x2 << 6) | (point & 0x3f) /* 0b00111111 */
            continue
          }
        } else {
          resArr[(resPos += 1)] = 0xef /* 0b11101111 */
          resArr[(resPos += 1)] = 0xbf /* 0b10111111 */
          resArr[(resPos += 1)] = 0xbd /* 0b10111101 */
          continue
        }
      }
      if (point <= 0x007f) {
        resArr[(resPos += 1)] = (0x0 << 7) | point
      } else if (point <= 0x07ff) {
        resArr[(resPos += 1)] = (0x6 << 5) | (point >>> 6)
        resArr[(resPos += 1)] = (0x2 << 6) | (point & 0x3f) /* 0b00111111 */
      } else {
        resArr[(resPos += 1)] = (0xe << 4) | (point >>> 12)
        resArr[(resPos += 1)] =
          (0x2 << 6) | ((point >>> 6) & 0x3f) /* 0b00111111 */
        resArr[(resPos += 1)] = (0x2 << 6) | (point & 0x3f) /* 0b00111111 */
      }
    }
    if (typeof Uint8Array !== 'undefined') return resArr.subarray(0, resPos + 1)
    // else // IE 6-9
    resArr.length = resPos + 1 // trim off extra weight
    return resArr
  }
  TextEncoder.prototype.toString = function () {
    return '[object TextEncoder]'
  }
  try {
    // Object.defineProperty only works on DOM prototypes in IE8
    Object.defineProperty(TextEncoder.prototype, 'encoding', {
      get: function () {
        // eslint-disable-next-line no-prototype-builtins
        if (TextEncoder.prototype.isPrototypeOf(this)) return 'utf-8'
        else throw TypeError('Illegal invocation')
      }
    })
  } catch (e) {
    /* IE6-8 fallback */ TextEncoder.prototype.encoding = 'utf-8'
  }
  if (typeof Symbol !== 'undefined')
    TextEncoder.prototype[Symbol.toStringTag] = 'TextEncoder'
}

function concatArrayBuffer(segments) {
  let sumLength = 0
  for (let i = 0; i < segments.length; ++i) {
    sumLength += segments[i].byteLength
  }
  const res = new Uint8Array(sumLength)
  let pos = 0
  for (let i = 0; i < segments.length; ++i) {
    res.set(new Uint8Array(segments[i]), pos)
    pos += segments[i].byteLength
  }
  return { result: res.buffer, length: sumLength }
}
function pascalString(str) {
  const te = new TextEncoder()
  const bstr = te.encode(str)
  const l = bstr.length
  if (l > 255) throw new Error('Too long to convert into pascalString')
  const res = new Uint8Array(l + 1)
  res.set([l], 0)
  res.set(bstr, 1)
  return res
}
function setHeader(body, header) {
  if (Object.keys(header).length === 0) return body
  const list = []
  for (const k in header) {
    list.push(pascalString(k))
    list.push(pascalString(header[k].toString()))
  }
  const concatHeader = concatArrayBuffer(list)

  const headerLen = new Uint8Array(4)
  const view = new DataView(headerLen.buffer, 0)
  view.setInt32(0, concatHeader.length)

  return concatArrayBuffer([headerLen, concatHeader.result, body]).result
}

export async function uploadViaSocket(
  /** @type {WebSocket} */ socket,
  /** @type {File} */ file,
  { chunkSize = 1e5, header = {} } = {}
) {
  /** @type {<T>() => Promise<T>} */
  function newPromiseTriple() {
    /** @type {(value?: T | PromiseLike<T>) => void} */
    let res
    /** @type {(reason?: any) => void} */
    let rej
    const promise = new Promise((resolve, reject) => {
      res = resolve
      rej = reject
    })
    return { promise, resolve: res, reject: rej }
  }

  async function loadFile(/** @type {File} */ file) {
    const reader = new FileReader()
    /** @type {Promise<ProgressEvent<FileReader>>} */
    const fileReaderPromise = new Promise((resolve, reject) => {
      reader.addEventListener('load', resolve)
      reader.addEventListener('error', () =>
        reject(new Error('FILE_READ_ERROR'))
      )
      reader.addEventListener('abort', () =>
        reject(new Error('FILE_READ_ABORTED'))
      )
    })
    reader.readAsArrayBuffer(file)
    await fileReaderPromise
    /** @type {ArrayBuffer} */
    const body = reader.result
    return body
  }

  const fileSize = file.size
  const body = await loadFile(file)
  const highWaterMark = chunkSize * 10

  const { promise: promiseSocketClose, reject: rejectSocketClose } =
    newPromiseTriple()
  const onSocketClose = () => rejectSocketClose(new Error('SOCKET_CLOSED'))
  socket.addEventListener('close', onSocketClose)
  try {
    let count = 0
    for (let i = 0; i < fileSize; i += chunkSize) {
      while (socket.bufferedAmount >= highWaterMark) {
        // wait until a socket message arrives or reject if the socket has been closed
        const { promise: promiseSocketMessage, resolve: resolveSocketMessage } =
          newPromiseTriple()
        socket.addEventListener('message', resolveSocketMessage)
        try {
          await Promise.race([promiseSocketMessage, promiseSocketClose])
        } finally {
          socket.removeEventListener('message', resolveSocketMessage)
        }
      }
      const chunk = body.slice(i, chunkSize + i)
      header.count = count
      count += 1
      socket.send(setHeader(chunk, header))
    }
  } finally {
    socket.removeEventListener('close', onSocketClose)
  }
}
