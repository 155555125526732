import store from '@/stores/index.js'

const EVENT_NAME = 'appEvent'
const gtmTools = {
  install: function (Vue, options) {
    Vue.prototype.$gtmDataLayer = {
      sendPageView: function (action, label) {
        window.dataLayer.push(function () {
          this.reset()
        })
        window.dataLayer.push({
          event: 'appPageView',
          eventCategory: 'appPageView',
          eventAction: action || 'pageView',
          eventLabel: label || 'defaultLabel',
          eventCheckId: store.getters['auth/accountId'],
          eventPlan: store.getters['auth/plan'],
          eventMail: store.getters['auth/email']
        })
      },
      sendEvent: function (action, label) {
        window.dataLayer.push(function () {
          this.reset()
        })
        window.dataLayer.push({
          event: EVENT_NAME,
          eventCategory: 'appEvent',
          eventAction: action || 'defaultAction',
          eventLabel: label || 'defaultLabel',
          eventCheckId: store.getters['auth/accountId'],
          eventPlan: store.getters['auth/plan'],
          eventMail: store.getters['auth/email']
        })
      },
      sendError: function (action, label) {
        window.dataLayer.push(function () {
          this.reset()
        })
        window.dataLayer.push({
          event: EVENT_NAME,
          eventCategory: 'appError',
          eventAction: action || 'defaultAction',
          eventLabel: label || 'defaultLabel',
          eventCheckId: store.getters['auth/accountId'],
          eventPlan: store.getters['auth/plan'],
          eventMail: store.getters['auth/email']
        })
      },
      sendErrorMessage: function (message, stackBacktrace) {
        window.dataLayer.push(function () {
          this.reset()
        })
        window.dataLayer.push({
          event: EVENT_NAME,
          errorMessage: message || 'defaultMessage',
          stackBacktrace: stackBacktrace || 'defaultStack',
          eventCheckId: store.getters['auth/accountId'],
          eventPlan: store.getters['auth/plan'],
          eventMail: store.getters['auth/email'],
          time: new Date()
        })
      },
      sendEventCategory: function (category, action, label, value) {
        window.dataLayer.push(function () {
          this.reset()
        })
        window.dataLayer.push({
          event: EVENT_NAME,
          eventCategory: category || 'appEvent',
          eventAction: action || 'defaultAction',
          eventLabel: label || 'defaultLabel',
          eventValue: value || null,
          eventCheckId: store.getters['auth/accountId'],
          eventPlan: store.getters['auth/plan'],
          eventMail: store.getters['auth/email']
        })
      }
    }
  }
}
export default gtmTools
