export function finishTrainingError(
  res,
  i18n,
  checkDifferentOptimization,
  type
) {
  let status = null
  let notificationMessage = null
  let manualType = null
  let detail = null
  switch (res.code) {
    case 26004:
      status = 'ERROR_MULTIPLE_DATE_COLUMNS'
      break
    case 26005:
      status = 'ERROR_DATE_COLUMNS_NOT_FOUND'
      break
    case 26008:
      status = 'ERROR_NULL_VALUES_IN_KNOWN_COLUMN'
      break
    case 27001:
      status = 'ERROR_INVALID_RECIPE'
      break
    case 27002:
      status = 'ERROR_INVALID_DATA'
      break
    case 27003:
      status = 'ERROR_TOO_FEW_DATA'
      break
    case 27004:
      status = 'ERROR_DATA_TOO_FEW_SPLIT'
      break
    case 27005: {
      status = checkDifferentOptimization
        ? 'ERROR_JOB_LIMIT_OPTIMIZATION'
        : 'ERROR_JOB_LIMIT'
      break
    }
    case 27006: {
      if (type === 'optimization') {
        status = 'ERROR_NONE_RECIPE_OPTIMIZATION'
      } else {
        status = 'ERROR_NONE_RECIPE'
      }
      break
    }
    case 27007: {
      if (type === 'optimization') {
        status = 'ERROR_NONE_DATA_OPTIMIZATION'
      } else {
        status = 'ERROR_NONE_DATA'
      }
      break
    }
    case 27009:
      status = 'ERROR_SUBSCRIPTION'
      break
    case 27010:
      status = 'ERROR_LEARNING_DATA_LOG_NEGATIVE_VALUE'
      break
    case 27011:
      status = 'ERROR_LEARNING_DATA_DIM_REDUCTION'
      break
    case 27100:
      status = 'ERROR_LEARNING_TRAIN_TEST_SPLIT_ERROR'
      break
    case 27101:
      status = 'ERROR_DATA_TOO_FEW_SPLIT'
      break
    case 27102:
      status = 'ERROR_TRAIN_TEST_SPLIT_TRAIN_DATA_TOO_FEW'
      break
    case 27103:
      status = 'ERROR_TRAIN_TEST_SPLIT_TEST_DATA_TOO_FEW'
      break
    case 27104:
      status = 'ERROR_TRAIN_TEST_SPLIT_INVALID_RATIO'
      break
    case 27105:
      status = 'ERROR_TRAIN_TEST_SPLIT_TEST_DATA_CLASS_TOO_FEW'
      break
    case 27200:
      status = 'ERROR_SEASONALITY_PERIOD'
      break
    case 27210:
      status = 'SPECIFIC_COLUMN_LESS_LEN_STR'
      break
    case 27220:
      status = 'ERROR_ZERO_TEST_DATA'
      break
    case 27230:
      status = 'ERROR_SPLIT_TEST_DATA'
      break
    case 27300:
      status = 'ERROR_LEARNING_TIMESERIESE_DATA_PARSE_ERROR'
      break
    case 27301:
      status = 'ERROR_TIMESERIES_INVALID_TIME_UNIT_ERROR'
      notificationMessage = i18n.t(
        'training.progressStatus.ERROR_TIMESERIES_INVALID_TIME_UNIT_ERROR',
        {
          unitCountErrorCandidate: res.error_info.unitCountErrorCandidate,
          unitErrorCandidate: i18n.t(
            'trainedAi.timeTransformerV2.timeUnit.' +
              res.error_info.unitErrorCandidate
          ),
          unitCount: res.error_info.unitCount,
          unit: i18n.t(
            'trainedAi.timeTransformerV2.timeUnit.' + res.error_info.unit
          )
        }
      )
      break
    case 27303:
      status = 'ERROR_TIMESERIES_SEQUENCE_NOT_ENOUGH_LENGTH_ERROR'
      notificationMessage = i18n.t(
        'training.progressStatus.ERROR_TIMESERIES_SEQUENCE_NOT_ENOUGH_LENGTH_ERROR',
        { seqLength: res.error_info.seqLength }
      )
      break
    case 27500:
      status = 'ERROR_LEARNING_UNDER_SAMPLING_ERROR'
      break
    case 27501:
      status = 'ERROR_LEARNING_UNDER_SAMPLING_INVALID_RECIPE'
      break
    case 27502:
      status = 'ERROR_LEARNING_UNDER_SAMPLING_INVALID_ALGORITHM'
      break
    case 27503:
      status = 'ERROR_LEARNING_UNDER_SAMPLING_INVALID_RATIO'
      break
    case 29001:
      status = 'ERROR_OPTIMIZATION_CONSTRAINT_ERROR'
      manualType = 'optimizationConstraintError'
      break
    case 29002:
      status = 'ERROR_OPTIMIZATION_EXPRESSION_ERROR'
      manualType = 'optimizationExpressionError'
      break
    case 29003:
      status = 'ERROR_OPTIMIZATION_EXECUTION_ERROR'
      break
    case 30002:
      status = 'ERROR_IN_CUSTOMBLOCK'
      detail = res.detail
      break
    case 30003:
      status = 'ERROR_OUTSIDE_CUSTOMBLOCK'
      break
    case 100303:
      status = 'ERROR_CANCELED'
      notificationMessage = i18n.t(
        'training.message.stoppedTrainingByOtherClient',
        {
          type: i18n.t('training.message.type.' + type)
        }
      )
      break
    case 101001:
      status = 'ERROR_AWS_AWSBATCH_OUT_OF_MEMORY_ERROR'
      break
    case 101002:
      status = 'ERROR_AWS_AWSBATCH_CNN_OUT_OF_MEMORY_ERROR'
      break
    default:
      status = 'ERROR_UNKNOWN'
  }
  return {
    status: status,
    notificationMessage: notificationMessage,
    manualType: manualType,
    detail
  }
}

export const SHOW_TRAINED_AI_TYPE = {
  BEST: 'trainedAiBest',
  LIST: 'trainedAiList',
  DETAIL: 'trainedAiDetail'
}

export const DEEP_RECIPE_TYPE = ['DEEP', 'FINETUNING_DEEP']

export function validateInputColumns(
  inputColumns,
  overrideInputColumns,
  predictionColumns
) {
  return predictionColumns.every((key) => {
    if (overrideInputColumns[key] != null) {
      return overrideInputColumns[key].length > 0
    } else {
      return inputColumns.length > 0
    }
  })
}
