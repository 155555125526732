import { loading } from './loading'
// この下の部分使ってないのでコメントアウトしました。使わないなら消してもらえればと思います
// import { download, base64ToBytes, base64ToUTF8 } from '@/lib/download'

const rpcTimeout = 10

const recipes = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    recipes: {},
    recipeLayers: null,
    editRecipe: null,
    editRecipeFrom: null,
    recipeTemplates: {},
    loadingRecipeTemplates: false
  },
  getters: {
    recipes: (state) => state.recipes,
    recipeLayers: (state) => state.recipeLayers,
    editRecipe: (state) => state.editRecipe,
    editRecipeFrom: (state) => state.editRecipeFrom,
    recipeError: (_state, getters) => getters['loading/error'],
    recipeLoading: (_state, getters) => getters['loading/loading'],
    recipeTemplates: (state) => state.recipeTemplates,
    loadingRecipeTemplates: (state) => state.loadingRecipeTemplates
  },
  mutations: {
    SET_LIST(state, value) {
      state.recipes = value
    },
    SET_DETAIL(state, { id, value }) {
      state.recipes[id] = value
    },
    SET_RECIPE_LAYERS(state, value) {
      state.recipeLayers = value
    },
    SET_AUTOFLOW_LAYER(state, value) {
      // set default params.
      const autoflowLayer = state.recipeLayers.AutoFlow
      autoflowLayer.params.algorithm = {}
      Object.keys(value).forEach((algoType) => {
        const algorithmList = value[algoType]
        algorithmList.forEach((algo) => {
          algo.active = true
        })
        autoflowLayer.params.algorithm[algoType] = {
          value: algorithmList
        }
      })
    },
    SET_EDIT_RECIPE(state, { recipe, from }) {
      state.editRecipe = recipe
      state.editRecipeFrom = from
    },
    SET_RECIPE_TEMPLATES(state, value) {
      const newTemplates = {}
      for (const entry of value) {
        newTemplates[entry.id] = entry
      }
      state.recipeTemplates = newTemplates
    },
    SET_LOADING_RECIPE_TEMPLATES(state, value) {
      state.loadingRecipeTemplates = value
    }
  },
  actions: {
    addRecipe: async function ({ commit, dispatch }, { recipe, projectId }) {
      const req = {
        action: 'addRecipe',
        recipe,
        projectId
      }
      const res = await dispatch('remoteCall', req)

      return res
    },
    async loadRecipeList({ commit, dispatch }, projectId = null) {
      dispatch('loading/start')

      let req = {
        action: 'getRecipeList'
      }

      if (projectId != null) {
        req = Object.assign(req, { projectId })
      }

      try {
        const response = await this._vm.$sendMessageAndReceive(req)

        if (response.status !== 'error') {
          const obj = {}
          response.list.forEach((item) => {
            const fullId = item.id + '-' + item.accountId
            item.fullId = fullId
            obj[fullId] = item
          })
          commit('SET_LIST', obj)
        } else {
          throw response
        }
      } catch (e) {
        console.log(e)
        dispatch('loading/error', e)
      } finally {
        dispatch('loading/finish')
      }
    },
    async fetchLayers({ state, commit }) {
      const res = await this._vm.$sendMessageAndReceive({ action: 'getLayers' })
      const subscriptionResult = await this._vm.$sendMessageAndReceive({
        action: 'getSubscriptions'
      })
      const subscriptions = subscriptionResult.result
      const layers = {}
      res.layers.forEach((v) => {
        v.graph = {}
        if (v.subscriptionNeeded) {
          if (subscriptions.indexOf(v.name) === -1) {
            return
          }
        }
        layers[v.name] = v
      })
      commit('SET_RECIPE_LAYERS', layers)
      const resAutoflow = await this._vm.$sendMessageAndReceive({
        action: 'getAutoFlowParams'
      })
      commit('SET_AUTOFLOW_LAYER', resAutoflow.body)
    },
    setEditRecipe({ commit }, { recipe, from }) {
      commit('SET_EDIT_RECIPE', { recipe, from })
    },
    async deleteRecipes({ dispatch }, recipes) {
      await dispatch(
        'remoteCall',
        {
          action: 'deleteRecipeMulti',
          recipes
        },
        { root: true }
      )
      dispatch('loadRecipeList')
    },
    async fetchTemplateList({ state, commit }, payload) {
      commit('SET_LOADING_RECIPE_TEMPLATES', true)
      try {
        const req = {
          action: 'listTemplates'
        }
        const res = await this._vm.$sendMessageAndReceive(req)
        res.result.forEach((recipe) => {
          recipe.name = recipe.body.info.name
          recipe.description = recipe.body.info.description
          recipe.detailLoaded = true
        })
        commit('SET_RECIPE_TEMPLATES', res.result)
      } finally {
        commit('SET_LOADING_RECIPE_TEMPLATES', false)
      }
    },
    async fetchTemplateDetail({ state, commit }, payload) {},
    async loadTemplate(
      { state, commit, dispatch },
      { type, id, name = null, projectId = null }
    ) {
      const req = {
        action: 'loadTemplate',
        type: type,
        id: id,
        name: name,
        projectId: projectId
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      if (res.status !== 'error') {
        res.detail.detailLoaded = true
        return res
      }
    },
    remoteCall: async function ({ dispatch }, request) {
      try {
        const response = await Promise.race([
          this._vm.$sendMessageAndReceive(request),
          new Promise((resolve) => setTimeout(resolve, rpcTimeout * 1000)).then(
            () => {
              return {
                status: 'error',
                message: 'RPC_TIMEOUT'
              }
            }
          )
        ])

        if (response.status === 'error') throw new Error(response)
        else return response
      } catch (error) {
        throw new Error(error)
      }
    },
    updateRecipe: async function (
      { commit, dispatch },
      { accountId, projectId, recipe, recipeId }
    ) {
      const req = {
        action: 'updateRecipe',
        accountId,
        projectId,
        recipe,
        recipeId
      }

      const res = await dispatch('remoteCall', req)
      return res
    },
    getRecipeTypeBeforeAdd: async function ({ commit, dispatch }, { recipe }) {
      const req = {
        action: 'getRecipeTypeBeforeAdd',
        recipe
      }

      const res = await dispatch('remoteCall', req)
      return res
    }
  }
}
export default recipes
