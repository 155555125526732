<template>
  <popup
    :title="$t('alerts.connectionClosed.title')"
    :showPopup="showPopup"
    noClose
    class="error-popup"
    @close-modal="$emit('close-modal', $event)"
  >
    <connection-error />
  </popup>
</template>

<script>
import popup from '@/components/molecules/popup/popup'
import connectionError from '@/components/organisms/common-popups/connection-error'

export default {
  components: {
    popup,
    connectionError
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.error-popup {
  left: 0;
  width: 100vw;
  background-color: rgba($medium-gray, 0.95);
  z-index: $z-index-max;
  &::v-deep {
    .popup-wrap {
      box-shadow: $box-shadow-hover;
    }
  }
}
</style>
