// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'

import VueNotifications from 'vue-notification'
import VueUID from 'vue-uid'
/* eslint-disable no-new */

import store from '@/stores/index.js'
import Formatter from '@/plugin/formatter'
import WS from '@/plugin/ws.js'
import LogInfo from '@/plugin/log_info'

import VueI18n from 'vue-i18n'
import VTooltip from 'v-tooltip'
import localeJa from '@/locales/ja.json'
import localeEn from '@/locales/en.json'

import './globalComponents.js'
import '@/plugin/globalProperties.js'
import VueGtm from '@gtm-support/vue2-gtm'
import GtmTools from '@/plugin/gtmTools.js'
import errorHandler from '@/plugin/errorHandler.js'

Vue.use(VueI18n)
Vue.use(VTooltip, {
  defaultBoundariesElement: 'window'
})
export const i18n = new VueI18n({
  locale: store?.getters['settings/language'] ?? 'ja',
  messages: { ja: localeJa, en: localeEn }
})

if (module.hot) {
  module.hot.accept(['./locales/en.json'], function () {
    const localeEn = require('./locales/en.json')
    i18n.setLocaleMessage('en', {
      ...localeEn
    })
  })
  module.hot.accept(['./locales/ja.json'], function () {
    const localeJa = require('./locales/ja.json')
    i18n.setLocaleMessage('ja', {
      ...localeJa
    })
  })
}

Vue.use(VueNotifications)
Vue.use(VueUID)

require('@/assets/scss/common.scss')
require('@/components/organisms/graph/graph.scss')

Vue.config.productionTip = false
Vue.use(LogInfo, process.env.NODE_ENV === 'development')

Vue.use(Formatter)

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTAG,
  defer: true,
  compatibility: false,
  debug: process.env.NODE_ENV === 'development',
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
})
Vue.use(GtmTools)
Vue.use(errorHandler)

const host = location.host
const protocol = location.protocol
const url =
  protocol === 'https:'
    ? 'wss://' + host + '/connect'
    : 'ws://' + host + '/connect'

Vue.use(WS, url, {
  onopen: () => {
    store.dispatch('initialize')
  },
  retry: 3,
  onclosed: () => router.push('/login')
})

export default new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App),
  template: '<App/>'
})
