<template>
  <div>
    <div class="error-desc">
      <texts
        class="error-desc-text"
        :text="$t('alerts.connectionClosed.message')"
        size="small"
      />
    </div>
    <div class="error-desc">
      <texts
        class="error-desc-text"
        :text="$t('alerts.connectionClosed.trainInfereAnnounce')"
        size="small"
      />
      <div class="error-desc-img">
        <icons iconName="trainedAi" size="40" color="green" />
      </div>
      <div class="error-desc-img">
        <icons iconName="inference" size="40" color="green" />
      </div>
    </div>
    <div class="error-button">
      <button-main
        :text="$t('loginPage.resetPassword.loginLink')"
        type="emphasis"
        @click="logout"
      />
    </div>
  </div>
</template>

<script>
import icons from '@/components/atoms/icon'
import buttonMain from '@/components/atoms/button-main'
import { mapActions } from 'vuex'

export default {
  components: {
    icons,
    buttonMain
  },
  methods: {
    ...mapActions('auth', ['connectionClosedSignout']),
    async logout() {
      this.connectionClosedSignout()
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  &-desc {
    display: flex;
    grid-column-gap: $space-medium;
    max-width: adjustVW(800);
    margin-bottom: $space-small;
    &-text {
      white-space: pre-line;
    }
    &-img {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: adjustVW(64);
      height: adjustVW(64);
      border-radius: 9in;
      box-shadow: $box-shadow-hover;
    }
  }
  &-button {
    margin-top: $space-medium;
  }
}
</style>
