<template>
  <div
    v-if="isNaN(size)"
    ref="textItem"
    v-tooltip.auto-start="tooltip"
    class="c-text"
    :class="[
      'c-text-' + size,
      'c-text-' + color,
      { 'c-text-bold': isBold },
      { 'c-text-show-all': isShowAll }
    ]"
    @mouseover="showTips"
    @mouseleave="leaveTips"
  >
    <slot />
    {{ text }}
  </div>
  <div
    v-else
    ref="textItem"
    v-tooltip.auto-start="tooltip"
    class="c-text c-text-number"
    :class="['c-text-' + color, { 'c-text-bold': isBold }]"
    :style="styleSize"
    @mouseover="showTips"
    @mouseleave="leaveTips"
  >
    <slot />{{ text }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      isTipsShow: false,
      offsetWidth: null,
      scrollWidth: null
    }
  },
  props: {
    /** 表示するテキスト */
    text: {
      type: [String, Number, Boolean],
      default: ''
    },
    /** 文字の大きさ ['default', 'title', 'sub-title', 'big', 'large', 'small', 'min'] */
    size: {
      type: [String, Number],
      default: 'default',
      require: false
    },
    /** 文字の色やデコレーション ['default', 'gray', 'emphasis', 'link', 'link-default', 'link-caution', 'function', 'caution', 'accept', 'disabled', 'off', 'text-button', 'green', 'link-green', 'green-sub', 'negative', 'positive'] */
    color: {
      type: String,
      require: false,
      default: 'default'
    },
    /** 太文字かどうか */
    isBold: {
      type: Boolean,
      default: false,
      require: false
    },
    /** 折り返して全文表示するかどうか */
    isShowAll: Boolean,
    /** トリムされたテキストを表示するツールチップを非表示にする */
    noTips: Boolean
  },
  computed: {
    styleSize() {
      return { '--textSize': this.size / 10 + 'rem' }
    },
    isTips() {
      if (!this.offsetWidth || !this.scrollWidth) return false
      return this.offsetWidth < this.scrollWidth
    },
    tooltip() {
      if (this.noTips) {
        return {
          trigger: ['manual'],
          show: false
        }
      }
      return {
        content: this.text,
        trigger: ['manual'],
        show: this.isTips && this.isTipsShow && !this.noTips,
        delay: { show: 500, hide: 500 }
      }
    }
  },
  methods: {
    showTips() {
      this.isTipsShow = true
    },
    leaveTips() {
      this.isTipsShow = false
    },
    getOffsetWidth() {
      return this.$refs.textItem.offsetWidth
    },
    getScrollWidth() {
      return this.$refs.textItem.scrollWidth
    }
  },
  mounted() {
    this.offsetWidth = this.getOffsetWidth()
    this.scrollWidth = this.getScrollWidth()
  },
  watch: {
    text() {
      this.$nextTick(function () {
        this.offsetWidth = this.getOffsetWidth()
        this.scrollWidth = this.getScrollWidth()
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.c-text {
  overflow: hidden;
  color: $text-main;
  font-size: $text-medium;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include text-crop;
  transition: color $transition-base;
  &-title {
    color: $text-main;
    font-size: $text-page-title;
    font-weight: bold;
  }
  &-sub-title {
    color: $text-main;
    font-size: $text-page-sub-title;
    font-weight: bold;
  }
  &-big {
    color: $text-main;
    font-size: $text-big;
    font-weight: bold;
  }
  &-large {
    color: $text-main;
    font-size: $text-large;
    font-weight: bold;
  }
  &-small {
    color: $text-main;
    font-size: $text-base;
    font-weight: 400;
  }
  &-min {
    color: $text-main;
    font-size: $text-min;
    font-weight: 400;
  }
  &-number {
    --textSize: 1.6rem;
    font-size: var(--textSize);
  }
  &-gray {
    color: $text-sub;
  }
  &-emphasis {
    color: $text-decoration;
  }
  &-link {
    color: $text-link;
    text-decoration: underline;
  }
  &-link-default {
    color: $text-main;
    text-decoration: underline;
  }
  &-link-caution {
    color: $text-caution;
    text-decoration: underline;
  }
  &-function {
    color: $text-func;
  }
  &-caution {
    color: $text-caution;
  }
  &-accept {
    color: $blue;
  }
  &-disabled {
    color: $border-gray;
  }
  &-off {
    color: $border-gray;
  }
  &-green {
    color: $green;
  }
  &-green-sub {
    color: $green-sub;
  }
  &-link-green {
    color: $green;
    text-decoration: underline;
  }
  &-negative {
    color: $color-negative;
  }
  &-positive {
    color: $color-positive;
  }
  &-text-button {
    text-decoration: underline;
  }
  &-bold {
    font-weight: bold;
  }
  &-show-all {
    word-break: break-all;
    white-space: pre;
  }
}
.tooltip {
  position: absolute;
}
</style>
