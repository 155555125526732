import { loading } from '../loading'

export default {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    services: {},
    loadingServices: false
  },
  getters: {
    services: (state) => state.services,
    servicesLoading: (_state, getters) => getters['loading/loading'],
    loadingServices: (state) => state.loadingServices
  },
  mutations: {
    SET_LIST(state, value) {
      state.services = value
    },
    SET_DETAIL(state, { serviceId, value }) {
      state.services[serviceId] = value
    },
    SET_ERROR(state, { serviceId, value }) {
      state.services[serviceId].error = value
    },
    SET_LOADING_STATE(state, { serviceId, value }) {
      state.services[serviceId].loading = value
    },
    SET_LOADING_SERVICES_LIST(state, value) {
      state.loadingServices = value
    }
  },
  actions: {
    async loadServiceList({ commit, dispatch }, { projectId }) {
      dispatch('loading/start')
      commit('SET_LOADING_SERVICES_LIST', true)
      try {
        const response = await this._vm.$sendMessageAndReceive({
          action: 'listServicev2',
          serviceType: 'optimization',
          projectId: projectId
        })
        if (response.status !== 'error') {
          const obj = {}
          response.list.forEach((item) => {
            obj[item.serviceId] = item
          })
          commit('SET_LIST', obj)
        } else {
          throw response
        }
      } catch (e) {
        console.log(e)
        dispatch('loading/error', e)
      } finally {
        dispatch('loading/finish')
        commit('SET_LOADING_SERVICES_LIST', false)
      }
    },
    async addService({ dispatch }, { projectId, ...service }) {
      await dispatch(
        'remoteCall',
        {
          action: 'addServicev2',
          serviceType: 'optimization',
          projectId: projectId,
          ...service
        },
        {
          root: true
        }
      )
      dispatch('loadServiceList', { projectId })
    },
    async updateService({ dispatch }, { projectId, ...service }) {
      await dispatch(
        'remoteCall',
        {
          action: 'updateServicev2',
          serviceType: 'optimization',
          ...service
        },
        { root: true }
      )
      dispatch('loadServiceList', { projectId })
    },
    async deleteServices({ dispatch }, { projectId, services }) {
      await dispatch(
        'remoteCall',
        {
          action: 'deleteServicesv2',
          serviceType: 'optimization',
          services: services
        },
        { root: true }
      )
      dispatch('loadServiceList', { projectId })
    }
  }
}
