import Vue from 'vue'

Vue.prototype.$urls = {
  contactLink: 'https://www.matrixflow.net/matrixflow/contact-product',
  changePlan: 'https://www.matrixflow.net/matrixflow/contact-basic-plan',
  terms: {
    ja: 'https://www.matrixflow.net/terms/',
    en: 'https://www.matrixflow.net/en/terms/'
  },
  privacyPolicy: {
    ja: 'https://www.matrixflow.net/privacypolicy/',
    en: 'https://www.matrixflow.net/en/privacypolicy/'
  },
  personalInfomationProtection:
    'https://www.matrixflow.net/personal-information-protection/',
  googleAuthenticator: 'https://support.google.com/accounts/answer/1066447',
  manual: 'https://www.matrixflow.net/manual/',
  community: 'https://forms.gle/zMHEEUvdGwhvUQj2A',
  swagger: '/docs'
}

// recipeTypeの組み合わせで処理を分岐させるときに利用する
Vue.prototype.$recipeType = {
  time: ['TIME', 'TIME_TRANSFORMER', 'TIME_TRANSFORMER2'],
  timeExceptPreviousTransformer: ['TIME', 'TIME_TRANSFORMER2'],
  timeTransformer: ['TIME_TRANSFORMER', 'TIME_TRANSFORMER2']
}
