import Vue from 'vue'
import { i18n } from '@/main'
import { loading } from './loading'
import { download, base64ToBytes, base64ToUTF8 } from '@/lib/download'

const rpcTimeout = 10

const datasets = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    datasetList: {},
    bulkLoaded: false,
    loadingDatasetList: false
  },
  getters: {
    apiServerOrigin: () => {
      return window.location.origin
    },
    datasetList: (state) => state.datasetList,
    datasetError: (_state, getters) => getters['loading/error'],
    datasetLoading: (_state, getters) => getters['loading/loading'],
    loadingDatasetList: (state) => state.loadingDatasetList
  },
  mutations: {
    SET_DATASET_LIST(state, value) {
      const beforeDatasetList = Object.assign(state.datasetList)
      Vue.delete(state, 'datasetList')
      const datasetList = {}
      for (const [key, item] of Object.entries(value)) {
        // データセットの詳細情報を保持するため
        datasetList[key] = beforeDatasetList[key]
          ? Object.assign(beforeDatasetList[key], item)
          : item
      }
      state.datasetList = datasetList
    },
    SET_DATASET_DETAIL(state, { datasetId, value }) {
      state.datasetList[datasetId] = Object.assign(
        state.datasetList[datasetId],
        value
      )
    },
    SET_DATASET_ERROR(state, { datasetId, value }) {
      if (state.datasetList[datasetId]) {
        state.datasetList[datasetId].error = value
      }
    },
    SET_DATASET_LOADING_STATE(state, { datasetId, value }) {
      if (state.datasetList[datasetId]) {
        state.datasetList[datasetId].loading = value
      }
    },
    BULK_LOADED(state) {
      state.bulkLoaded = true
    },
    SET_LOADING_DATASET_LIST(state, value) {
      state.loadingDatasetList = value
    }
  },
  actions: {
    notifyError(context, response) {
      const message = response.message
      this._vm.$notify({
        group: 'alerts',
        type: 'danger',
        title: i18n.t(message),
        duration: 10000,
        data: {
          dismissible: true
        }
      })
    },
    async remoteCall({ dispatch }, request) {
      try {
        const response = await Promise.race([
          this._vm.$sendMessageAndReceive(request),
          new Promise((resolve) => setTimeout(resolve, rpcTimeout * 1000)).then(
            () => {
              return {
                status: 'error',
                message: 'RPC_TIMEOUT'
              }
            }
          )
        ])
        if (response.status === 'error') {
          dispatch('notifyError', response)
        }
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async loadDatasetList({ commit, dispatch }, projectId = null) {
      if (projectId == null) {
        throw new Error('loadDatasetList without projectId is Deprecated')
      }
      dispatch('loading/start')
      commit('SET_LOADING_DATASET_LIST', true)

      let req = {
        action: 'getDataList'
      }
      if (projectId) req = Object.assign(req, { projectId })

      try {
        const response = await this._vm.$sendMessageAndReceive(req)
        if (response.status !== 'error') {
          const obj = {}
          response.list.forEach((item) => {
            obj[item.id] = item
          })
          commit('SET_DATASET_LIST', obj)
        } else {
          throw response
        }
      } catch (e) {
        dispatch('loading/error', e)
      } finally {
        dispatch('loading/finish')
        commit('SET_LOADING_DATASET_LIST', false)
      }
    },
    async loadDatasetDetail({ commit, state }, datasetId) {
      try {
        commit('SET_DATASET_LOADING_STATE', {
          datasetId: datasetId,
          value: true
        })
        const response = await this._vm.$sendMessageAndReceive({
          action: 'getDataInfo',
          dataId: datasetId,
          accountId: state.datasetList[datasetId].accountId
        })
        if (response.status !== 'error') {
          commit('SET_DATASET_DETAIL', {
            datasetId: datasetId,
            value: response.detail
          })
        } else {
          throw response
        }
      } catch (e) {
        commit('SET_DATASET_ERROR', {
          datasetId: datasetId,
          value: e
        })
      } finally {
        commit('SET_DATASET_LOADING_STATE', {
          datasetId: datasetId,
          value: false
        })
      }
    },
    async loadDatasetDetailBulk({ commit, state }) {
      if (state.bulkLoaded) return
      commit('BULK_LOADED', true)
      for (const index in state.datasetList) {
        try {
          commit('SET_DATASET_LOADING_STATE', {
            datasetId: index,
            value: true
          })
          const response = await this._vm.$sendMessageAndReceive({
            action: 'getDataInfo',
            dataId: index,
            accountId: state.datasetList[index].accountId
          })
          if (response.status !== 'error') {
            commit('SET_DATASET_DETAIL', {
              datasetId: index,
              value: response.detail
            })
          } else {
            throw response
          }
        } catch (e) {
          commit('SET_DATASET_ERROR', {
            datasetId: index,
            value: e
          })
        } finally {
          commit('SET_DATASET_LOADING_STATE', {
            datasetId: index,
            value: false
          })
        }
      }
    },
    async createDataset({ dispatch }, dataset) {
      if (dataset?.projectId == null) {
        throw new Error('projectId required')
      }
      await dispatch('remoteCall', {
        action: 'datasetCreate',
        ...dataset
      })
      dispatch('loadDatasetList')
    },
    async updateDataset({ dispatch }, { dataId, accountId, data }) {
      dispatch('loading/start')
      await dispatch('remoteCall', {
        action: 'updateData',
        dataInfo: {
          name: data.name,
          description: data.description
        },
        accountId: accountId,
        dataId: dataId
      })
      dispatch('loading/finish')
    },
    async deleteDatasets({ dispatch }, datasets) {
      await dispatch('remoteCall', {
        action: 'deleteDataMulti',
        datasets
      })
      dispatch('loadDatasetList')
    },
    copyDataset: async function (
      { dispatch },
      { dataId, accountId, newName, projectId }
    ) {
      await dispatch('remoteCall', {
        action: 'copyDataset',
        dataId,
        accountId,
        newName,
        projectId
      })
      dispatch('loadDatasetList')
    },
    async downloadDataset(
      { dispatch, state },
      { name, dataId, accountId, type }
    ) {
      const req = {
        action: 'downloadData',
        accountId: accountId,
        dataId: dataId,
        type: type
      }
      const res = await this._vm.$sendMessageAndReceive(req)
      const file = res.file
      const ext = res.ext
      const decoded = base64ToBytes(file)
      download(decoded, name + '.' + ext)
    }
  }
}

export default datasets
