<template>
  <time>
    {{ formattedDate }}
  </time>
</template>
<script>
import moment from 'moment-timezone'

export default {
  name: 'AtomTime',
  props: {
    /** 日時 */
    value: [Date, String],
    /** タイムゾーン（accountInfoに紐付いたものなど） */
    timezone: {
      type: [String, null],
      default: null,
      require: false
    },
    /** 時間も表示する場合 */
    withTime: { type: Boolean, default: false }
  },
  computed: {
    formattedDate() {
      const UtcDate = moment.utc(this.value)
      let date
      if (this.timezone) {
        date = UtcDate.tz(this.timezone)
      } else {
        date = UtcDate.local()
      }
      if (this.withTime) {
        return date.format('YYYY MM DD  HH:mm:ss')
      } else {
        return date.format('YYYY MM DD')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.c-text {
  overflow: hidden;
  color: $text-main;
  font-size: $text-medium;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include text-crop;
  transition: color $transition-base;
  &-title {
    color: $text-main;
    font-size: $text-page-title;
    font-weight: bold;
  }
  &-sub-title {
    color: $text-main;
    font-size: $text-page-sub-title;
    font-weight: bold;
  }
  &-big {
    color: $text-main;
    font-size: $text-big;
    font-weight: bold;
  }
  &-large {
    color: $text-main;
    font-size: $text-large;
    font-weight: bold;
  }
  &-small {
    color: $text-main;
    font-size: $text-base;
    font-weight: 400;
  }
  &-min {
    color: $text-main;
    font-size: $text-min;
    font-weight: 400;
  }
  &-number {
    --textSize: 1.6rem;
    font-size: var(--textSize);
  }
  &-gray {
    color: $text-sub;
  }
  &-emphasis {
    color: $text-decoration;
  }
  &-link {
    color: $text-link;
    text-decoration: underline;
  }
  &-link-default {
    color: $text-main;
    text-decoration: underline;
  }
  &-function {
    color: $text-func;
  }
  &-caution {
    color: $text-caution;
  }
  &-accept {
    color: $blue;
  }
  &-disabled {
    color: $border-gray;
  }
  &-off {
    color: $border-gray;
  }
  &-text-button {
    text-decoration: underline;
  }
  &-bold {
    font-weight: bold;
  }
  &-show-all {
    word-break: break-all;
    white-space: pre;
  }
}
</style>
