// eslint-disable-next-line no-unused-vars
import Vue from 'vue'
import axios from 'axios'
import store from '../stores'

import { i18n } from '@/main'

function uniqueId() {
  return (
    new Date().getTime().toString(16) +
    Math.floor((1 << 16) * Math.random()).toString(16) +
    Math.floor((1 << 16) * Math.random()).toString(16)
  )
}

window.notify = Vue.notify

export class CancelToken {
  isCanceled = false
  cancel() {
    this.isCanceled = true
  }
}

export default {
  install(
    Vue,
    url = null,
    { onopen = () => 0, retry = 3, onclosed = () => 0 }
  ) {
    function init(socket, vm = mixin) {
      socket._polling = setInterval(
        () => socket.send(JSON.stringify({ action: 'polling' })),
        20000
      )
      socket.addEventListener('message', (msg) => vm.__ws_received__(msg))

      socket.addEventListener('open', () => {
        vm.$ws.__onopen_resolve.map((f) => f())
        onopen()
        if (vm.$ws.connection_error_count > 0) {
          vm.$gtmDataLayer.sendEventCategory(
            'reconnectCheck',
            'reOpenWS',
            vm.$ws.connection_error_count
          )
        }
        store.dispatch('settings/setWebSocketConnectionStatus', true)
      })

      socket.addEventListener('error', async function (e) {
        if (vm.$ws.error_reason !== 'EXPIRED_SESSION') {
          try {
            const res = await axios.get('/api/checkIpAddress')
            if (res.result === true) {
              setTimeout(() => vm.$reconnectSocket(), 1000)
            }
          } catch (err) {
            const statusCode = err.request.status
            const data = err.response?.data
            const reason = data?.reason

            if (statusCode === 403) {
              vm.$ws.error_reason = 'NOT_ALLOWED_IP'
              await axios.get('/api/signout')
            }

            if (statusCode === 500 && reason === 'INCORRECT_IP_IN_LIST') {
              vm.$ws.error_reason = 'INCORRECT_IP_IN_LIST'
              await axios.get('/api/signout')
            }
          }
        }
        // console.log('ws error:', e)
      })

      socket.addEventListener('close', () => {
        vm.$ws.connection_error_count++
        vm.$gtmDataLayer.sendEventCategory(
          'reconnectCheck',
          'reconnectCountWS',
          vm.$ws.connection_error_count
        )
        setTimeout(() => vm.$reconnectSocket(), 1000)
      })
    }

    let $socket = null

    function get$socket() {
      return $socket
    }

    function set$socket(value) {
      $socket = value
    }

    Vue.prototype.__defineGetter__('$socket', get$socket)
    Vue.prototype.__defineSetter__('$socket', set$socket)

    const mixin = {
      $ws: {
        // todo : 変数やメソッドを$ws内に移動する
        connection_error_count: 0,
        error_reason: '',
        __onopen_resolve: []
      },
      $waitingMessages: {},
      $defaultListener: {},
      async $waitConnected() {
        if (this.$socket.readyState !== WebSocket.OPEN) {
          await new Promise((resolve) => {
            this.$ws.__onopen_resolve.push(resolve)
          })
        }
      },
      async $reconnectSocket({ ifDead = false } = {}) {
        if (ifDead) {
          if (
            this.$socket !== null &&
            this.$socket.readyState !== WebSocket.CLOSED
          ) {
            return
          }
        }

        const retryThreshold = this.$ws.connection_error_count > retry
        const errorReason = this.$ws.error_reason
        const hasErrorReason = errorReason !== ''

        if (retryThreshold || hasErrorReason) {
          let notifyContent

          switch (errorReason) {
            case 'NOT_ALLOWED_IP':
              notifyContent = {
                group: 'alerts',
                type: 'warning',
                title: i18n.t('alerts.notAllowedGlobalIp.title'),
                text: i18n.t('alerts.notAllowedGlobalIp.message'),
                duration: -1,
                data: {
                  dismissible: false,
                  connectionError: true
                }
              }

              break

            case 'INCORRECT_IP_IN_LIST':
              notifyContent = {
                group: 'alerts',
                type: 'warning',
                title: i18n.t('alerts.incorrectIpIncluded.title'),
                text: i18n.t('alerts.incorrectIpIncluded.message'),
                duration: -1,
                data: {
                  dismissible: false,
                  connectionError: true
                }
              }

              break

            case 'EXPIRED_SESSION':
              notifyContent = {
                group: 'alerts',
                type: 'warning',
                title: i18n.t('alerts.sessionExpired.title'),
                text: i18n.t('alerts.sessionExpired.message'),
                duration: -1,
                data: {
                  dismissible: false,
                  connectionError: true
                }
              }

              break

            default:
              Vue.prototype.$gtmDataLayer.sendEventCategory(
                'reconnectCheck',
                'connectionClosed',
                'connectionClosed'
              )
              store.dispatch('settings/setWebSocketConnectionStatus', false)

              break
          }

          Vue.notify(notifyContent)
          onclosed()

          return
        }
        const oldSocket = this.$socket
        if (oldSocket) {
          if (oldSocket._polling) clearInterval(oldSocket._polling)
          oldSocket.close()
        }

        const wsUrl = url
        this.$socket = new WebSocket(wsUrl)

        for (const k in this.$waitingMessages) {
          delete this.$waitingMessages[k]
        }
        this.reconected = true
        init(this.$socket, this)
        this.log_info('wait open')
        try {
          await new Promise((resolve, reject) => {
            this.$socket.addEventListener('open', resolve)
            this.$socket.addEventListener('error', reject)
            this.$socket.addEventListener('close', reject)
          })
        } catch (ex) {
          this.log_info(
            'failed to reconnect. (' + this.$ws._connection_error_count + ')'
          )
          return
        }
        this.log_info('wait open end')
      },
      $sendMessage(msg) {
        /**
         * send message as json
         * return : message id
         */
        if (!msg.m_id) {
          msg.m_id = uniqueId()
        }
        this.$socket.send(JSON.stringify(msg))
        return msg.m_id
      },
      async $sendMessageAndReceive(msg, actionName) {
        /**
         * send message as json and receive result.
         * return : result message
         */
        if (!msg.m_id) {
          msg.m_id = uniqueId()
        }
        const messageId = msg.m_id // msg.action
        return new Promise((resolve) => {
          this.$socket.send(JSON.stringify(msg))
          // this.$waitingMessages[messageId] = resolve
          if (actionName) {
            const strictResolve = (response) => {
              if (actionName === response.action) {
                resolve(response)
              }
            }
            this.$waitingMessages[messageId] = strictResolve
          } else {
            this.$waitingMessages[messageId] = resolve
          }
        })
      },
      async $receiveMessageById(messageId) {
        return new Promise((resolve) => {
          this.$waitingMessages[messageId] = resolve
        })
      },
      /**
       * status : "finish"or"error" であるメッセージが到着するまで、actionに応じたcallbackを呼ぶ
       */
      async $watchProgress(messageId, callbacks, cancelToken = null) {
        for (;;) {
          /** resolve with the received message */
          let resolvePromise
          /** reject if the socket has been closed */
          let rejectPromise
          const promise = new Promise((resolve, reject) => {
            resolvePromise = resolve
            rejectPromise = reject
          })
          this.$waitingMessages[messageId] = resolvePromise
          const onSocketClose = () => rejectPromise(new Error('SOCKET_CLOSED'))
          this.$socket.addEventListener('close', onSocketClose)

          /** the received message */
          let res
          try {
            res = await promise
          } finally {
            this.$socket.removeEventListener('close', onSocketClose)
          }

          const cb = callbacks[res.action]
          if (cb) {
            cb(res)
          }

          if (cancelToken?.isCanceled === true) {
            return
          }

          if (res.status === 'error') {
            throw res
          }
          if (res.status === 'finish') {
            return
          }
        }
      },
      __ws_received__(e) {
        let msg = e.data

        try {
          msg = JSON.parse(msg)

          if (msg?.status === 'EXPIRED_SESSION') {
            this.$ws.error_reason = 'EXPIRED_SESSION'
          }
        } catch (ex) {
          this._vm.log_info(ex)
          this._vm.log_info('malformed JSON : ' + msg)
        }
        const messageId = msg.m_id

        if (this.$waitingMessages[messageId]) {
          this.$waitingMessages[messageId](msg)
          delete this.$waitingMessages[messageId]
        } else {
          if (this.$defaultListener[messageId]) {
            this.$defaultListener[messageId](msg)
          }
        }
      }
    }
    Object.assign(Vue.prototype, mixin)
  }
}
